import React, { useState } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  CardContent,
  FormControl,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert as MuiAlert } from "@material-ui/lab";
// import { spacing } from "@material-ui/system";
import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import Chip from "../../customComponent/Chips";
import {
  addProject,
  updateProject,
} from "../../../redux/reducers/projectReducer";

const Alert = styled(MuiAlert)(spacing);

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#6320EE",
    marginRight: "10px",
    marginLeft: "5px",
  },
  // paper: {
  //     borderRadius: "12px",
  //     boxShadow: "0px 0px 12px -5px #000000",
  // },
  dialogwrapper: {
    position: "absolute",
    maxWidth: "50rem",
    // top: theme.spacing(5)
  },
  dialogTitle: {
    paddingRight: "0px",
    float: "right",
  },
  startICon: {
    margin: 0,
  },
  chips: {
    height: "20px",
    borderRadius: "0px",
  },
  label: {
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  rootButton: {
    minWidth: "39px",
    marginTop: "-2px",
    marginRight: "11px",
  },
  ButtonAprove: {
    backgroundColor: "#23CC94",
    color: "white",
    width: "6rem",
    margin: "12px 39px",
  },
  discription: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    marginTop: "10px",
  },
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  h5: {
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "5px",
  },
  dialogContent: {
    padding: "0px 24px",
  },
  controlinput: {
    backgroundColor: "#F9F9FC",
  },
  select: {
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    padding: "4px 12px ",
    // backgroundColor: "white"
  },
  shrink: {
    display: "none",
  },
  inputSelect: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  paperSelect: {
    backgroundColor: "#f5f6fa",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
}));

const validationSchema = Yup.object().shape({
  project_number: Yup.number().required("Project Number is required.."),
  name: Yup.string().required("Project name is required"),
  description: Yup.string().required("Required"),
});

const Popup = ({ open, setOpen, project }) => {
  const [selectUsers, setSelectUsers] = useState([]);
  const [selectGroups, setSelectGroups] = useState([]);
  const [message, setMessage] = useState();
  const [profile, setProfile] = useState();
  const [hubstaff, setHubstaff] = useState();
  const [channel, setBrand] = useState(
    project && project.channel ? project.channel : ""
  );
  const [localError, setLocalError] = useState(null);

  const dispatch = useDispatch();

  let projects = useSelector((state) => state.entities.projects);
  let users = useSelector((state) => state.entities.users);
  let groups = useSelector((state) => state.entities.groups);
  let messages = useSelector((state) => state.entities.messages);
  let profiles = useSelector((state) => state.entities.profiles);
  let hubstaffProjects = useSelector((state) => state.entities.hubstaff);
  let channels = useSelector((state) => state.entities.brands);

  React.useEffect(() => {
    if (project) {
      // in update
      const selectedGroups = groups.all.filter((item) =>
        project.groups.includes(item._id)
      );

      let projectUsers = users.list.filter(
        ({ projects }) => projects[0]?._id === project._id
      );

      let projectMessage = messages.all.find(
        (item) => item._id === project.message
      );

      let projectProfile = profiles.list.find(
        (item) => item._id === project.profile
      );

      let projectHubstaff = hubstaffProjects.list.find(
        (item) => item.id === project.hubstaff_project
      );

      setHubstaff(projectHubstaff);
      setProfile(projectProfile);
      setMessage(projectMessage);
      setSelectUsers(projectUsers);
      setSelectGroups(selectedGroups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    project_number: project ? project.project_number : "",
    name: project ? project.name : "",
    description: project ? project.description : "",
    profile,
    hubstaff,
    message,
    selectGroups,
    selectUsers,
    channel,
  };

  const classes = useStyles();

  const handleSubmit = ({ project_number, name, description }) => {
    let listOfGroups = selectGroups.map((item) => item._id);
    let listOfUsers = selectUsers.map((item) => item._id);

    if (!channel?._id) {
      setLocalError("channel is required");
      return;
    }
    const data = {
      project_number,
      name,
      description,
      message: message._id,
      profile: profile._id,
      channel: channel._id,
      groups: listOfGroups,
      users: listOfUsers,
      hubstaff_project: hubstaff.id,
      hubstaff_project_name: hubstaff.name,
    };

    if (project) {
      // dispatch update
      dispatch(updateProject(project._id, data));
      setOpen(false);
      setLocalError(null);
    } else {
      // dispatch add
      dispatch(addProject(data));
      setOpen(false);
      setLocalError(null);
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogwrapper }}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h6" gutterBottom>
          {project ? "Update Project" : "New Project"}
        </Typography>
        <div style={{ display: "flex", float: "right" }}>
          <Button
            style={{ color: "#868695", marginTop: "-40px" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent
        style={{ marginTop: "-18px" }}
        classes={{ root: classes.dialogContent }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            status,
          }) => (
            <>
              <CardContent>
                <Typography variant="body2" gutterBottom>
                  {project ? "Update " : "Create a new "}
                  project and start connecting with business across the country
                </Typography>
                {projects.success && (
                  <Alert severity="success" my={3}>
                    New project has been created successfully!
                  </Alert>
                )}
                {projects.error ||
                  (localError && (
                    <Alert severity="error" my={3}>
                      Error: {projects.error || localError}
                    </Alert>
                  ))}

                <form onSubmit={handleSubmit}>
                  <Grid container>
                    <Grid item md={12} style={{ padding: "9px 0" }}>
                      <Typography variant="h5" classes={{ h5: classes.h5 }}>
                        Project Number*:
                      </Typography>
                      <TextField
                        name="project_number"
                        placeholder="Project Number"
                        InputProps={{ classes: { input: classes.input } }}
                        inputlabelprops={{ shrink: false }}
                        value={values.project_number}
                        error={Boolean(
                          touched.project_number && errors.project_number
                        )}
                        fullWidth
                        helperText={
                          touched.project_number && errors.project_number
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>

                    <Grid item md={12} style={{ padding: "9px 0px" }}>
                      <FormControl fullWidth>
                        <Chip
                          list={channels.list_all}
                          selected={values.channel}
                          placeholder={"Select Brand"}
                          label={"name"}
                          setSelected={setBrand}
                          multiple={false}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={12} style={{ padding: "9px 0" }}>
                      <Typography variant="h5" classes={{ h5: classes.h5 }}>
                        Name*:
                      </Typography>
                      <TextField
                        name="name"
                        placeholder="Name"
                        InputProps={{ classes: { input: classes.input } }}
                        inputlabelprops={{ shrink: false }}
                        value={values.name}
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>

                    <Grid item md={12} style={{ padding: "9px 0px" }}>
                      <Typography variant="h5" classes={{ h5: classes.h5 }}>
                        Description*:
                      </Typography>
                      <TextField
                        name="description"
                        placeholder="Description"
                        InputProps={{
                          classes: {
                            input: classes.inputTexterea,
                            root: classes.controlinput,
                          },
                        }}
                        inputlabelprops={{ shrink: false }}
                        value={values.description}
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        fullWidth
                        rows={3}
                        rowsMax={4}
                        multiline={true}
                        helperText={touched.description && errors.description}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={12} style={{ padding: "9px 0px" }}>
                      <Chip
                        list={users.list}
                        selected={values.selectUsers}
                        placeholder={"Users"}
                        label={"name"}
                        setSelected={setSelectUsers}
                        multiple={true}
                      />
                    </Grid>
                    <Grid item md={12} style={{ padding: "9px 0px" }}>
                      <Chip
                        list={groups.all}
                        selected={values.selectGroups}
                        placeholder={"Groups"}
                        label={"name"}
                        setSelected={setSelectGroups}
                        multiple={true}
                      />
                    </Grid>
                    <Grid item md={12} style={{ padding: "9px 0px" }}>
                      <FormControl fullWidth>
                        <Chip
                          list={messages.all}
                          selected={values.message}
                          placeholder={"Message"}
                          label={"subject"}
                          setSelected={setMessage}
                          multiple={false}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={12} style={{ padding: "9px 0px" }}>
                      <FormControl fullWidth>
                        <Chip
                          list={profiles.list}
                          selected={values.profile}
                          placeholder={"Profile"}
                          label={"email"}
                          setSelected={setProfile}
                          multiple={false}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={12} style={{ padding: "9px 0px" }}>
                      <FormControl fullWidth>
                        <Chip
                          list={hubstaffProjects.list}
                          selected={values.hubstaff}
                          placeholder={"Project in Hubstaff"}
                          label={"name"}
                          setSelected={setHubstaff}
                          multiple={false}
                          disabled={project || false}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Button
                    style={{ marginTop: "10px" }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={4}
                  >
                    {project ? "Update" : "Add New"}
                  </Button>
                </form>
              </CardContent>
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
