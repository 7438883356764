import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import { InfoOutlined } from "@material-ui/icons";

import {
  Box,
  Card,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  CircularProgress,
  Grid,
  Tooltip,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import NumberFormat from "react-number-format";
import { blue, green } from "@material-ui/core/colors";

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const Chip = styled(MuiChip)`
  position: absolute;
  top: 16px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)}px;

  span {
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const Percentage = styled(MuiTypography)`
  blocks {
    display: "flex";
    alignitems: "center";
    justifycontent: "space-between";
  }
  span {
    color: #fff;
    font-weight: 600;
    background: ${green[300]};
    padding: 3px;
    border-radius: 3px;
    margin-right: 3px;
  }
  p {
    color: #000;
    font-weight: 600;
  }
`;

const MyStats = ({
  xs,
  sm,
  md,
  title,
  titleColor,
  titleRight,
  titleStyle,
  titleRightStyle,
  amount,
  amountColor,
  amountSize,
  prefix,
  suffix,
  thousandSeparator,
  amountStyle,
  amountRight,
  amountRightSize,
  amountRightStyle,
  amountRightColor,
  amountRightPrefix,
  amountRightSuffix,
  chip,
  chipStyle,
  numberBottomLeft,
  NBLPrefix,
  NBLSuffix,
  NBLPhousandSeparator,
  NBRPrefix,
  NBRSuffix,
  NBRThousandSeparator,
  numberBottomLeftStyle,
  textBottomLeft,
  textBottomLeftStyle,
  textBottomRight,
  numberBottomRight,
  textBottomRightStyle,
  numberBottomRightStyle,
  arrow,
  tooltip,
  tooltipRight,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(!amount ? true : false);
  }, [amount]);

  return (
    <Grid item xs={xs ? xs : 12} sm={sm ? sm : 6} md={md ? md : 3}>
      <Card style={{ hight: "100vw" }}>
        <CardContent>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h4"
              mb={6}
              style={titleStyle ? titleStyle : {}}
              color={titleColor || ""}
            >
              {title}
              {tooltip && (
                <Tooltip title={tooltip}>
                  <InfoOutlined
                    style={{
                      marginTop: "-3px",
                      position: "absolute",
                      marginLeft: "5px",
                      cursor: "pointer",
                      fontSize: "0.6em",
                    }}
                  />
                </Tooltip>
              )}
            </Typography>
            {titleRight && (
              <Typography
                variant="h4"
                mb={6}
                marginRight="1em"
                style={titleRightStyle ? titleRightStyle : {}}
              >
                {titleRight}
                {tooltipRight && (
                  <Tooltip title={tooltipRight}>
                    <InfoOutlined
                      style={{
                        marginTop: "-3px",
                        position: "absolute",
                        marginLeft: "5px",
                        cursor: "pointer",
                        fontSize: "0.6em",
                      }}
                    />
                  </Tooltip>
                )}
              </Typography>
            )}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3" mb={6}>
              <Box
                fontWeight="fontWeightBold"
                style={amountStyle ? amountStyle : {}}
                color={amountColor || ""}
                fontSize={amountSize || "2.4rem"}
              >
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <NumberFormat
                    value={amount}
                    displayType={"text"}
                    thousandSeparator={thousandSeparator ? false : true}
                    prefix={prefix ? prefix : ""}
                    suffix={suffix ? suffix : ""}
                  />
                )}
              </Box>
            </Typography>

            {(amountRight || amountRight === null) && (
              <Typography variant="h3" mb={6}>
                <Box
                  fontWeight="fontWeightBold"
                  color={amountRightColor || blue[900]}
                  fontSize={amountRightSize || "2rem"}
                >
                  {isLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <NumberFormat
                      value={amountRight}
                      displayType={"text"}
                      thousandSeparator={thousandSeparator ? false : true}
                      prefix={
                        amountRightPrefix
                          ? amountRightPrefix
                          : prefix
                          ? prefix
                          : ""
                      }
                      suffix={
                        amountRightSuffix
                          ? amountRightSuffix
                          : suffix
                          ? suffix
                          : ""
                      }
                    />
                  )}
                </Box>
              </Typography>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Percentage variant="subtitle2" mb={6} color="textSecondary">
              {/* {arrow && arrow === "up" ? (
                <ArrowUp fontSize="small" />
              ) : (
                <ArrowUp fontSize="small" />
              )} */}
              {(numberBottomLeft || numberBottomLeft === null) && (
                <span>
                  {isLoading ? (
                    <CircularProgress size={10} />
                  ) : (
                    <NumberFormat
                      value={numberBottomLeft}
                      displayType={"text"}
                      thousandSeparator={NBLPhousandSeparator ? false : true}
                      prefix={NBLPrefix ? NBLPrefix : ""}
                      suffix={NBLSuffix ? NBLSuffix : ""}
                    />
                  )}
                </span>
              )}
              {textBottomLeft && textBottomLeft}
            </Percentage>

            <Percentage variant="subtitle2" mb={6} color="textSecondary">
              {(numberBottomRight || numberBottomRight === null) && (
                <span>
                  {isLoading ? (
                    <CircularProgress size={10} />
                  ) : (
                    <NumberFormat
                      value={numberBottomRight}
                      displayType={"text"}
                      thousandSeparator={NBRThousandSeparator ? false : true}
                      prefix={NBRPrefix ? NBRPrefix : ""}
                      suffix={NBRSuffix ? NBRSuffix : ""}
                    />
                  )}
                </span>
              )}
              {textBottomRight && textBottomRight}
            </Percentage>
          </div>
          {chip && <Chip label={chip} />}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default MyStats;
