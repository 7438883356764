import {
  getAllCompanies,
  getCompanies,
} from "../redux/reducers/companiesReducer";
import * as globals from "../constants/index";

import {
  getAllBrands,
  getBrands,
  getItemsFamilies,
} from "../redux/reducers/brandsReducer";

import { getAllIntegrations } from "../redux/reducers/integrationsReducer";
import { getManagers } from "../redux/reducers/usersReducer";
import { getLeads } from "../redux/reducers/leadsReducer";
import { getDashboardStats } from "../redux/reducers/dashboardReducer";
import { getGlobalWords } from "../redux/reducers/globalWordReducer";
import { getSettings } from "../redux/reducers/settingsReducer";
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const dispatchAdminModules = (dispatch, state) => {
  if (state) {
    if (state.brands.isRequestSent === null) {
      dispatch(getBrands());
      dispatch(getAllBrands());
      dispatch(getItemsFamilies());
    }
    if (state.companies.isRequestSent === null) {
      // dispatch(getCompanies());
      dispatch(getAllCompanies());
    }

    if (state.leads.isRequestSent === null) {
      dispatch(getLeads());
    }

    if (state.users.isRequestSent === null) {
      dispatch(getManagers());
      //dispatch(getUsers());
    }

    if (state.integrations.isRequestSent === null) {
      dispatch(getAllIntegrations());
    }

    if (state.companies.isRequestSent === null) {
      dispatch(getCompanies());
    }

    if (state.dashboard.isRequestSent === null) {
      dispatch(getDashboardStats());
    }

    if (state.globalWords.isRequestSent === null) {
      dispatch(getGlobalWords());
    }

    if (state.settings.isRequestSent === null) {
      dispatch(getSettings());
    }
  }
  getNumberPerPage();
};

const getNumberPerPage = () => {
  const nbr = parseInt(localStorage.getItem("PerPage"));
  if (nbr) return nbr;
  else localStorage.setItem("PerPage", 20);
  return 20;
};

const updateNbrPerPage = (nbr) => {
  // update  local storage
  localStorage.setItem("PerPage", nbr);
};

function getToken() {
  let token = localStorage.getItem(globals.USER_TOKEN);
  return token ? JSON.parse(token) : null;
}

function updateToken(token) {
  localStorage.setItem(globals.USER_TOKEN, JSON.stringify(token));
  return token;
}

function isValidEmail(emailAddress) {
  // eslint-disable-next-line no-useless-escape
  let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (emailAddress.match(regexEmail)) {
    return true;
  } else {
    return false;
  }
}

const showNumber = (number) => {
  return number !== undefined && number !== null ? number.toString() : number;
};

export {
  getComparator,
  stableSort,
  dispatchAdminModules,
  getNumberPerPage,
  updateNbrPerPage,
  getToken,
  updateToken,
  showNumber,
  isValidEmail,
};
