import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import {
  Box,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  // Toolbar,
  Tooltip,
  Typography,
  FormControl,
  FormControlLabel,
  Switch,
  Button,
  Chip,
} from "@material-ui/core";

import {
  updateCompany,
  resetAll,
  deleteCompany,
  setSelectedCompany,
} from "../../redux/reducers/companiesReducer";

import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import MySelect from "../customComponent/MySelect";
import { getCompanies } from "../../redux/reducers/companiesReducer";
import { getNumberPerPage, updateNbrPerPage } from "../../helpers/functions";
import Loading from "../customComponent/Loading";
import CompanyPopUp from "./CompanyPopUp";
import { Reload } from "../customComponent/Reload";
import { blue, red } from "@material-ui/core/colors";
import moment from "moment";
import { Calendar, Link2 } from "react-feather";
import ArchiveDialog from "../customComponent/AlertDialog";
import NotificationAlerts from "../../components/NotificationAlerts";
import { resetLists } from "../../redux/reducers/usersReducer";
import { ROWS_PER_PAGE_OPTIONS } from "../../constants";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Customer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
`;

// const Spacer = styled.div`
//   flex: 1 1 100%;
// `;

// const ToolbarTitle = styled.div`
//   min-width: 150px;
// `;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "status", alignment: "left", label: "Status" },
  { id: "number", alignment: "center", label: "№" },
  { id: "Paid", alignment: "center", label: "Paid" },
  { id: "company", alignment: "left", label: "Company" },
  { id: "domaine", alignment: "left", label: "Domaine" },
  { id: "Created at", alignment: "left", label: "Created at" },
  { id: "users", alignment: "center", label: "Users" },
  { id: "integrations", alignment: "center", label: "Integrations" },
  { id: "actions", alignment: "right", label: "Actions" },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell mr={2}>Status</TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// let EnhancedTableToolbar = (props) => {
//   const { numSelected } = props;

//   return (
//     <Toolbar>
//       <ToolbarTitle>
//         {numSelected > 0 ? (
//           <Typography color="inherit" variant="subtitle1">
//             {numSelected} selected
//           </Typography>
//         ) : (
//           <Typography variant="h6" id="tableTitle">
//             Companies List
//           </Typography>
//         )}
//       </ToolbarTitle>
//       <Spacer />
//       <Box>
//         {numSelected > 0 ? (
//           <Tooltip title="Delete">
//             <IconButton aria-label="Delete">
//               <ArchiveIcon />
//             </IconButton>
//           </Tooltip>
//         ) : (
//           <Tooltip title="Filter list">
//             <IconButton aria-label="Filter list">
//               <FilterListIcon />
//             </IconButton>
//           </Tooltip>
//         )}
//       </Box>
//     </Toolbar>
//   );
// };

function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [openDialog, setOpenDialog] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [companyToDelete, setCompanyToDelete] = React.useState(null);

  const rowsPerPage = getNumberPerPage();
  const history = useHistory();
  const dispatch = useDispatch();

  const { list, search, total_records, maxPage } = useSelector(
    (state) => state.entities.companies
  );

  React.useEffect(() => {
    if (maxPage === 0) setPage(0);
  }, [maxPage]);

  const handleChangePage = (event, newPage) => {
    let data = {
      search,
      page: newPage + 1,
    };

    if (newPage > maxPage - 1) {
      dispatch(getCompanies(data));
    }
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      //const newSelectees = list.map((n) => n.id);
      // setSelected(newSelectees);
      return;
    }
    // setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    let per_page = event.target.value;
    let data = {
      search,
      per_page,
    };

    updateNbrPerPage(per_page);
    setPage(0);
    dispatch(getCompanies(data));
  };

  // const isSelected = (id) => selected.indexOf(id) !== -1;
  const afterCompanyDelete = () => {
    dispatch(deleteCompany({ _id: companyToDelete }));
    setCompanyToDelete(null);
  };

  const emptyRows = rowsPerPage - Math.min(list, 10 - page * rowsPerPage);

  const handleCompanyStatus = (company_id, status) => {
    dispatch(updateCompany({ _id: company_id, ...status }));
  };
  const goToCompany = (company) => {
    dispatch(setSelectedCompany(company));
    console.log(company);
    history.push("/companies/" + company._id);
  };
  return (
    <div>
      {companyToDelete && (
        <ArchiveDialog
          title="Archive Company"
          description="This will remove the company and all its integrations. Are you sure?"
          open={openDialog}
          setOpen={setOpenDialog}
          onYes={afterCompanyDelete}
        />
      )}
      <Paper>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={2}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={list.length}
            />
            <TableBody>
              {stableSort(list, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // const isItemSelected = isSelected(row.id);
                  // const labelId = `enhanced-table-checkbox-${index}`;
                  // const { status } = row;

                  return (
                    <TableRow hover key={index}>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              checked={row.status}
                              onChange={(event) =>
                                handleCompanyStatus(row._id, {
                                  status: !row.status,
                                })
                              }
                            />
                          }
                        />
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          goToCompany(row);
                        }}
                      >
                        <Customer>{row.number}</Customer>
                      </TableCell>

                      <TableCell align="center">
                        {row.validation_date ? (
                          <Chip label="Paid" color="primary" size="small" />
                        ) : (
                          <Chip
                            label="Pending"
                            color="secondary"
                            size="small"
                          />
                        )}
                      </TableCell>

                      <TableCell
                        style={{ cursor: "pointer" }}
                        align="left"
                        onClick={() => {
                          goToCompany(row);
                        }}
                      >
                        <Customer>{row.company_name}</Customer>
                      </TableCell>
                      <TableCell align="left">
                        <Link2
                          size={13}
                          style={{
                            marginRight: "3px",
                            marginBottom: "-3px",
                            color: blue[800],
                          }}
                        />
                        <Link
                          to={{ pathname: "https://" + row.domain }}
                          color="inherit"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: blue[800],
                            fontWeight: "600",
                          }}
                        >
                          {row.domain}
                        </Link>
                      </TableCell>
                      <TableCell align="left">
                        <Calendar
                          size={13}
                          style={{
                            marginRight: "3px",
                            marginBottom: "-1px",
                            color: red[800],
                          }}
                        />
                        {moment(row.createdAt).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {row.users?.length || 0}
                      </TableCell>
                      <TableCell align="center">
                        {row.integrations?.length || 0}
                      </TableCell>

                      <TableCell align="right">
                        <Tooltip title="Edit">
                          <IconButton
                            aria-label="edit"
                            style={{ color: blue[500] }}
                            onClick={() => goToCompany(row)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            style={{ color: red[500] }}
                            aria-label="Delete"
                            onClick={() => {
                              setOpenDialog(true);
                              setCompanyToDelete(row._id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={total_records}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function CompaniesList() {
  const companies_types = [
    { name: "Paid & Pending", value: "all" },
    { name: "Paid", value: true },
    { name: "Pending", value: "pending" },
  ];

  const [open, setOpen] = React.useState(false);
  const [brand, setBrand] = React.useState("all");
  const [manager, setManager] = React.useState("all");
  const [integration, setIntegration] = React.useState("all");
  const [type, setType] = React.useState("all");

  const {
    success,
    error,
    search,
    isLoading,
    selected_brand,
    selected_integration,
    selected_manager,
    isRequestSent,
    list,
    page,
  } = useSelector((state) => state.entities.companies);

  const { list_all: brands_list } = useSelector(
    (state) => state.entities.brands
  );
  const { list_all: integrations_list } = useSelector(
    (state) => state.entities.integrations
  );
  const { managers } = useSelector((state) => state.entities.users);

  const dispatch = useDispatch();

  React.useEffect(() => {
    const currentURL = window.location.href;
    let isLeadManager = currentURL.includes("/leads/");
    // console.log(integration);
    if (isLeadManager && integrations_list) {
      let integration = integrations_list.find(
        (item) => item.slug === "website-leads"
      );
      if (integration) {
        // set the list to zero
        dispatch(resetLists());
        setIntegration(integration._id);
        console.log(integration);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrations_list]);

  React.useEffect(() => {
    if (!isRequestSent) {
      loadCompanies();
    } else {
      loadCompanies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand, integration, manager, type]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadCompanies = () => {
    dispatch(
      getCompanies({
        ...search,
        ...page,
        brand,
        integration,
        manager,
        validate: type,
      })
    );
  };

  return (
    <React.Fragment>
      <NotificationAlerts error={error} success={success} reset={resetAll} />

      <Helmet title="Companies" />

      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Companies
          </Typography>
        </Grid>
        <Grid item>
          <Box style={{ display: "flex" }}>
            <Reload loading={isLoading} action={loadCompanies} />
            <FormControl style={{ minWidth: "10rem", marginRight: "1rem" }}>
              <MySelect
                selected={selected_manager}
                defaultLabel="All Managers"
                defaultValue={"all"}
                list={managers}
                label="full_name"
                value="_id"
                setSelected={setManager}
              />
            </FormControl>
            <FormControl style={{ minWidth: "10rem", marginRight: "1rem" }}>
              <MySelect
                selected={selected_integration}
                defaultLabel="All Integrations"
                defaultValue="all"
                list={integrations_list}
                label="name"
                value="_id"
                setSelected={setIntegration}
              />
            </FormControl>
            <FormControl style={{ minWidth: "10rem", marginRight: "1rem" }}>
              <MySelect
                selected={selected_brand}
                defaultLabel="All Brands"
                defaultValue="all"
                list={brands_list}
                label="name"
                value="_id"
                setSelected={setBrand}
              />
            </FormControl>
            <FormControl style={{ minWidth: "10rem", marginRight: "1rem" }}>
              <MySelect
                selected={type}
                list={companies_types}
                label="name"
                value="value"
                setSelected={setType}
              />
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
            >
              <AddIcon />
              Add Company
            </Button>
            {open && (
              <CompanyPopUp
                open={open}
                setOpen={setOpen}
                handleClose={handleClose}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {!list.length && isLoading ? <Loading /> : <EnhancedTable />}
          {/* <NoResults
            loading={isLoading}
            list={list.length}
            table={EnhancedTable}
          /> */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CompaniesList;
