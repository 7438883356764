import React from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "../customComponent/Chips";
import Loading from "../customComponent/Loading";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#6320EE",
    marginRight: "10px",
    marginLeft: "5px",
  },

  dialogWrapper: {
    position: "absolute",
    minHeight: "90rem",
  },
  dialogTitle: {
    paddingRight: "0px",
    float: "right",
  },
  startICon: {
    margin: 0,
  },
  chips: {
    height: "20px",
    borderRadius: "0px",
  },
  label: {
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  rootButton: {
    minWidth: "39px",
    marginTop: "-2px",
    marginRight: "11px",
  },
  ButtonApprove: {
    backgroundColor: "#23CC94",
    color: "white",
    width: "6rem",
    margin: "12px 39px",
  },
  description: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    marginTop: "10px",
  },
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  // eslint-disable-next-line no-dupe-keys
  label: {
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "5px",
    marginTop: "10px",
  },
  dialogContent: {
    padding: "0px 24px",
  },
  controlInput: {
    backgroundColor: "#F9F9FC",
  },
  select: {
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    padding: "4px 12px ",
  },
  shrink: {
    display: "none",
  },
  inputSelect: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  paperSelect: {
    backgroundColor: "#f5f6fa",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
}));

const AssignIntegrationPopUp = ({
  open,
  setOpen,
  handleClose,
  setIntegration,
  list,
}) => {
  const classes = useStyles();
  if (!list) return <Loading />;
  return (
    <Dialog
      maxWidth="md"
      classes={{ paper: classes.dialogwrapper }}
      open={open}
      onClose={handleClose ? handleClose : null}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        className={classes.dialogTitle}
        style={{ minWidth: "500px" }}
      >
        <Typography variant="h6" gutterBottom>
          Assign New Integration
        </Typography>
        <div style={{ display: "flex", float: "right" }}>
          <Button
            style={{ color: "#868695", marginTop: "-40px" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent
        style={{ marginTop: "-18px", minHeight: "160px" }}
        classes={{ root: classes.dialogContent }}
      >
        <Grid container spacing={6}>
          <Grid item md={12} style={{ paddingTop: "50px" }}>
            {list && (
              <Chip
                label="name"
                list={list}
                placeholder="Select Integration"
                setSelected={setIntegration}
                multiple={true}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default AssignIntegrationPopUp;
