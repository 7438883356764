import { createSlice } from "@reduxjs/toolkit";
import { getNumberPerPage } from "../../helpers/functions";
import { apiCallBegan } from "../actions/apiActions";
const url = "companies";

const slice = createSlice({
  name: "companies",
  initialState: {
    list_all: [],
    list: [],
    managers: [],
    integrations: [],
    stats: {},
    selected_brand: null,
    selected_integration: null,
    selected_manager: null,
    selected_date: null,
    selected_company: null,
    search: "",
    page: 0,
    per_page: 0,
    total_pages: 0,
    maxPage: 0,
    total_records: 0,
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
  },
  reducers: {
    resetAll: (companies, action) => {
      companies.isLoading = false;
      companies.error = null;
      companies.success = null;
      companies.deActivated = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    requestStarted: (companies, action) => {
      companies.isLoading = true;
      companies.error = null;
      companies.isRequestSent = true;
    },
    requestedFailed: (companies, action) => {
      companies.error = action.payload;
      companies.isLoading = false;
    },

    companyAdded: (companies, action) => {
      companies.success = "New Company Added";
      companies.list.unshift(action.payload);
      companies.list_all.unshift({
        _id: action.payload._id,
        company_name: action.payload.company_name,
      });
      companies.isLoading = false;
      companies.selected_company = action.payload;
    },
    setSelectedCompany: (companies, action) => {
      companies.selected_company = action.payload;
    },
    companyUpdated: (companies, action) => {
      const index = companies.list.findIndex(
        (company) => company._id === action.payload._id
      );
      companies.list[index] = action.payload;
      companies.success = "Company Updated";
      companies.isLoading = false;
      companies.selected_company = action.payload;
    },
    companyDeleted: (companies, action) => {
      const index = companies.list.findIndex(
        (company) => company._id === action.payload._id
      );
      const i = companies.list_all.findIndex(
        (company) => company._id === action.payload._id
      );
      companies.list_all.splice(i, 1);
      companies.list.splice(index, 1);
      companies.success = "Company Deleted";
      companies.isLoading = false;
    },

    companiesDeActivated: (companies, action) => {
      const { modifiedCount, word } = action.payload;
      companies.success =
        modifiedCount + " companies De-activated successfully";
      companies.deActivated = word;
      companies.isLoading = false;
    },
    resetList: (companies, action) => {
      companies.list = [];
      companies.page = 0;
      companies.per_page = 0;
      companies.total_pages = 0;
      companies.maxPage = 0;
      companies.total_records = 0;
    },

    companiesLoaded: (state, action) => {
      state.list = state.list.concat(action.payload.data);
      state.isLoading = false;
      state.page = action.payload.page;
      state.per_page = action.payload.per_page;
      state.total_pages = action.payload.total_pages;
      state.total_records = action.payload.total_records;
      state.selected_brand = action.payload.selected_brand;
      state.selected_integration = action.payload.selected_integration;
      state.selected_manager = action.payload.selected_manager;
      state.search = action.payload.search;
      state.maxPage = state.page > state.maxPage ? state.page : state.maxPage;
    },
    companiesReLoaded: (state, action) => {
      state.list = action.payload.data;
      state.isLoading = false;
      state.page = action.payload.page;
      state.per_page = action.payload.per_page;
      state.total_pages = action.payload.total_pages;
      state.total_records = action.payload.total_records;
      state.selected_brand = action.payload.selected_brand;
      state.selected_integration = action.payload.selected_integration;
      state.selected_manager = action.payload.selected_manager;
      state.search = action.payload.search;
      state.maxPage = 0;
    },
    allCompaniesLoaded: (state, action) => {
      state.list_all = action.payload;
      state.isLoading = false;
    },
    OneCompaniesLoaded: (state, action) => {
      state.company = action.payload;
      state.selected_company = action.payload;
      state.isLoading = false;
    },
    CompanyIntegrationsLoaded: (state, action) => {
      state.integrations = action.payload;
      state.isLoading = false;
      state.success = "";
    },
  },
});

export const {
  setError,
  companyAdded,
  companiesLoaded,
  companiesReLoaded,
  OneCompaniesLoaded,
  requestedFailed,
  requestStarted,
  companyUpdated,
  companyDeleted,
  companiesDeActivated,
  allCompaniesLoaded,
  CompanyIntegrationsLoaded,
  resetAll,
  setSelectedCompany,
  companyIntegrationUpdated,
} = slice.actions;
export default slice.reducer;

export const getCompanies = (params = {}) => {
  params.per_page = getNumberPerPage();
  if (params.search === "" || params.search === null) delete params.search;
  if (params.brand === "all" || params.brand === null) delete params.brand;
  if (params.integration === "all" || params.integration === null)
    delete params.integration;

  if (params.validate === "all") delete params.validate;
  if (params.validate === "pending") params.validate = false;

  if (params.manager === "all" || params.manager === null)
    delete params.manager;
  let query = new URLSearchParams(params);
  // // Reload the data if no page is provided
  let resultsReceived = params.page
    ? companiesLoaded.type
    : companiesReLoaded.type;

  return apiCallBegan({
    url: `${url}?${query}`,
    method: "GET",
    onStart: requestStarted.type,
    onSuccess: resultsReceived,
    onError: requestedFailed.type,
  });
};

export const getAllCompanies = (params = {}) =>
  apiCallBegan({
    url: `${url}/list`,
    method: "GET",
    onStart: requestStarted.type,
    onSuccess: allCompaniesLoaded.type,
    onError: requestedFailed.type,
  });

export const getOneCompany = (id) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: "GET",
    onStart: requestStarted.type,
    onSuccess: OneCompaniesLoaded.type,
    onError: requestedFailed.type,
  });

export const getCompanyIntegrations = (id) =>
  apiCallBegan({
    url: `${url}/${id}/integrations`,
    method: "GET",
    onStart: requestStarted.type,
    onSuccess: CompanyIntegrationsLoaded.type,
    onError: requestedFailed.type,
  });

export const addCompany = (data) =>
  apiCallBegan({
    url,
    method: "POST",
    data,
    onStart: requestStarted.type,
    onSuccess: companyAdded.type,
    onError: requestedFailed.type,
  });

export const updateCompany = (data) =>
  apiCallBegan({
    url,
    method: "PUT",
    data,
    onStart: requestStarted.type,
    onSuccess: companyUpdated.type,
    onError: requestedFailed.type,
  });
export const updateCompanyIntegration = (data) =>
  apiCallBegan({
    url,
    method: "PUT",
    data,
    onStart: requestStarted.type,
    onSuccess: companyIntegrationUpdated.type,
    onError: requestedFailed.type,
  });

export const deActivateCompanies = (word) =>
  apiCallBegan({
    url: `${url}/de-activate/${word}`,
    method: "PUT",
    onStart: requestStarted.type,
    onSuccess: companiesDeActivated.type,
    onError: requestedFailed.type,
  });
export const deleteCompany = (data) =>
  apiCallBegan({
    url,
    data,
    method: "DELETE",
    onStart: requestStarted.type,
    onSuccess: companyDeleted.type,
    onError: requestedFailed.type,
  });
