import React from "react";
import styled from "styled-components/macro";
import { makeStyles } from "@material-ui/core/styles";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { getOneCompany } from "../../redux/reducers/companiesReducer";
import Loading from "../customComponent/Loading";
import { Add, SettingsApplicationsRounded } from "@material-ui/icons";
import { Trash } from "react-feather";
import AssignIntegrationPopUp from "./AssignIntegrationPopUp";
import ArchiveDialog from "../customComponent/AlertDialog";

const Divider = styled(MuiDivider)(spacing);

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const useStyles = makeStyles({
  icon: {
    width: "100%",
    color: "white",
  },
  newSetting: {
    color: "white",
    height: "100%",
    marginRight: "0.5rem",
    backgroundColor: "#23CC94",
    "&:hover": {
      backgroundColor: "#248262",
    },
  },
  deleteSetting: {
    color: "white",
    height: "100%",
    marginRight: "0.5rem",
    backgroundColor: "#da2525",
    "&:hover": {
      backgroundColor: "#248262",
    },
  },
});

const CompanyIntegrations = () => {
  const [integrationPopUpOpen, setIntegrationPopUpOpen] = React.useState(false);
  const [openArchiveDialog, setOpenArchiveDialog] = React.useState(false);
  const [integrationToDelete, setIntegrationToDelete] = React.useState(null);

  const classes = useStyles();
  let { id } = useParams();
  const dispatch = useDispatch();

  // const [integrations, setIntegrations] = React.useState(null);

  const { selected_company } = useSelector((state) => state.entities.companies);

  let { list_all: integrations } = useSelector(
    (state) => state.entities.integrations
  );

  React.useEffect(() => {
    if (selected_company) {
    } else {
      dispatch(getOneCompany(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_company, integrations]);

  // add new Integration
  const setIntegration = (integrations) => {
    console.log(integrations);
  };

  // open Setting Dialog
  const updateIntegrationSettings = (id) => {
    console.log(id);
  };

  // Delete Integration
  const afterIntegrationDelete = () => {
    console.log(integrationToDelete);
    setIntegrationToDelete(null);
  };

  if (!selected_company) return <Loading />;
  return (
    <>
      {integrationToDelete && (
        <ArchiveDialog
          title="Delete Integration"
          description="This will remove the integration from this company. Are you sure?"
          open={openArchiveDialog}
          setOpen={setOpenArchiveDialog}
          onYes={afterIntegrationDelete}
        />
      )}
      <Card mb={6}>
        <CardContent>
          <Grid justify="space-between" container>
            <Grid item>
              <Typography variant="h3" gutterBottom display="inline">
                Integrations
              </Typography>
            </Grid>
            <Grid item>
              <Box display="flex" align="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setIntegrationPopUpOpen(true)}
                >
                  <Add size={18} style={{ marginRight: "5px" }} />
                  Assign Integration
                </Button>
              </Box>
            </Grid>
          </Grid>
          <AssignIntegrationPopUp
            open={integrationPopUpOpen}
            setOpen={setIntegrationPopUpOpen}
            list={integrations}
            setIntegration={setIntegration}
          />
          <Divider my={6} />
          <Grid container spacing={6}>
            {selected_company &&
              selected_company.integrations.map((item, index) => (
                <div key={index}>
                  <Grid item md={10}>
                    <Typography variant="h5" gutterBottom display="inline">
                      {item.slug}
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Button
                      variant="contained"
                      className={classes.newSetting}
                      onClick={() =>
                        updateIntegrationSettings(item.integration)
                      }
                    >
                      <SettingsApplicationsRounded />
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.deleteSetting}
                      onClick={() => {
                        setIntegrationToDelete(item.integration);
                        setOpenArchiveDialog(true);
                      }}
                    >
                      <Trash />
                    </Button>
                  </Grid>
                </div>
              ))}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default CompanyIntegrations;
