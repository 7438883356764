import React from "react";
import styled, { withTheme } from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import { useSelector } from "react-redux";
import ProjectPopup from "./components/ProjectPopup";

import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Box,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { spacing } from "@material-ui/system";

import Actions from "./components/Actions";

import { Plus } from "react-feather";
import MyStats from "../customComponent/MyStats";
import AddNewBrandPopUp from "../brands/AddNewBrandPopUp";
import { showNumber } from "../../helpers/functions";

const useStyles = makeStyles({
  campaign: {
    marginRight: "12px",
    height: "40px",
    backgroundColor: "#6A74C9",
    color: "white",
    "&:hover": {
      backgroundColor: "#424c96",
    },
  },
  icon: {
    width: "20px",
    color: "white",
  },
  newProject: {
    color: "white",
    height: "40px",
    backgroundColor: "#23CC94",
    "&:hover": {
      backgroundColor: "#248262",
    },
    width: "9rem",
  },
});

const Divider = styled(MuiDivider)(spacing);
const Spacer = styled.div(spacing);

const Typography = styled(MuiTypography)(spacing);

const Dashboard = ({ theme }) => {
  const classes = useStyles();
  const [openAddCompany, setOpenAddCompany] = React.useState(false);
  const [openAddBrand, setOpenAddBrand] = React.useState(false);

  const { user } = useSelector((state) => state.entities.auth.data);
  const first_name = user ? user.first_name : "";
  const { stats } = useSelector((state) => state.entities.dashboard);

  const handleClickOpenAddCompany = () => {
    setOpenAddCompany(true);
  };
  const handleClickOpenAddBrand = () => {
    setOpenAddBrand(true);
  };

  const handleClose = () => {
    setOpenAddCompany(false);
  };

  // const handleNotificationClose = () => {
  //   setNotification(false);
  //   dispatch(resetAll());
  // };

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid justify="space-between" container spacing={2}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Admin Dashboard
          </Typography>
          <Typography variant="subtitle2">
            Welcome back, <span>{first_name ? first_name : ""}!</span> We've
            missed you.
            <span role="img" aria-label="Waving Hand Sign">
              👋
            </span>
          </Typography>
        </Grid>

        <Grid item>
          <Box display="flex">
            <Button
              variant="contained"
              startIcon={<Plus className={classes.icon} />}
              className={classes.campaign}
              onClick={handleClickOpenAddBrand}
            >
              New Brand
            </Button>
            <Button
              variant="contained"
              startIcon={<Plus className={classes.icon} />}
              className={classes.newProject}
              onClick={handleClickOpenAddCompany}
            >
              New Company
            </Button>
            {openAddCompany && (
              <ProjectPopup
                open={openAddCompany}
                setOpen={setOpenAddCompany}
                project={null}
                handleClose={handleClose}
              />
            )}
            {openAddBrand && (
              <AddNewBrandPopUp
                open={openAddBrand}
                setOpen={setOpenAddBrand}
                handleClose={handleClose}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid justify="space-between" container spacing={2}>
        <Grid item>
          <Typography variant="h4" gutterBottom>
            Global Overview
          </Typography>
        </Grid>

        <Grid item>
          <Actions />
        </Grid>
      </Grid>

      <Spacer mb={5} />
      <Grid container spacing={6}>
        <MyStats
          title="Sign up's last 24h"
          textBottomLeft="Paid"
          NBLPrefix=""
          numberBottomLeft={showNumber(stats.signUp_paid)}
          textBottomRight="Pending"
          NBRPrefix=""
          amount={showNumber(stats.signUp)}
          numberBottomRight={showNumber(stats.signUp_pending)}
        />
        <MyStats
          xs={12}
          sm={6}
          md={3}
          title="Companies"
          titleColor={theme.palette.primary.main}
          amount={showNumber(stats.companies)}
          prefix=""
          amountColor={theme.palette.primary.main}
          tooltip="Total clients"
        />
        <MyStats
          title="Users"
          amount={showNumber(stats.total_users)}
          numberBottomLeft={showNumber(stats.users)}
          textBottomLeft="Users"
          numberBottomRight={showNumber(stats.account_manager)}
          textBottomRight="Managers"
        />
        <MyStats
          title="Net Payment"
          // amount={showNumber(stats.brands)}
        />
        {/* <MyStats
          title="Total MRR"
          // amount={showNumber(stats.totalIntegrations)}
          prefix="$"
        />
        <MyStats
          title="Net Billing"
          // tooltip="Average Revenue Per Customer"
          // amount={showNumber(stats.submissionSuccessPercent)}
          prefix="$"
        />
        <MyStats
          title="Net Payment"
          // amount={showNumber(stats.brands)}
        />
        <MyStats
          title="Unpaid Invoices"
          //    amount={showNumber(stats.cost_per_lead)}
          prefix="$"
        /> */}
      </Grid>
      <Spacer mt={10} />
      <Grid item xs={12} lg={12}>
        {/* <Table /> */}
      </Grid>

      <Spacer mb={6} />
    </React.Fragment>
  );
};

export default withTheme(Dashboard);
