import React from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { Close as CloseIcon, DetailsSharp } from "@material-ui/icons";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  CardContent,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert as MuiAlert } from "@material-ui/lab";
// import { spacing } from "@material-ui/system";
import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import { updateMyProfile } from "../../redux/reducers/authReducer";

const Alert = styled(MuiAlert)(spacing);

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#6320EE",
    marginRight: "10px",
    marginLeft: "5px",
  },
  // paper: {
  //     borderRadius: "12px",
  //     boxShadow: "0px 0px 12px -5px #000000",
  // },
  dialogwrapper: {
    position: "absolute",
    width: "40rem",
    // top: theme.spacing(5)
  },
  dialogTitle: {
    paddingRight: "0px",
    float: "right",
  },

  label: {
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  rootButton: {
    minWidth: "39px",
    marginTop: "-2px",
    marginRight: "11px",
  },
  ButtonAprove: {
    backgroundColor: "#23CC94",
    color: "white",
    width: "6rem",
    margin: "12px 39px",
  },
  discription: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    marginTop: "10px",
  },
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  h5: {
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "5px",
  },
  dialogContent: {
    padding: "0px 24px",
  },
  controlinput: {
    backgroundColor: "#F9F9FC",
  },
  select: {
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    padding: "4px 12px ",
    // backgroundColor: "white"
  },
  shrink: {
    display: "none",
  },
  inputSelect: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  paperSelect: {
    backgroundColor: "#f5f6fa",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
}));

const Popup = ({ open, setOpen, handleClose }) => {
  const classes = useStyles();
  const { data, isLoading, success, error } = useSelector(
    (state) => state.entities.auth
  );
  const dispatch = useDispatch();
  const [isChangePassword, setIsChangePassword] = React.useState(
    data.password_change
  );

  React.useEffect(() => {
    setIsChangePassword(data.password_change);
  }, [data.password_change]);

  React.useEffect(() => {
    if (success) setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  //  const timeOut = (time) => new Promise((res) => setTimeout(res, time));
  const initialValues = {
    name: data.user.name,
    email: data.user.email,
    Password: "",
    confirmPassword: "",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email().required("Required"),
    password: isChangePassword
      ? Yup.string().min(6)
      : Yup.string().min(6).required("Required"),

    confirmPassword: Yup.string()
      .min(6)
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string()
          .required("Confirm Password Required")
          .oneOf([Yup.ref("password")], "Both password need to be the same"),
      }),
  });

  const handleMySubmit = ({ name, email, password }) => {
    let data = { name, email };

    if (password !== "") data.password = password;

    dispatch(updateMyProfile(data));
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogwrapper }}
      open={open}
      onClose={isChangePassword ? handleClose : () => {}}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <DetailsSharp
              style={{ width: "22px", color: "#6320EE", marginLeft: "10px" }}
            />
            <Typography
              variant="h4"
              style={{ fontSize: "16px", fontWeight: "700" }}
            >
              Update Password
            </Typography>
          </div>
          {isChangePassword && (
            <Button
              style={{ color: "#868695" }}
              classes={{ root: classes.rootButton }}
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon />
            </Button>
          )}
        </div>
      </DialogTitle>

      <DialogContent
        style={{ marginTop: "-18px" }}
        classes={{ root: classes.dialogContent }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleMySubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
            handleSubmit,
            touched,
            values,
            status,
          }) => (
            <>
              <CardContent>
                {error && (
                  <Alert severity="danger" my={3}>
                    {error}
                  </Alert>
                )}

                {isLoading ? (
                  <Box display="flex" justifyContent="center" m={12}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={6}>
                      <Grid item md={12} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          Name:
                        </Typography>
                        <TextField
                          InputProps={{ classes: { input: classes.input } }}
                          inputlabelprops={{ shrink: false }}
                          name="name"
                          placeholder="Name"
                          value={values.name}
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={touched.name && errors.name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item md={12} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          Email:
                        </Typography>
                        <TextField
                          InputProps={{
                            classes: { input: classes.input },
                            readOnly: true,
                          }}
                          inputlabelprops={{ shrink: false }}
                          name="email"
                          placeholder="Email"
                          value={values.email}
                          error={Boolean(touched.email && errors.email)}
                          fullWidth
                          helperText={touched.email && errors.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item md={6} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          Password:
                        </Typography>
                        <TextField
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                          inputlabelprops={{ shrink: false }}
                          name="password"
                          placeholder="Password"
                          value={values.password}
                          error={Boolean(touched.password && errors.password)}
                          fullWidth
                          helperText={touched.password && errors.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="password"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                      <Grid item md={6} style={{ padding: "9px" }}>
                        <Typography variant="h5" classes={{ h5: classes.h5 }}>
                          Confirm Password:
                        </Typography>
                        <TextField
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                          inputlabelprops={{ shrink: false }}
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          value={values.confirmPassword}
                          error={Boolean(
                            touched.confirmPassword && errors.confirmPassword
                          )}
                          fullWidth
                          helperText={
                            touched.confirmPassword && errors.confirmPassword
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="password"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>

                    <div style={{ float: "right", margin: "40px 0" }}>
                      {isChangePassword && (
                        <Button
                          style={{ marginRight: "15px" }}
                          // type="outlined"
                          variant="outlined"
                          color="primary"
                          mt={3}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                      )}
                      <Button
                        style={{ width: "10rem", backgroundColor: "#6A74C9" }}
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        mt={3}
                      >
                        Save changes
                      </Button>
                    </div>
                  </form>
                )}
              </CardContent>
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
