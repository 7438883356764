/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Paper,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { makeStyles } from "@material-ui/core/styles";
import ArchiveDialog from "../customComponent/AlertDialog";

import UserCompanyRow from "./UserCompanyRow";
import AssignCompanyToUser from "./AssignCompanyToUser";
import {
  deleteUserCompany,
  updateUserCompanies,
} from "../../redux/reducers/usersReducer";
const Divider = styled(MuiDivider)(spacing);

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const useStyles = makeStyles({
  icon: {
    width: "100%",
    color: "white",
  },
  newSetting: {
    color: "white",
    height: "40px",
    marginRight: "0.5rem",
    backgroundColor: "#4320C4",
    "&:hover": {
      backgroundColor: "#4327ab",
    },
  },
  addSetting: {
    color: "white",
    height: "40px",
    marginRight: "0.5rem",
    backgroundColor: "#1BB27C",
    "&:hover": {
      backgroundColor: "#258f69",
    },
  },
  deleteSetting: {
    color: "white",
    height: "100%",
    marginRight: "0.5rem",
    backgroundColor: "#da2525",
    "&:hover": {
      backgroundColor: "#248262",
    },
  },
});

const UpdateUserCompanies = () => {
  const [companies, setCompanies] = React.useState([]);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [openAddCompany, setOpenAddCompany] = React.useState(false);
  const [companyToDelete, setCompanyToDelete] = React.useState(null);

  const dispatch = useDispatch();
  const classes = useStyles();

  let { selected_user, companiesUpdated, isLoading } = useSelector(
    (state) => state.entities.users
  );

  React.useEffect(() => {
    if (selected_user?.companies) {
      setCompanies(selected_user.companies);
    }
  }, [selected_user]);

  React.useEffect(() => {
    if (companiesUpdated && selected_user) {
      setCompanies(selected_user.companies);
      setOpenAddCompany(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_user.companies]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let newList = [];
    companies.map((item) => {
      return newList.push({
        company: item.company,
        user: selected_user.user._id,
        role: item.role,
      });
    });
    // console.log(newList);
    dispatch(updateUserCompanies(newList));
  };

  const deleteCompany = (index) => {
    setCompanyToDelete(index);
    setDeleteOpen(true);
  };
  const onDeleteCompany = () => {
    let company = companies[companyToDelete];
    dispatch(
      deleteUserCompany({
        company: company.company,
        user: selected_user.user._id,
      })
    );
    setCompanyToDelete(null);
  };
  const setCompany = (company) => {
    let newList = [];
    newList.push({
      company: company._id,
      user: selected_user.user._id,
      role: company.role,
    });
    dispatch(updateUserCompanies(newList));
    setOpenAddCompany(false);
  };

  function handleChangeRole(index, updated) {
    let updatedCompanies = [];

    let res = companies.map((company, i) => {
      if (i === index) return updatedCompanies.push(updated);
      else return updatedCompanies.push(company);
    });
    setCompanies(updatedCompanies);
  }

  return (
    <>
      {!selected_user ? (
        <Box display="flex" justifyContent="center" my={0}>
          <CircularProgress />
        </Box>
      ) : (
        <Paper>
          <ArchiveDialog
            title="Delete Company"
            description="This will remove the user from accessing this company. Are you sure you wanna proceed?"
            open={deleteOpen}
            setOpen={setDeleteOpen}
            onYes={onDeleteCompany}
          />

          <Card mb={6}>
            <CardContent>
              <Typography variant="h3" gutterBottom display="inline">
                User Companies
              </Typography>
              <Divider my={6} />
              <form onSubmit={handleSubmit}>
                {companies &&
                  Object.keys(companies).map((key, index) => {
                    //console.log(index);
                    return (
                      <UserCompanyRow
                        index={index}
                        company={companies[key]}
                        remove={deleteCompany}
                        change={handleChangeRole}
                      />
                    );
                  })}

                {openAddCompany && <AssignCompanyToUser add={setCompany} />}

                {!openAddCompany && (
                  <Button
                    variant="contained"
                    className={classes.newSetting}
                    type="submit"
                    color="primary"
                    mt={3}
                  >
                    {isLoading ? "Updating..." : "Save changes"}
                  </Button>
                )}
                <Button
                  variant="contained"
                  className={classes.addSetting}
                  color="primary"
                  onClick={() => setOpenAddCompany(!openAddCompany)}
                  mt={3}
                >
                  {openAddCompany ? "Cancel" : "Assign New Company"}
                </Button>
              </form>
            </CardContent>
          </Card>
        </Paper>
      )}
    </>
  );
};

export default UpdateUserCompanies;
