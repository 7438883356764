import React from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { getBrand, updateBrand } from "../../redux/reducers/brandsReducer";
import Chip from "../customComponent/Chips";

const Divider = styled(MuiDivider)(spacing);

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const GeneralSettings = () => {
  let { id } = useParams();
  const dispatch = useDispatch();

  const [data, setData] = React.useState({
    name: "",
    domain: "",
    platform_url: "",
    prefix: "",
    description: "",
    integrations: [],
  });

  const [integrations, setIntegrations] = React.useState(null);
  const [itemFamily, setItemFamily] = React.useState([]);
  const [selectedFamily, setSelectedFamily] = React.useState(null);

  const [selectedIntegrations, setSelectedIntegrations] = React.useState(null);
  let { selected_brand, isLoading, chargeBee_items_families } = useSelector(
    (state) => state.entities.brands
  );
  let { list_all } = useSelector((state) => state.entities.integrations);

  React.useEffect(() => {
    if (selected_brand && list_all.length) {
      setSelectedIntegrations(selected_brand.integrations);
      setIntegrations(selected_brand.integrations);
      setData(selected_brand);
      // console.log(selected_brand.integrations, list_all);
    } else {
      dispatch(getBrand(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_brand, list_all]);
  React.useEffect(() => {
    if (chargeBee_items_families && selected_brand) {
      let found = chargeBee_items_families.find(
        (item) => item.id === selected_brand.charge_bee_item_family_id
      );

      setSelectedFamily(found);
      console.log(found);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargeBee_items_families]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateBrand({
        ...data,
        integrations: selectedIntegrations,
        charge_bee_item_family_id: itemFamily.id,
      })
    );
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h4" gutterBottom display="inline">
          General Settings
        </Typography>
        <Divider my={6} />
        {/* {status && status.sent && (
              <Alert severity="success" my={3}>
                [DEMO] Your data has been submitted successfully!
              </Alert>
            )} */}

        {/* {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : ( */}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item md={12}>
              <TextField
                name="name"
                label="Brand Name"
                onChange={handleChange}
                value={data.name}
                fullWidth
                variant="outlined"
                required
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                name="domain"
                label="Domain"
                value={data.domain}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                required
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                name="platform_url"
                label="Platform URL"
                value={data.platform_url}
                onChange={handleChange}
                fullWidth
                type="url"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                name="prefix"
                label="Companies Prefix"
                value={data.prefix}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                required
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                name="description"
                label="Description"
                value={data.description}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                multiline={true}
                rows={3}
              />
            </Grid>

            <Grid item md={6}>
              {integrations && (
                <Chip
                  list={list_all}
                  selected={integrations}
                  placeholder={"Select Integrations"}
                  label="name"
                  setSelected={setSelectedIntegrations}
                  multiple={true}
                />
              )}
            </Grid>
            <Grid item md={6}>
              {selectedFamily !== null && chargeBee_items_families && (
                <Chip
                  list={chargeBee_items_families}
                  selected={selectedFamily}
                  placeholder={"Select Items Family"}
                  label="name"
                  input_label="Select Items Family"
                  setSelected={setItemFamily}
                />
              )}
            </Grid>
          </Grid>
          <Button
            type="submit"
            disabled={isLoading}
            variant="contained"
            color="primary"
            mt={3}
          >
            {isLoading ? "Saving..." : "Save changes"}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default GeneralSettings;
