import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/apiActions";
import * as globals from "../../constants/index";
const url = "auth";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    data: { user: null },
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
  },
  reducers: {
    resetAll: (state) => {
      state.isLoading = false;
      state.error = null;
      state.success = null;
    },
    loginSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.success = "Login success";
    },

    requestStarted: (state, action) => {
      state.isLoading = true;
      state.isRequestSent = true;
    },
    requestSuccess: (state, action) => {
      state.success = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    requestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    profileUpdated: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.success = "Profile Updated";
    },
    resetErrorAndSuccess: (state) => {
      state.error = null;
      state.isLoading = false;
      state.success = null;
    },
    signOut: (state) => {
      localStorage.removeItem(globals.USER_TOKEN);
      state.error = null;
      state.isLoading = false;
      state.success = null;
      state.data = { user: null };
    },
  },
});
export default authSlice.reducer;
export const {
  requestStarted,
  requestSuccess,
  requestFailed,
  loginSuccess,
  resetErrorAndSuccess,
  profileUpdated,
  signOut,
  resetAll,
} = authSlice.actions;

export const login = (data) =>
  apiCallBegan({
    url: url + "/login",
    data,
    method: "POST",
    onStart: requestStarted.type,
    onSuccess: loginSuccess.type,
    onError: requestFailed.type,
  });

export const updateMyProfile = (data) =>
  apiCallBegan({
    url: "users/me",
    data,
    method: "PUT",
    onStart: requestStarted.type,
    onSuccess: profileUpdated.type,
    onError: requestFailed.type,
  });

export const sendResetPassword = (data) =>
  apiCallBegan({
    url: url + "/forgot-password",
    data,
    method: "POST",
    onStart: requestStarted.type,
    onSuccess: requestSuccess.type,
    onError: requestFailed.type,
  });

export const resetPassword = (data) =>
  apiCallBegan({
    url: url + "/reset-password",
    data,
    method: "POST",
    onStart: requestStarted.type,
    onSuccess: requestSuccess.type,
    onError: requestFailed.type,
  });

export const getUserByToken = () => {
  return apiCallBegan({
    url: url + `/token`,
    method: "POST",
    onStart: requestStarted.type,
    onSuccess: loginSuccess.type,
    onError: signOut.type,
  });
};
