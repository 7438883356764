import axios from "axios";
import { getToken } from "../helpers/functions";

const authAxios = () => {
  let token = getToken();
  const axiosData = {
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 60000,
  };

  if (token) {
    axiosData.headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
  }

  return axios.create(axiosData);
};

export default authAxios;
