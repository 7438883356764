import React from "react";
import styled from "styled-components/macro";

import {
  Button as MuiButton,
  Divider as MuiDivider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { Trash } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "../customComponent/Chips";
const Divider = styled(MuiDivider)(spacing);

const Button = styled(MuiButton)(spacing);

const useStyles = makeStyles({
  icon: {
    width: "100%",
    color: "white",
  },
  newSetting: {
    color: "white",
    height: "100%",
    marginRight: "0.5rem",
    backgroundColor: "#23CC94",
    "&:hover": {
      backgroundColor: "#248262",
    },
  },
  deleteSetting: {
    color: "white",
    height: "100%",
    marginRight: "0.5rem",
    backgroundColor: "#da2525",
    "&:hover": {
      backgroundColor: "#248262",
    },
  },
});

const UserCompanyRow = ({ index, company, remove, change }) => {
  const user_roles = [{ name: "Owner" }, { name: "Admin" }, { name: "User" }];

  const [companyRole, setCompanyRole] = React.useState();
  const classes = useStyles();

  React.useEffect(() => {
    if (company) {
      let role = company.role;
      setCompanyRole({
        name: role.charAt(0).toUpperCase() + company.role.slice(1),
      });
    }
  }, [company]);

  const changeRole = (value) => {
    if (value && company) {
      let updated = { ...company, role: value.name.toLowerCase() };
      change(index, updated);
    }
  };

  return (
    <>
      <Paper>
        <Grid container spacing={4}>
          <Grid item md={4}>
            <Typography variant="h4" style={{ marginTop: "16px" }}>
              {company && company.company_name}
            </Typography>
          </Grid>
          <Grid item md={7}>
            {companyRole && (
              <Chip
                list={user_roles}
                selected={companyRole}
                input_label={true}
                placeholder={"User Role"}
                label="name"
                setSelected={changeRole}
              />
            )}
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              className={classes.deleteSetting}
              onClick={() => remove(index)}
            >
              <Trash className={classes.icon} />
            </Button>
          </Grid>
        </Grid>
        <Divider my={3} />
      </Paper>
    </>
  );
};

export default UserCompanyRow;
