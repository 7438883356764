import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/apiActions";
const url = "projects";

const slice = createSlice({
  name: "projects",
  initialState: {
    list: [],
    allProjects: [],
    listType: "active",
    isLoading: false,
    error: null,
    success: null,
    isUpdated: false,
    isRequestSent: null,
  },
  reducers: {
    resetAll: (projects) => {
      projects.isLoading = false;
      projects.error = null;
      projects.success = null;
    },
    requestStarted: (projects, action) => {
      projects.isLoading = true;
      projects.error = null;
      projects.success = null;
      projects.isRequestSent = true;
    },
    requestedFailed: (projects, action) => {
      projects.error = action.payload;
      projects.isLoading = false;
    },
    projectsLoaded: (projects, action) => {
      projects.list = action.payload;
      projects.isLoading = false;
      // set the type of the list
      if (projects.list.length > 0) {
        projects.listType = projects.list[0].deletedAt ? "archived" : "active";
      }
    },
    allProjectsLoaded: (projects, action) => {
      projects.allProjects = action.payload;
      projects.isLoading = false;
    },
    projectAdded: (projects, action) => {
      projects.success = "New project Added";
      projects.list.unshift(action.payload);
      projects.isLoading = false;
    },
    projectUpdated: (projects, action) => {
      const index = projects.list.findIndex(
        (project) => project._id === action.payload._id
      );
      projects.list[index] = action.payload;

      // update allProjects
      const indexAll = projects.allProjects.findIndex(
        (project) => project._id === action.payload._id
      );

      projects.allProjects[indexAll].name = action.payload.name;

      projects.isLoading = false;

      projects.success = "Project Updated";
      projects.isUpdated = true;
    },
    projectArchived: (projects, action) => {
      const newList = projects.list.filter(
        (project) => project._id !== action.payload._id
      );
      projects.list = newList;
      projects.isLoading = false;
      projects.success = "Project Archived";
    },
    projectRestored: (projects, action) => {
      const newList = projects.list.filter(
        (project) => project._id !== action.payload._id
      );
      projects.list = newList;
      projects.isLoading = false;
      projects.success = "Project Restored";
    },
    setProjectsListType: (projects, action) => {
      projects.listType = action.payload;
    },
  },
});

export const {
  projectAdded,
  projectsLoaded,
  requestedFailed,
  requestStarted,
  projectUpdated,
  resetAll,
  projectArchived,
  projectRestored,
  setProjectsListType,
  allProjectsLoaded,
} = slice.actions;
export default slice.reducer;

export const getProjects = (status = "active") =>
  apiCallBegan({
    url: status === "archived" ? url + "?archived=1" : url,
    onStart: requestStarted.type,
    onSuccess: projectsLoaded.type,
    onError: requestedFailed.type,
  });

export const getAllProjects = (status = "active") =>
  apiCallBegan({
    url: url + "/all",
    onStart: requestStarted.type,
    onSuccess: allProjectsLoaded.type,
    onError: requestedFailed.type,
  });

export const archiveProject = (id) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: "put",
    data: { deletedAt: new Date(), status: false },
    onStart: requestStarted.type,
    onSuccess: projectArchived.type,
    onError: requestedFailed.type,
  });

export const restoreProject = (id) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: "put",
    data: { deletedAt: null },
    onStart: requestStarted.type,
    onSuccess: projectRestored.type,
    onError: requestedFailed.type,
  });

export const addProject = (data) =>
  apiCallBegan({
    url,
    method: "post",
    data,
    onStart: requestStarted.type,
    onSuccess: projectAdded.type,
    onError: requestedFailed.type,
  });

export const updateProject = (id, data) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: "put",
    data,
    onStart: requestStarted.type,
    onSuccess: projectUpdated.type,
    onError: requestedFailed.type,
  });
