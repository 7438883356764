import { createSlice } from "@reduxjs/toolkit";
import { getNumberPerPage } from "../../helpers/functions";
import { apiCallBegan } from "../actions/apiActions";
const url = "brands";

const slice = createSlice({
  name: "brands",
  initialState: {
    plans_list: [],
    list_all: [],
    list: [],
    chargeBee_items_families: [],
    stats: {},
    selected_brand: null,
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
    page: 0,
    per_page: 0,
    total_pages: 0,
    maxPage: 0,
    total_records: 0,
  },

  reducers: {
    resetAll: (brands) => {
      brands.isLoading = false;
      brands.error = null;
      brands.success = null;
    },
    requestStarted: (brands, action) => {
      brands.isLoading = true;
      brands.error = null;
      brands.isRequestSent = true;
    },
    requestedFailed: (brands, action) => {
      brands.error = action.payload;
      brands.isLoading = false;
    },
    brandsLoaded: (state, action) => {
      state.list = state.list.concat(action.payload.data);
      state.isLoading = false;
      state.page = action.payload.page;
      state.per_page = action.payload.per_page;
      state.total_pages = action.payload.total_pages;
      state.total_records = action.payload.total_records;
      state.search = action.payload.search;
      state.maxPage = state.page > state.maxPage ? state.page : state.maxPage;
    },
    plansLoaded: (state, action) => {
      state.plans_list = action.payload;
      state.isLoading = false;
    },
    brandsReLoaded: (state, action) => {
      state.list = action.payload.data;
      state.isLoading = false;
      state.page = action.payload.page;
      state.per_page = action.payload.per_page;
      state.total_pages = action.payload.total_pages;
      state.total_records = action.payload.total_records;
      state.search = action.payload.search;
      state.maxPage = 0;
    },
    allBrandsLoaded: (brands, action) => {
      brands.list_all = action.payload;
      brands.isLoading = false;
    },
    setSelectedBrand: (brands, action) => {
      brands.selected_brand = action.payload;
    },
    brandStatsLoaded: (brands, action) => {
      // brands.stats = action.payload;
      brands.isLoading = false;
    },
    brandAdded: (brands, action) => {
      brands.list_all.unshift(action.payload);
      brands.list.unshift(action.payload);
      brands.isLoading = false;
      brands.success = "Brand Added.";
    },
    selectedBrandLoaded: (brands, action) => {
      brands.selected_brand = action.payload;
      brands.isLoading = false;
    },
    planAdded: (brands, action) => {
      brands.selected_brand = action.payload;
      brands.isLoading = false;
      brands.success = "Plan Added.";
    },

    itemsFamiliesLoaded: (brands, action) => {
      brands.chargeBee_items_families = action.payload;
      brands.isLoading = false;
    },

    planDeleted: (brands, action) => {
      const index = brands.list.findIndex(
        (brand) => brand._id === action.payload._id
      );

      if (index !== -1)
        brands.list[index] = { ...brands.list[index], ...action.payload };

      brands.selected_brand.plan_ids = action.payload.plan_ids;
      brands.isLoading = false;
      brands.success = "Plan Deleted.";
    },
    brandUpdated: (brands, action) => {
      const index = brands.list.findIndex(
        (brand) => brand._id === action.payload._id
      );

      if (index !== -1)
        brands.list[index] = { ...brands.list[index], ...action.payload };

      const j = brands.list_all.findIndex(
        (brand) => brand._id === action.payload._id
      );

      if (j !== -1)
        brands.list_all[j] = {
          _id: action.payload._id,
          name: action.payload.name,
          slug: action.payload.slug,
        };

      brands.selected_brand = action.payload;
      //brands.list = action.payload;
      brands.isLoading = false;
      brands.success = "Brand Updated.";
    },
  },
});

export const {
  brandsLoaded,
  brandsReLoaded,
  setSelectedBrand,
  allBrandsLoaded,
  brandStatsLoaded,
  brandAdded,
  selectedBrandLoaded,
  brandUpdated,
  requestedFailed,
  requestStarted,
  resetAll,
  plansLoaded,
  planAdded,
  planDeleted,
  itemsFamiliesLoaded,
} = slice.actions;
export default slice.reducer;

export const getAllBrands = () =>
  apiCallBegan({
    url: url + "/list",
    onStart: requestStarted.type,
    onSuccess: allBrandsLoaded.type,
    onError: requestedFailed.type,
  });

export const getBrands = (params = {}) => {
  params.per_page = getNumberPerPage();
  if (params.search === "" || params.search === null) delete params.search;
  let query = new URLSearchParams(params);
  // // Reload the data if no page is provided
  let resultsReceived = params.page ? brandsLoaded.type : brandsReLoaded.type;
  return apiCallBegan({
    url: url + "?" + query,
    onStart: requestStarted.type,
    onSuccess: resultsReceived,
    onError: requestedFailed.type,
  });
};

export const getBrandStats = (params = {}) => {
  if (params.brand === "all" || params.brand === null) delete params.brand;

  if (params.startDate === null) {
    delete params.date;
  } else {
    if (params.date && params.date.startDate)
      params.startDate = params.date.startDate;
    if (params.date && params.date.endDate)
      params.endDate = params.date.endDate;

    delete params.date;
  }

  let query = new URLSearchParams(params);
  return apiCallBegan({
    url: url + `/stats?${query}`,
    onStart: requestStarted.type,
    onSuccess: brandStatsLoaded.type,
    onError: requestedFailed.type,
  });
};

export const updateBrand = (data = {}) =>
  apiCallBegan({
    url,
    method: "put",
    data,
    onStart: requestStarted.type,
    onSuccess: brandUpdated.type,
    onError: requestedFailed.type,
  });
export const addPlan = (data) =>
  apiCallBegan({
    url: url + "/plan",
    method: "POST",
    data,
    onStart: requestStarted.type,
    onSuccess: planAdded.type,
    onError: requestedFailed.type,
  });
export const removePlan = (data) =>
  apiCallBegan({
    url: url + "/plan",
    method: "DELETE",
    data,
    onStart: requestStarted.type,
    onSuccess: planDeleted.type,
    onError: requestedFailed.type,
  });

export const getBrand = (id) =>
  apiCallBegan({
    url: url + "/" + id,
    method: "get",
    onStart: requestStarted.type,
    onSuccess: selectedBrandLoaded.type,
    onError: requestedFailed.type,
  });

export const getPlans = () =>
  apiCallBegan({
    url: url + "/plans",
    method: "GET",
    onStart: requestStarted.type,
    onSuccess: plansLoaded.type,
    onError: requestedFailed.type,
  });
export const getItemsFamilies = () =>
  apiCallBegan({
    url: "/billing/item-families",
    method: "GET",
    onStart: requestStarted.type,
    onSuccess: itemsFamiliesLoaded.type,
    onError: requestedFailed.type,
  });

export const addBrand = (data) =>
  apiCallBegan({
    url,
    method: "post",
    data,
    onStart: requestStarted.type,
    onSuccess: brandAdded.type,
    onError: requestedFailed.type,
  });

export const archiveBrand = (id) =>
  apiCallBegan({
    url,
    method: "put",
    onStart: requestStarted.type,
    onSuccess: brandAdded.type,
    onError: requestedFailed.type,
  });

export const restoreBrand = (id) =>
  apiCallBegan({
    url,
    method: "put",
    onStart: requestStarted.type,
    onSuccess: brandAdded.type,
    onError: requestedFailed.type,
  });
