import authAxios from "../../api/axios";
import * as actions from "../actions/apiActions";

const api = ({ dispatch }) => (next) => async (action) => {
  if (action.type !== actions.apiCallBegan.type) return next(action);

  const { url, method, onStart, data, onSuccess, onError } = action.payload;

  if (onStart) dispatch({ type: onStart });
  next(action);

  try {
    const axios = authAxios();
    const response = await axios({
      url,
      method,
      data,
    });

    dispatch(actions.apiCallSuccess(response.data));

    if (onSuccess)
      dispatch({
        type: onSuccess,
        payload: response.data,
      });
  } catch (error) {
    let message;
    if (error.response && error.response.data.message)
      message = error.response.data.message;
    else message = error.message;
    dispatch(actions.apiCallFailed(message));

    if (onError)
      dispatch({
        type: onError,
        payload: message,
      });
  }
};

export default api;
