import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, Box } from "@material-ui/core";
import { getBrands } from "../../../redux/reducers/brandsReducer";
import MySelect from "../../customComponent/MySelect";
import DatePicker from "../../customComponent/DateRangePicker";
import { Reload } from "../../customComponent/Reload";
import { getDashboardStats } from "../../../redux/reducers/dashboardReducer";

const Actions = () => {
  const dispatch = useDispatch();

  const { list_all: brandsList, isLoading } = useSelector(
    (state) => state.entities.brands
  );

  const { selected_date, selected_brand } = useSelector(
    (state) => state.entities.dashboard
  );

  const handleRefresh = () => {
    dispatch(getBrands());
    dispatch(getDashboardStats({ brand: selected_brand, date: selected_date }));
  };

  return (
    <React.Fragment>
      <Box display="flex" align="center">
        <Reload loading={isLoading} action={handleRefresh} />

        <FormControl style={{ marginRight: "1rem" }}>
          <MySelect
            selected={selected_brand}
            defaultLabel="All Brands"
            defaultValue="all"
            list={brandsList}
            label="name"
            value="_id"
            valueHolder="brand"
            data={{ date: selected_date }}
            toDispatch={getDashboardStats}
          />
        </FormControl>

        <FormControl>
          <DatePicker
            data={{ brand: selected_brand }}
            toDispatch={getDashboardStats}
          />
        </FormControl>
      </Box>
    </React.Fragment>
  );
};

export default Actions;
