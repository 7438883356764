import React from "react";
import styled from "styled-components/macro";
import { CardComponent } from "@chargebee/chargebee-js-react-wrapper";
import { Helmet } from "react-helmet-async";
import { Typography } from "@material-ui/core";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

const PayCardExample = () => {
  const cardRef = React.createRef();

  React.useEffect(() => {
    window.Chargebee.init({
      site: "webdevgroup-test",
      publishableKey: "test_nluFGBAnFW3tuIxVYa9wcdcRwKBQvcdePB",
    });
  }, []);

  function onSubmit() {
    // Call the tokenize method through the ref
    cardRef.current.tokenize().then((data) => {
      console.log("ChargeBee: ", data.token);
    });
  }

  function onChange() {}

  return (
    <Wrapper>
      <Helmet title="Pay card" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        Pay Now:
      </Typography>

      <form
        style={{ width: "800px" }}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <CardComponent ref={cardRef} onChange={onChange} />
        <button style={{ marginTop: "50px" }} onClick={onSubmit}>
          Pay
        </button>
      </form>
    </Wrapper>
  );
};

export default PayCardExample;
