import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  CardContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import { addBrand } from "../../redux/reducers/brandsReducer";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#6320EE",
    marginRight: "10px",
    marginLeft: "5px",
  },

  dialogWrapper: {
    position: "absolute",
    maxWidth: "50rem",
  },
  dialogTitle: {
    paddingRight: "0px",
    float: "right",
  },
  startICon: {
    margin: 0,
  },
  chips: {
    height: "20px",
    borderRadius: "0px",
  },
  label: {
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  rootButton: {
    minWidth: "39px",
    marginTop: "-2px",
    marginRight: "11px",
  },
  ButtonApprove: {
    backgroundColor: "#23CC94",
    color: "white",
    width: "6rem",
    margin: "12px 39px",
  },
  description: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    marginTop: "10px",
  },
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  h5: {
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "5px",
  },
  dialogContent: {
    padding: "0px 24px",
  },
  controlInput: {
    backgroundColor: "#F9F9FC",
  },
  select: {
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    padding: "4px 12px ",
  },
  shrink: {
    display: "none",
  },
  inputSelect: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  paperSelect: {
    backgroundColor: "#f5f6fa",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
}));

const validationSchema = Yup.object().shape({
  domain: Yup.string().required("Brand Domain is required.."),
  name: Yup.string().required("Brand name is required"),
  prefix: Yup.string().min(2).max(3).required("Prefix is required"),
});

const AddNewBrandPopUp = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  let { success, list_all, isLoading } = useSelector(
    (state) => state.entities.brands
  );

  React.useEffect(() => {
    if (success) {
      let addedBrand = list_all[list_all.length - 1];
      history.push("/brands/" + addedBrand._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const initialValues = {
    name: "",
    description: "",
    domain: "",
    prefix: "",
  };

  const classes = useStyles();

  const handleSubmit = (values) => {
    dispatch(addBrand(values));
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogWrapper }}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h6" gutterBottom>
          New Brand
        </Typography>
        <div style={{ display: "flex", float: "right" }}>
          <Button
            style={{ color: "#868695", marginTop: "-40px" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent
        style={{ marginTop: "-18px" }}
        classes={{ root: classes.dialogContent }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            status,
          }) => (
            <>
              <CardContent>
                <Typography variant="body2" gutterBottom>
                  Create a new Brand then configure its settings to meet your
                  needs.
                </Typography>

                <form onSubmit={handleSubmit}>
                  <Grid container>
                    <Grid item md={12} style={{ padding: "9px 0" }}>
                      <Typography variant="h5" classes={{ h5: classes.h5 }}>
                        Name:
                      </Typography>
                      <TextField
                        name="name"
                        placeholder="Name"
                        inputlabelprops={{ shrink: false }}
                        value={values.name}
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={12} style={{ padding: "9px 0" }}>
                      <Typography variant="h5" classes={{ h5: classes.h5 }}>
                        Domain:
                      </Typography>
                      <TextField
                        name="domain"
                        placeholder="Domain"
                        inputlabelprops={{ shrink: false }}
                        value={values.domain}
                        error={Boolean(touched.domain && errors.domain)}
                        fullWidth
                        helperText={touched.domain && errors.domain}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>

                    <Grid item md={12}>
                      <Typography variant="h5" classes={{ h5: classes.h5 }}>
                        Companies Prefix:
                      </Typography>
                      <TextField
                        name="prefix"
                        placeholder="Companies Prefix"
                        value={values.prefix}
                        fullWidth
                        required
                        inputlabelprops={{ shrink: false }}
                        error={Boolean(touched.prefix && errors.prefix)}
                        helperText={touched.prefix && errors.prefix}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        mt={4}
                      />
                    </Grid>

                    <Grid item md={12} style={{ padding: "9px 0" }}>
                      <Typography variant="h5" classes={{ h5: classes.h5 }}>
                        Description:
                      </Typography>
                      <TextField
                        name="description"
                        placeholder="Description"
                        multiline={true}
                        rows={3}
                        inputlabelprops={{ shrink: false }}
                        value={values.description}
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        fullWidth
                        helperText={touched.description && errors.description}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                  </Grid>

                  <Button
                    style={{
                      width: "10rem",
                      backgroundColor: isLoading ? "#6A74C9" : "#6320EE",
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    mt={6}
                  >
                    {!isLoading ? "Add New" : "Loading..."}
                  </Button>
                </form>
              </CardContent>
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddNewBrandPopUp;
