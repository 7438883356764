import React from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Typography,
  Tab,
  Tabs,
  Divider as MuiDivider,
  Paper as MuiPaper,
} from "@material-ui/core/";
import { Helmet } from "react-helmet-async";
import { spacing } from "@material-ui/system";
import GeneralSettings from "./GeneralSettings";
import { useDispatch, useSelector } from "react-redux";
import { getBrand, resetAll } from "../../redux/reducers/brandsReducer";
import SettingsTab from "./SettingsTab";
import { useParams } from "react-router-dom";
import NotificationAlerts from "../../components/NotificationAlerts";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const EditBrands = () => {
  let { id } = useParams();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();

  const { error, success, selected_brand } = useSelector(
    (state) => state.entities.brands
  );

  React.useEffect(() => {
    if (!selected_brand) {
      dispatch(getBrand(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_brand]);
  
  React.useEffect(() => {
      dispatch(getBrand(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  
  

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <NotificationAlerts error={error} success={success} reset={resetAll} />
      <Helmet title="Edit Brands" />
      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Update Brand - {selected_brand && `: (${selected_brand.name})`}
          </Typography>
        </Grid>
        <Divider my={6} />
        <Grid container spacing={6}>
          <Grid item xs={12} md={12}>
            <Paper>
              <Box className={classes.root}>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  className={classes.tabs}
                  style={{ width: "250px" }}
                >
                  <Tab label="General Settings" {...a11yProps(0)} />
                  <Tab label="Options" {...a11yProps(1)} />
                  {/* <Tab label="Plans" {...a11yProps(1)} /> */}
                  <Tab label="Email Providers" {...a11yProps(2)} />
                  <Tab label="Email Templates" {...a11yProps(3)} />
                  <Tab label="Content (Text & Numbers)" {...a11yProps(4)} />
                  <Tab label="Images" {...a11yProps(5)} />
                  <Tab label="Colors" {...a11yProps(6)} />
                </Tabs>
                <TabPanel style={{ width: "100%" }} value={value} index={0}>
                  <GeneralSettings />
                </TabPanel>
                {/* <TabPanel style={{ width: "100%" }} value={value} index={1}>
                  <BrandPlans />
                </TabPanel> */}
                <TabPanel style={{ width: "100%" }} value={value} index={1}>
                  <SettingsTab type="options" title="Options" />
                </TabPanel>
                <TabPanel style={{ width: "100%" }} value={value} index={2}>
                  <SettingsTab type="email_providers" title="Email Providers" />
                </TabPanel>
                <TabPanel style={{ width: "100%" }} value={value} index={3}>
                  <SettingsTab
                    type="email_templates"
                    title="Sendgrid Email Templates IDs"
                  />
                </TabPanel>
                <TabPanel style={{ width: "100%" }} value={value} index={4}>
                  <SettingsTab
                    type="content"
                    title="Content (Text & Numbers)"
                  />
                </TabPanel>
                <TabPanel style={{ width: "100%" }} value={value} index={5}>
                  <SettingsTab type="images" title="Images URLs" />
                </TabPanel>

                <TabPanel style={{ width: "100%" }} value={value} index={6}>
                  <SettingsTab type="colors" title="Custom Colors" />
                </TabPanel>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EditBrands;
