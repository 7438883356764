import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  CardContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#6320EE",
    marginRight: "10px",
    marginLeft: "5px",
  },

  dialogWrapper: {
    position: "absolute",
    maxWidth: "50rem",
    // top: theme.spacing(5)
  },
  dialogTitle: {
    paddingRight: "0px",
    float: "right",
  },
  startICon: {
    margin: 0,
  },
  chips: {
    height: "20px",
    borderRadius: "0px",
  },
  label: {
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  rootButton: {
    minWidth: "39px",
    marginTop: "-2px",
    marginRight: "11px",
  },
  ButtonApprove: {
    backgroundColor: "#23CC94",
    color: "white",
    width: "6rem",
    margin: "12px 39px",
  },
  description: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    marginTop: "10px",
  },
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  h5: {
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "5px",
  },
  dialogContent: {
    padding: "0px 24px",
  },
  controlInput: {
    backgroundColor: "#F9F9FC",
  },
  select: {
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    padding: "4px 12px ",
    // backgroundColor: "white"
  },
  shrink: {
    display: "none",
  },
  inputSelect: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  paperSelect: {
    backgroundColor: "#f5f6fa",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
}));

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Setting Name is required.."),
  value: Yup.string(),
});

const NewSetting = ({ open, setOpen, setSetting }) => {
  const initialValues = {
    name: "",
    value: "",
  };

  const classes = useStyles();

  const handleSubmit = (values) => {
    setSetting(values);
    setOpen(false);
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogWrapper }}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h6" gutterBottom>
          New Setting (Option)
        </Typography>
        <div style={{ display: "flex", float: "right" }}>
          <Button
            style={{ color: "#868695", marginTop: "-40px" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent
        style={{ marginTop: "-18px" }}
        classes={{ root: classes.dialogContent }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            status,
          }) => (
            <>
              <CardContent>
                <Typography variant="body2" gutterBottom>
                  Create a setting (key/value) that you can use across the
                  platform under the current Brand.
                </Typography>

                <form onSubmit={handleSubmit}>
                  <Grid container>
                    <Grid item md={12} me={2} style={{ padding: "9px 0" }}>
                      <Typography variant="h5" classes={{ h5: classes.h5 }}>
                        Name:
                      </Typography>
                      <TextField
                        name="name"
                        placeholder="Name"
                        InputProps={{ classes: { input: classes.input } }}
                        InputLabelProps={{ shrink: false }}
                        value={values.name}
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={12} style={{ padding: "9px 0" }}>
                      <Typography variant="h5" classes={{ h5: classes.h5 }}>
                        Value:
                      </Typography>
                      <TextField
                        name="value"
                        placeholder="Value"
                        InputProps={{ classes: { input: classes.input } }}
                        InputLabelProps={{ shrink: false }}
                        value={values.value}
                        error={Boolean(touched.value && errors.value)}
                        fullWidth
                        helperText={touched.value && errors.value}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                  </Grid>

                  <Button
                    style={{ marginTop: "10px" }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={4}
                  >
                    Add New
                  </Button>
                </form>
              </CardContent>
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default NewSetting;
