import * as React from "react";

import { FormControl, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  shrink: {
    display: "none",
  },
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  paperSelect: {
    backgroundColor: "#f5f6fa",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    backgroundColor: "#dfdcdc",
  },
}));

const Chip = ({
  id,
  label,
  list,
  placeholder,
  selected,
  setSelected,
  multiple,
  disabled,
  error,
  input_label,
  required,
  helperText,
  ...other
}) => {
  const classes = useStyles();

  return (
    <div>
      <FormControl fullWidth style={{ backgroundColor: "#F9F9FC" }}>
        <Autocomplete
          required={required ? true : false}
          multiple={multiple}
          id={id ? id : "id"}
          options={list}
          getOptionLabel={(item) => (item[label] ? item[label] : "")}
          onChange={(e, value) => setSelected(value)}
          defaultValue={selected}
          disabled={disabled ?? false}
          filterSelectedOptions
          {...other}
          renderInput={(params) => (
            <TextField
              inputlabelprops={{ shrink: false }}
              InputProps={{ classes: { root: classes.input } }}
              {...params}
              variant="outlined"
              label={!input_label ? placeholder : false}
              placeholder={placeholder}
              error={error ? true : false}
              helperText={helperText ? helperText : false}
            />
          )}
        />
      </FormControl>
    </div>
  );
};

export default Chip;
