import React, { useState, useEffect } from "react";
import { MenuItem, FormControl, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  select: {
    borderRadius: "4px",
    color: "#6A74C9",
    fontWeight: "600",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  shrink: {
    display: "none",
  },
  inputSelect: {
    padding: "11.5px 14px",
  },
  paperSelect: {
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
  input: {
    padding: "8.5px 14px",
  },
  formControl: {
    minWidth: "10rem",
  },
}));
const MySelect = ({
  data,
  valueHolder,
  selected,
  list,
  label,
  value,
  defaultValue,
  defaultLabel,
  toDispatch,
  onSelect,
  setSelected,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [newSelected, setNewSelected] = useState(selected || defaultValue);
  const [selectedFound, setSelectedFound] = useState(null);

  useEffect(() => {
    if (selected) setNewSelected(selected);
    else setNewSelected(defaultValue);

    // check if the slected exist in array
    let found = list.find((item) => item[value] === selected);
    if (found) setSelectedFound(found);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleChange = (event) => {
    setNewSelected(event.target.value);
    if (!data) data = {};
    if (valueHolder) data[valueHolder] = event.target.value;
    if (toDispatch) dispatch(toDispatch(data));
    if (onSelect) onSelect(event.target.value);
    if (setSelected) setSelected(event.target.value);
  };

  return (
    <React.Fragment>
      <FormControl classes={{ root: classes.formControl }}>
        <Select
          MenuProps={{
            classes: { paper: classes.paperSelect },
            variant: "menu",
          }}
          inputlabelprops={{ shrink: false }}
          variant="outlined"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={newSelected}
          onChange={(event) => handleChange(event)}
          className={classes.select}
          classes={{ root: classes.input, select: classes.selectMenu }}
          placeholder="No choice"
        >
          {selectedFound && (
            <MenuItem key={-1} value={selectedFound[value]}>
              {selectedFound[label]}
            </MenuItem>
          )}

          {defaultLabel && defaultValue ? (
            <MenuItem key={-1} value={defaultValue}>
              {defaultLabel}
            </MenuItem>
          ) : (
            ""
          )}
          {list.map((item, index) => (
            <MenuItem key={index} value={item[value]}>
              {item[label]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};
export default MySelect;
