import React from "react";
import styled from "styled-components/macro";

import {
  Button as MuiButton,
  Divider as MuiDivider,
  Grid,
  TextField as MuiTextField,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { Trash } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";
const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const useStyles = makeStyles({
  icon: {
    width: "100%",
    color: "white",
  },
  newSetting: {
    color: "white",
    height: "100%",
    marginRight: "0.5rem",
    backgroundColor: "#23CC94",
    "&:hover": {
      backgroundColor: "#248262",
    },
  },
  deleteSetting: {
    color: "white",
    height: "100%",
    marginRight: "0.5rem",
    backgroundColor: "#da2525",
    "&:hover": {
      backgroundColor: "#248262",
    },
  },
});

const SettingsRow = ({ index, name, value, remove, handleData }) => {
  const [data, setData] = React.useState({
    name,
    value,
  });
  const classes = useStyles();

  const handleChange = (e) => {
    setData({ ...data, value: e.target.value });
    handleData(name, setValue(e.target.value));
  };

  const getValue = (value) => {
    if (Array.isArray(value)) return value.join(",");
    else if (typeof value === "object") return JSON.stringify(value);
    return value;
  };

  const setValue = (value) => {
    console.log(isJsonString(value.split(",")));
    if (value.at(-1) !== ",") {
      if (isJsonString(value.split(","))) {
        console.log(value.split(","));
        return value.split(",");
      }
    }
    return value;
  };

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={3}>
          <TextField
            name={index + "-name"}
            label="Name"
            onChange={handleChange}
            value={data.name}
            fullWidth
            variant="outlined"
            required
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item md={8}>
          <TextField
            name={index + "-value"}
            label="Value"
            onChange={handleChange}
            value={getValue(data.value)}
            fullWidth
            variant="outlined"
            required
            multiline={typeof data.value === "object"}
          />
        </Grid>

        <Grid item>
          {/* <Button
            variant="contained"
            className={classes.newSetting}
            onClick={add}
          >
            <Plus className={classes.icon} />
          </Button> */}
          <Button
            variant="contained"
            className={classes.deleteSetting}
            onClick={() => remove(data.name)}
          >
            <Trash className={classes.icon} />
          </Button>
        </Grid>
      </Grid>
      <Divider my={3} />
    </>
  );
};

export default SettingsRow;
