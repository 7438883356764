import React, { useState } from "react";
import styled, { css } from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Alert, TabContext, TabList, TabPanel } from "@material-ui/lab";
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Container,
  Divider as MuiDivider,
  Grid,
  Slide,
  Snackbar,
  Tab,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import { resetAll, updateSettings } from "../../redux/reducers/settingsReducer";
import Loading from "../customComponent/Loading";

import { rgba } from "polished";
// import ArrowRightAltOutlinedIcon from "@material/icons-material/ArrowRightAltOutlined";
import moment from "moment";

// const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Button = styled(MuiButton)(spacing);

function SettingsTypes({ types, dispatch }) {
  const [data, setData] = React.useState({});
  React.useEffect(() => {
    setData(types);
  }, [types]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // dispatch(updateSettings());
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };

  const handleCloseRequest = (e) => {};

  console.log(types);
  return (
    <Box>
      <Container maxWidth={false} sx={{ padding: 0, mt: { xs: 2, md: 0 } }}>
        {types &&
          Object.keys(types).map((key, i) => {
            return (
              <Card sx={{ p: { xs: 1.5, md: 4 }, minHeight: "500px" }} key={i}>
                <Grid style={{ marginLeft: "1rem" }}>
                  <Typography>{key}</Typography>
                </Grid>
                <Box
                  style={{ marginBottom: "1rem" }}
                  sx={{ mt: { xs: 1, md: 5 } }}
                >
                  <RequestsList
                    object={types[key]}
                    handleCloseRequest={handleCloseRequest}
                  />
                </Box>
              </Card>
            );
          })}
      </Container>
    </Box>
  );
}

const LabTabs = () => {
  const [value, setValue] = React.useState("1");
  const { setting: settings, isLoading } = useSelector(
    (state) => state.entities.settings
  );

  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sty sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab variant="h6" label="Hubstaff" value="1" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <SettingsTypes types={settings?.settings_types} dispatch={dispatch} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const Settings = () => {
  const [notification, setNotification] = React.useState(false);
  const { success, error } = useSelector((state) => state.entities.settings);
  const dispatch = useDispatch();
  const handleNotificationClose = () => {
    setNotification(false);
    dispatch(resetAll());
  };

  React.useEffect(() => {
    if (success || error) {
      setNotification(true);
    }
  }, [success, error]);

  return (
    <React.Fragment>
      {notification === true && (
        <Snackbar
          open={notification}
          autoHideDuration={5000}
          onClose={handleNotificationClose}
          TransitionComponent={TransitionLeft}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleNotificationClose}
            severity={success ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {success || error}
          </Alert>
        </Snackbar>
      )}
      <Helmet title="Settings" />

      <Typography variant="h3" gutterBottom display="inline">
        Settings
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              {/* <Typography variant="h6" gutterBottom>
                Private info
              </Typography> */}
              <LabTabs />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const RequestsList = ({ object, handleCloseRequest }) => {
  return (
    <div>
      <TableHead />
      <Grid container spacing={0} style={{ padding: "10px" }}>
        {Object.keys(object).map((key, i) => (
          <Grid item xs={12} sm={12} md={12} lg={12} key={i}>
            <RequestItem
              item={object[key]}
              keyName={key}
              onView=""
              onClose={handleCloseRequest}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const TableHead = () => {
  return (
    <Card
      sx={{
        backgroundColor: "#ffffff",
        display: { xs: "none", sm: "none", md: "block" },
      }}
    >
      <CardContent
        style={{
          padding: "10px 20px 10px 35px",
        }}
      >
        <Grid container sx={{ fontWeight: "bold" }}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Typography variant="subtitle2" sx={{ textShadow: ".2px 0 #000" }}>
              Name
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Typography variant="subtitle2" sx={{ textShadow: ".2px 0 #000" }}>
              Key
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Typography variant="subtitle2" sx={{ textShadow: ".2px 0 #000" }}>
              Type
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="subtitle2" sx={{ textShadow: ".2px 0 #000" }}>
              Description
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const Card = styled(MuiCard)`
  position: relative;
  cursor: pointer;
  border-style: solid;
  border-color: #f9f9f9;
  border-width: 1px;
  margin-bottom: 8px;
  boxshadow: "0 0 2px 0 rgb(145 158 171 / 12%), 0 16px 32px -4px rgb(145 158 171 / 12%)";
  border-width: 1px;
  background-color: #f9f9f9;

  &:hover {
    border-style: solid;
    border-color: #365ab3;
    border-width: 1px;
  }
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    border-color: rgba(54, 90, 179, 0.3);
    background-color: #fff;
    box-shadow: rgba(54, 90, 179, 0.3) 0px 1px 2px 0px,
      rgba(54, 90, 179, 0.15) 0px 2px 6px 2px !important;
  }

  ${(props) =>
    props.illustration &&
    props.theme.palette.mode !== "dark" &&
    css`
      background: ${(props) => rgba(props.theme.palette.primary.main, 0.125)};
      color: ${(props) => props.theme.palette.primary.main};
    `}
`;

const CardContent = styled(MuiCardContent)`
  position: relative;
  background-color: "#e1e0e357";

  &:last-child {
    padding-bottom: 6px;
    padding-top: 12px;
  }
`;

const RequestItem = ({ item, keyName, onClose }) => {
  const handleSelected = (item) => {};

  return (
    <Card sx={{ mt: { xs: 1.5, md: 0 } }}>
      <CardContent
        sx={{
          ".address-text": {
            color: "#0A36A3",
          },
          "&:hover": {
            xs: { background: "#0A36A3", color: "#FFF" },
            md: { background: "inherit", color: "inherit" },
          },
          "&:hover .address-text": {
            xs: { color: "#FFF" },
            md: { color: "#0A36A3" },
          },
        }}
      >
        <Grid container spacing={1}>
          <Grid
            onClick={() => handleSelected(item)}
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
          >
            <Box
              sx={{
                fontWeight: { xs: "bold", md: "normal" },
              }}
            >
              {keyName}
            </Box>
          </Grid>
          <Grid
            onClick={() => handleSelected(item)}
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
          >
            <Box
              sx={{
                fontWeight: { xs: "bold", md: "normal" },
              }}
            >
              {item.name}
            </Box>
          </Grid>

          <Grid
            onClick={() => handleSelected(item)}
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            // mt={mobileView ? 0.5 : 0}
            className="address-text"
          >
            {item.type}
          </Grid>

          <Grid
            item
            onClick={() => handleSelected(item)}
            sx={{
              display: { sm: "none", xs: "none", md: "block" },
            }}
            xs={12}
            sm={12}
            md={4}
            lg={4}
          >
            {item.description}
          </Grid>

          <Grid
            item
            sx={{
              display: { sm: "none", xs: "none", md: "block" },
            }}
          >
            <Button
              variant="outlined"
              style={{ right: "0" }}
              sx={{
                marginRight: "5px",
                width: "140px",
                height: "30px",
                "&:hover": { backgroundColor: "#0A36A3", color: "white" },
              }}
              onClick={() => handleSelected(item)}
            >
              View
            </Button>
            {/* <Button
              variant="outlined"
              sx={{
                marginRight: "5px",
                width: "140px",
                height: "30px",
                "&:hover": { backgroundColor: "#0A36A3", color: "white" },
              }}
              onClick={() => handleSelected(item)}
            >
              View
            </Button> */}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Settings;
