import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";

import { login } from "../../redux/reducers/authReducer";

import { Visibility, VisibilityOff } from "@material-ui/icons";
import { updateToken } from "../../helpers/functions";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 10px;
`;

const SignIn = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // Add these variables to your component to track the state
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  let { data, error, success, isLoading } = useSelector(
    (state) => state.entities.auth
  );

  useEffect(() => {
    if (success && data.user !== null && data.token) {
      //save the token in Storage
      updateToken(data.token); // if is admin send to dashboard else send to submits
      if (data.user.membership_role === "user") {
        history.push("/members-area");
      } else {
        history.push("/dashboard");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, error]);

  const handleMySubmit = (values) => {
    dispatch(login({ email: values.email, password: values.password }));
  };

  return (
    <Wrapper>
      <Helmet title="Sign In" />
      <div
        style={{
          height: "40px",
          textAlign: "center",
          margin: "0 auto 4px",
        }}
      >
        <img
          style={{
            height: "120px",
            textAlign: "center",
            margin: "0 auto 4px",
          }}
          src="/static/img/logo/admin_dashboard_black.svg"
          alt="Logo"
        />
      </div>

      <Typography
        component="h2"
        variant="body1"
        align="center"
        style={{ marginBottom: "1rem", marginTop: "1rem" }}
      >
        Sign in to your Account
      </Typography>

      <Formik
        initialValues={{
          email: "",
          password: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={handleMySubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {error && (
              <Alert mt={2} mb={1} severity="warning">
                {error}
              </Alert>
            )}
            <TextField
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <TextField
              type={showPassword ? "text" : "password"}
              name="password"
              label="Password"
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
              InputProps={{
                //    classes: { input: classes.input },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ width: "5px", height: "5px" }}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <Visibility
                          style={{ width: "1.2rem", height: "1.2rem" }}
                        />
                      ) : (
                        <VisibilityOff
                          style={{ width: "1.2rem", height: "1.2rem" }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLoading}
              style={{
                marginTop: "2rem",
                background: "linear-gradient(to right, #673ab7, #512da8)",
              }}
            >
              {isLoading ? "Loading..." : "Login"}
            </Button>
            <Button
              component={Link}
              to="/auth/forgot-password"
              fullWidth
              color="primary"
            >
              Forgot Password
            </Button>
          </form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default SignIn;
