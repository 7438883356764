import React from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  CardContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import { updateUserPassword } from "../../redux/reducers/usersReducer";

const Alert = styled(MuiAlert)(spacing);

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#6320EE",
    marginRight: "10px",
    marginLeft: "5px",
  },

  dialogWrapper: {
    position: "absolute",
    minWidth: "90rem",
  },
  dialogTitle: {
    paddingRight: "0px",
    float: "right",
  },
  startICon: {
    margin: 0,
  },
  chips: {
    height: "20px",
    borderRadius: "0px",
  },
  label: {
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  rootButton: {
    minWidth: "39px",
    marginTop: "-2px",
    marginRight: "11px",
  },
  ButtonApprove: {
    backgroundColor: "#23CC94",
    color: "white",
    width: "6rem",
    margin: "12px 39px",
  },
  description: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    marginTop: "10px",
  },
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  // eslint-disable-next-line no-dupe-keys
  label: {
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "5px",
    marginTop: "10px",
  },
  dialogContent: {
    padding: "0px 24px",
  },
  controlInput: {
    backgroundColor: "#F9F9FC",
  },
  select: {
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    padding: "4px 12px ",
  },
  shrink: {
    display: "none",
  },
  inputSelect: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  paperSelect: {
    backgroundColor: "#f5f6fa",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
}));

const UpdatePassword = ({ open, setOpen, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { error, success, isLoading, selected_user } = useSelector(
    (state) => state.entities.users
  );
  React.useEffect(() => {
    if (success) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const initialValues = {
    password: "",
    repeatPassword: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required()
      .min(8, "Must be at least 8 characters")
      .max(255),
    repeatPassword: Yup.string().when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
  });

  const handleSubmit = async (values) => {
    const data = {
      _id: selected_user.user._id,
      password: values.password,
    };
    dispatch(updateUserPassword(data));
  };

  return (
    <Dialog
      maxWidth="md"
      classes={{ paper: classes.dialogwrapper }}
      open={open}
      onClose={handleClose ? handleClose : null}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h6" gutterBottom>
          Update Password
        </Typography>
        <div style={{ display: "flex", float: "right" }}>
          <Button
            style={{ color: "#868695", marginTop: "-40px" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent
        style={{ marginTop: "-18px" }}
        classes={{ root: classes.dialogContent }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <>
              <CardContent>
                {error && (
                  <Alert severity="danger" my={3}>
                    {error}
                  </Alert>
                )}

                <form onSubmit={handleSubmit}>
                  <Grid container spacing={6}>
                    <Grid item md={12} style={{ padding: "9px" }}>
                      <Typography variant="h5" classes={{ h5: classes.label }}>
                        Password*:
                      </Typography>
                      <TextField
                        placeholder="Password"
                        name="password"
                        value={values.password}
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={12} style={{ padding: "9px" }}>
                      <Typography variant="h5" classes={{ h5: classes.label }}>
                        Repeat Password*:
                      </Typography>
                      <TextField
                        placeholder="Repeat Password"
                        name="repeatPassword"
                        value={values.repeatPassword}
                        error={Boolean(
                          touched.repeatPassword && errors.repeatPassword
                        )}
                        fullWidth
                        helperText={
                          touched.repeatPassword && errors.repeatPassword
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                  </Grid>

                  <div style={{ margin: "20px 0" }}>
                    <Button
                      style={{
                        width: "10rem",
                        backgroundColor: isLoading ? "#6A74C9" : "#6320EE",
                      }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isLoading}
                      mt={3}
                    >
                      {!isLoading ? "Update Password" : "Updating..."}
                    </Button>
                  </div>
                </form>
              </CardContent>
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default UpdatePassword;
