import React from "react";
import { useDispatch } from "react-redux";
import { Snackbar, Slide } from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const NotificationAlerts = ({ error, success, reset }) => {
  const [notification, setNotification] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (success || error) {
      setNotification(true);
    }
  }, [success, error]);

  const handleNotificationClose = () => {
    setNotification(false);
    dispatch(reset());
  };

  return (
    <Snackbar
      open={notification}
      autoHideDuration={5000}
      onClose={handleNotificationClose}
      TransitionComponent={TransitionLeft}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <Alert
        onClose={handleNotificationClose}
        severity={success ? "success" : "error"}
        sx={{ width: "100%" }}
      >
        {success || error}
      </Alert>
    </Snackbar>
  );
};

export default NotificationAlerts;
