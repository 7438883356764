import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";

import {
  Box,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  FormControl,
  Button,
} from "@material-ui/core";

import { Add as AddIcon, Delete as DeleteIcon } from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import MySelect from "../customComponent/MySelect";
import {
  deleteGlobalWord,
  getGlobalWords,
  resetAll,
} from "../../redux/reducers/globalWordReducer";
import { getNumberPerPage, updateNbrPerPage } from "../../helpers/functions";
import Loading from "../customComponent/Loading";
// import CompanyPopUp from "./CompanyPopUp";
import { Reload } from "../customComponent/Reload";
import { red } from "@material-ui/core/colors";
import moment from "moment";
import { Calendar } from "react-feather";
import ArchiveDialog from "../customComponent/AlertDialog";
import NotificationAlerts from "../../components/NotificationAlerts";
import GlobalWordPopUp from "./GlobalWordPopUp";
import {
  GLOBAL_WORD_LABEL_TYPE,
  LEADS_ACTIONS,
  ROWS_PER_PAGE_OPTIONS,
} from "../../constants";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Customer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "label", alignment: "left", label: "Label" },
  { id: "keyword", alignment: "left", label: "Keyword" },
  { id: "action", alignment: "left", label: "Action" },
  { id: "createdAt", alignment: "left", label: "Created At" },
  { id: "actions", alignment: "right", label: "Actions" },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell mr={2}>Status</TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTable = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("customer");
  const [openDialog, setOpenDialog] = useState(false);
  const [globalWordToDelete, setGlobalWordToDelete] = useState(null);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const rowsPerPage = getNumberPerPage();
  const {
    list,
    search,
    selected_label,
    selected_action,
    total_records,
    maxPage,
  } = useSelector((state) => state.entities.globalWords);

  useEffect(() => {
    if (maxPage === 0) setPage(0);
  }, [maxPage]);

  const handleChangePage = (event, newPage) => {
    let data = {
      search,
      label: selected_label,
      action: selected_action,
      page: newPage + 1,
    };
    if (newPage > maxPage - 1) {
      dispatch(getGlobalWords(data));
    }
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      return;
    }
  };

  const handleChangeRowsPerPage = (event) => {
    let per_page = event.target.value;
    let data = {
      search,
      label: selected_label,
      action: selected_action,
      per_page,
    };

    updateNbrPerPage(per_page);
    setPage(0);
    dispatch(getGlobalWords(data));
  };

  const emptyRows = rowsPerPage - Math.min(list, 10 - page * rowsPerPage);

  return (
    <div>
      {globalWordToDelete && (
        <ArchiveDialog
          title="Delete Global Word"
          description="This will remove the keyword from the database and the leads wont be filtered using this word anymore, Are you sure?"
          open={openDialog}
          data={globalWordToDelete}
          setOpen={setOpenDialog}
          dispatchOnAgree={deleteGlobalWord}
          // onYes={setGlobalWordToDelete(false)}
        />
      )}

      <Paper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={2}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={list.length}
            />
            <TableBody>
              {stableSort(list, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell align="left">
                        <Customer>{row.label}</Customer>
                      </TableCell>
                      <TableCell align="left">
                        <Customer>{row.keyword}</Customer>
                      </TableCell>
                      <TableCell align="left">
                        <Customer>{row.action}</Customer>
                      </TableCell>
                      <TableCell align="left">
                        <Calendar
                          size={13}
                          style={{
                            marginRight: "3px",
                            marginBottom: "-1px",
                            color: red[800],
                          }}
                        />
                        {moment(row.createdAt).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell align="right">
                        {/* <Tooltip title="Edit">
                          <IconButton
                            aria-label="edit"
                            style={{ color: blue[500] }}
                            onClick={() => {
                              console.log(row);
                              setSelectedGlobalWord(row);
                              setOpen(true);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip> */}

                        <Tooltip title="Delete">
                          <IconButton
                            style={{ color: red[500] }}
                            aria-label="Delete"
                            onClick={() => {
                              setGlobalWordToDelete(row._id);
                              setOpenDialog(true);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={total_records}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

const LeadsSettings = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [selected_label, setSelectedLabel] = React.useState(null);
  const [selected_action, setSelectedAction] = React.useState(null);
  const { success, error, isLoading, search, list } = useSelector(
    (state) => state.entities.globalWords
  );

  useEffect(() => {
    loadGlobalWord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, selected_label, selected_action]);
  // }, []);

  const loadGlobalWord = () => {
    let data = {
      search,
      label: selected_label,
      action: selected_action,
    };
    dispatch(getGlobalWords(data));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <NotificationAlerts error={error} success={success} reset={resetAll} />
      <Helmet title="Global Black Listed" />
      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Global Black Listed
          </Typography>
        </Grid>
        <Grid item>
          <Box style={{ display: "flex" }}>
            <Reload loading={isLoading} action={loadGlobalWord} />
            <FormControl style={{ minWidth: "10rem", marginRight: "1rem" }}>
              <MySelect
                selected={selected_action}
                defaultLabel="All Actions"
                defaultValue="all"
                list={LEADS_ACTIONS}
                label="name"
                value="value"
                setSelected={setSelectedAction}
              />
            </FormControl>
            <FormControl style={{ minWidth: "10rem", marginRight: "1rem" }}>
              <MySelect
                selected={selected_label}
                defaultLabel="All Labels"
                defaultValue="all"
                list={GLOBAL_WORD_LABEL_TYPE}
                label="name"
                value="value"
                setSelected={setSelectedLabel}
              />
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
            >
              <AddIcon />
              Add Black Item
            </Button>
            {open && (
              <GlobalWordPopUp
                open={open}
                setOpen={setOpen}
                handleClose={handleClose}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {!list.length && isLoading ? <Loading /> : <EnhancedTable />}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default LeadsSettings;
