import React, { useState } from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import * as Yup from "yup";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  CardContent,
  TextField,
  Grid,
  FormControl,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { addCompany, setError } from "../../redux/reducers/companiesReducer";
import Chip from "../customComponent/Chips";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({}));

const CompanyPopUp = ({ open, setOpen, handleClose, company }) => {
  const dispatch = useDispatch();
  const [brand, setBrand] = useState([]);
  const [disable, setDisable] = useState(false);
  const history = useHistory();
  const initialValues = {
    company_name: "",
    domain: "",
    brand: brand,
  };
  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required("Brand Domain is required.."),
    domain: Yup.string().required("Brand name is required"),
  });

  const { success, error, list_all: companies } = useSelector(
    (state) => state.entities.companies
  );

  const { list_all } = useSelector((state) => state.entities.brands);

  React.useEffect(() => {
    if (success) {
      let newCompany = companies[companies.length - 1];
      history.push("/companies/" + newCompany._id);
    } else if (error) {
      setDisable(false);
      dispatch(setError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, error]);

  React.useEffect(() => {
    if (company) {
      // in update
      setBrand(company.brand);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  const handleSubmit = (values) => {
    if (brand._id) {
      dispatch(addCompany({ ...values, brand: brand._id }));
      console.log(brand);
      setDisable(true);
    } else {
      dispatch(setError("Brand is Required"));
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogwrapper }}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography
          style={{ fontSize: "15px", fontWeight: "bold" }}
          gutterBottom
        >
          Add New Company
        </Typography>
        <div style={{ display: "flex", float: "right" }}>
          <Button
            style={{ color: "#868695", marginTop: "-40px" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent
        style={{ marginTop: "-18px" }}
        classes={{ root: classes.dialogContent }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            status,
          }) => (
            <>
              <CardContent>
                <Typography variant="body2" gutterBottom>
                  Create a new Company then configure its settings to meet your
                  needs.
                </Typography>

                <form onSubmit={handleSubmit}>
                  <Grid container>
                    <Grid item md={12} style={{ padding: "9px 0" }}>
                      <Typography variant="h5" classes={{ h5: classes.h5 }}>
                        Company Name:
                      </Typography>
                      <TextField
                        name="company_name"
                        placeholder="Company Name"
                        InputProps={{ classes: { input: classes.input } }}
                        inputlabelprops={{ shrink: false }}
                        value={values.company_name}
                        error={Boolean(
                          touched.company_name && errors.company_name
                        )}
                        fullWidth
                        helperText={touched.company_name && errors.company_name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={12} style={{ padding: "9px 0" }}>
                      <Typography variant="h5" classes={{ h5: classes.h5 }}>
                        Domain:
                      </Typography>
                      <TextField
                        name="domain"
                        placeholder="Domain"
                        InputProps={{ classes: { input: classes.input } }}
                        inputlabelprops={{ shrink: false }}
                        value={values.domain}
                        error={Boolean(touched.domain && errors.domain)}
                        fullWidth
                        helperText={touched.domain && errors.domain}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={12} style={{ padding: "9px 0px" }}>
                      <Typography variant="h5" classes={{ h5: classes.h5 }}>
                        Brand:
                      </Typography>
                      <FormControl fullWidth>
                        <Chip
                          list={list_all}
                          selected={values.brand}
                          placeholder={"Brands"}
                          label={"name"}
                          setSelected={setBrand}
                          multiple={false}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Button
                    style={{ marginTop: "10px" }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={4}
                    disabled={disable}
                  >
                    Add New
                  </Button>
                </form>
              </CardContent>
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default CompanyPopUp;
