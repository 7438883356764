import { createSlice } from "@reduxjs/toolkit";
import { getNumberPerPage } from "../../helpers/functions";
import { apiCallBegan } from "../actions/apiActions";

const url = "integrations";

const integrationSlice = createSlice({
  name: "integrations",
  initialState: {
    list: {},
    list_all: [],
    selectedIntegration: null,
    selectedProject: null,
    data: null,
    isLoading: false,
    error: null,
    page: 0,
    per_page: 0,
    total_pages: 0,
    maxPage: 0,
    total_records: 0,
    isRequestSent: null,
  },
  reducers: {
    submittedSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    integrationsLoaded: (state, action) => {
      state.list = state.list.concat(action.payload.result);
      state.isLoading = false;
      state.maxPage = state.page > state.maxPage ? state.page : state.maxPage;
    },
    integrationsReLoaded: (state, action) => {
      state.list = action.payload;
      state.isLoading = false;
      state.maxPage = 0;
    },
    allIntegrationsLoaded: (state, action) => {
      state.isLoading = false;
      state.list_all = action.payload;
    },

    loadSelectedIntegration: (state, action) => {
      state.selectedIntegration.unshift(action.payload);
      state.integration = action.payload;
      state.isLoading = false;
    },

    requestStarted: (state, action) => {
      state.isLoading = true;
      state.error = null;
      state.isRequestSent = true;
    },

    integrationUpdated: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },

    requestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    noteAdded: (state, action) => {
      let submit_id = action.payload._id;
      const index = state.list.findIndex((submit) => submit._id === submit_id);
      state.list[index].notes.unshift(action.payload.note);
      state.isLoading = false;
    },
    setCompanyHasContactToTrue: (state, action) => {
      let submit_id = action.payload._id;
      const index = state.list.findIndex((submit) => submit._id === submit_id);
      state.list[index].company.hasContact = true;
    },
  },
});

export default integrationSlice.reducer;
export const {
  integrationsLoaded,
  integrationAdded,
  integrationUpdated,
  requestStarted,
  requestFailed,
  loadSelectedIntegration,
  integrationsReLoaded,
  noteAdded,
  allIntegrationsLoaded,
} = integrationSlice.actions;

export const getAllIntegrations = () =>
  apiCallBegan({
    url: url + "/list",
    method: "GET",
    onStart: requestStarted.type,
    onSuccess: allIntegrationsLoaded.type,
    onError: requestFailed.type,
  });

export const getIntegrations = (params = {}) => {
  params.per_page = getNumberPerPage();
  if (params.project === null || params.project === "all")
    delete params.project;
  if (params.user === null || params.user === "all") delete params.user;
  if (params.type === null || params.type === "all") delete params.type;
  if (params.group === null || params.group === "all") delete params.group;

  if (params.startDate === null) {
    delete params.date;
  } else {
    if (params.date && params.date.startDate)
      params.startDate = params.date.startDate;
    if (params.date && params.date.endDate)
      params.endDate = params.date.endDate;

    delete params.date;
  }

  //Generate a URL query based on the object we got
  let query = new URLSearchParams(params);

  // Reload the data if no page is provided
  let resultsReceived = params.page
    ? integrationsLoaded.type
    : integrationsReLoaded.type;

  return apiCallBegan({
    url: `${url}/all?${query}`,
    method: "GET",
    onStart: requestStarted.type,
    onSuccess: resultsReceived,
    onError: requestFailed.type,
  });
};

export const getIntegration = (id) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: "GET",
    onStart: requestStarted.type,
    onSuccess: loadSelectedIntegration.type,
    onError: requestFailed.type,
  });

export const updateIntegration = (data) =>
  apiCallBegan({
    url,
    method: "PUT",
    data,
    onStart: requestStarted.type,
    onSuccess: integrationUpdated.type,
    onError: requestFailed.type,
  });

export const addIntegration = (data) =>
  apiCallBegan({
    url,
    method: "POST",
    data,
    onStart: requestStarted.type,
    onSuccess: integrationAdded.type,
    onError: requestFailed.type,
  });
