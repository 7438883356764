import React, { useState } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  CardContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import Chip from "../customComponent/Chips";
import { useHistory } from "react-router-dom";
import { addUser } from "../../redux/reducers/usersReducer";

const Alert = styled(MuiAlert)(spacing);

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#6320EE",
    marginRight: "10px",
    marginLeft: "5px",
  },

  dialogWrapper: {
    position: "absolute",
    width: "90rem",
  },
  dialogTitle: {
    paddingRight: "0px",
    float: "right",
  },
  startICon: {
    margin: 0,
  },
  chips: {
    height: "20px",
    borderRadius: "0px",
  },
  label: {
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  rootButton: {
    minWidth: "39px",
    marginTop: "-2px",
    marginRight: "11px",
  },
  ButtonApprove: {
    backgroundColor: "#23CC94",
    color: "white",
    width: "6rem",
    margin: "12px 39px",
  },
  description: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    marginTop: "10px",
  },
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  // eslint-disable-next-line no-dupe-keys
  label: {
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "5px",
    marginTop: "10px",
  },
  dialogContent: {
    padding: "0px 24px",
  },
  controlInput: {
    backgroundColor: "#F9F9FC",
  },
  select: {
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    padding: "4px 12px ",
  },
  shrink: {
    display: "none",
  },
  inputSelect: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  paperSelect: {
    backgroundColor: "#f5f6fa",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
}));

const UserPopUp = ({ open, setOpen, handleClose }) => {
  const types = [{ name: "Admin" }, { name: "Manager" }, { name: "User" }];

  const [selectedType, setSelectedType] = useState(types[2]);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { error, success, isLoading, selected_user } = useSelector(
    (state) => state.entities.users
  );
  React.useEffect(() => {
    if (success) {
      history.push("/users/" + selected_user.user._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
    repeatPassword: "",
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    phone_number: Yup.string(),
    password: Yup.string().min(8, "Must be at least 8 characters").max(255),
    repeatPassword: Yup.string().when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
  });

  const handleSubmit = async (values) => {
    const data = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone_number: values.phone_number,
      password: values.password,
      type: selectedType ? selectedType.name.toLowerCase() : types[2],
    };
    dispatch(addUser(data));
  };

  return (
    <Dialog
      maxWidth="md"
      classes={{ paper: classes.dialogwrapper }}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h6" gutterBottom>
          Add New User
        </Typography>
        <div style={{ display: "flex", float: "right" }}>
          <Button
            style={{ color: "#868695", marginTop: "-40px" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent
        style={{ marginTop: "-18px" }}
        classes={{ root: classes.dialogContent }}
      >
        <Typography variant="body2" gutterBottom>
          Create a new User then configure its settings.
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <>
              <CardContent>
                {error && (
                  <Alert severity="danger" my={3}>
                    {error}
                  </Alert>
                )}

                <form onSubmit={handleSubmit}>
                  <Grid container spacing={4}>
                    <Grid item md={6}>
                      <Typography variant="h5" classes={{ h5: classes.label }}>
                        First Name*:
                      </Typography>
                      <TextField
                        placeholder="First Name"
                        name="first_name"
                        value={values.first_name}
                        error={Boolean(touched.first_name && errors.password)}
                        fullWidth
                        helperText={touched.first_name && errors.first_name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={6} style={{ padding: "9px" }}>
                      <Typography variant="h5" classes={{ h5: classes.label }}>
                        Last Name*:
                      </Typography>
                      <TextField
                        placeholder="Last Name"
                        name="last_name"
                        value={values.last_name}
                        error={Boolean(touched.last_name && errors.last_name)}
                        fullWidth
                        helperText={touched.last_name && errors.last_name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={6}>
                    <Grid item md={6}>
                      <Typography variant="h5" classes={{ h5: classes.label }}>
                        Email*:
                      </Typography>
                      <TextField
                        placeholder="Email"
                        name="email"
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Typography variant="h5" classes={{ h5: classes.label }}>
                        Phone Number:
                      </Typography>
                      <TextField
                        placeholder="Phone Number"
                        name="phone_number"
                        value={values.phone_number}
                        error={Boolean(
                          touched.phone_number && errors.phone_number
                        )}
                        fullWidth
                        helperText={touched.phone_number && errors.phone_number}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Typography variant="h5" classes={{ h5: classes.label }}>
                        User Type:
                      </Typography>
                      {types ? (
                        <Chip
                          list={types}
                          selected={types[2]}
                          input_label={true}
                          placeholder={"User Type"}
                          label="name"
                          setSelected={setSelectedType}
                        />
                      ) : (
                        ""
                      )}
                    </Grid>

                    <Grid item md={6} style={{ padding: "9px" }}>
                      <Typography variant="h5" classes={{ h5: classes.label }}>
                        Password*:
                      </Typography>
                      <TextField
                        placeholder="Password"
                        name="password"
                        value={values.password}
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={6} style={{ padding: "9px" }}>
                      <Typography variant="h5" classes={{ h5: classes.label }}>
                        Repeat Password*:
                      </Typography>
                      <TextField
                        placeholder="Repeat Password"
                        name="repeatPassword"
                        value={values.repeatPassword}
                        error={Boolean(
                          touched.repeatPassword && errors.repeatPassword
                        )}
                        fullWidth
                        helperText={
                          touched.repeatPassword && errors.repeatPassword
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                  </Grid>

                  <div style={{ margin: "40px 0" }}>
                    <Button
                      style={{
                        width: "10rem",
                        backgroundColor: isLoading ? "#6A74C9" : "#6320EE",
                      }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isLoading}
                      mt={3}
                    >
                      {!isLoading ? "Add New" : "Loading..."}
                    </Button>
                  </div>
                </form>
              </CardContent>
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default UserPopUp;
