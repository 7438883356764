import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  loading: {
    //   marginTop: "50%",
    // marginLeft: "47rem",
    //  paddingBottom: "50%",
  },
}));

const CircularIndeterminate = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress />
    </Box>
  );
};

const Loading = () => {
  const classes = useStyles();
  return (
    <div
      className={classes.loading}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "20rem",
      }}
    >
      <CircularIndeterminate
        disableShrink
        size={40}
        classes={{ root: classes.spiner }}
      />
      <span
        style={{
          justifyContent: "center",
          position: "fixed",
          marginTop: "5rem",
        }}
      >
        Loading...please wait
      </span>
    </div>
  );
};

export default Loading;
