/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

import {
  Button as MuiButton,
  Divider as MuiDivider,
  Grid,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { Save } from "react-feather";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "../customComponent/Chips";
import { useSelector } from "react-redux";
const Divider = styled(MuiDivider)(spacing);

const Button = styled(MuiButton)(spacing);

const useStyles = makeStyles({
  icon: {
    width: "100%",
    color: "white",
  },
  newSetting: {
    color: "white",
    height: "100%",
    marginRight: "0.5rem",
    backgroundColor: "#23CC94",
    "&:hover": {
      backgroundColor: "#248262",
    },
  },
  deleteSetting: {
    color: "white",
    height: "100%",
    marginRight: "0.5rem",
    backgroundColor: "#da2525",
    "&:hover": {
      backgroundColor: "#248262",
    },
  },
});

const AssignCompanyToUser = ({ index, name, value, add, handleData }) => {
  const user_roles = [{ name: "Owner" }, { name: "Admin" }, { name: "User" }];
  const [selectedRole, setSelectedRole] = useState();
  const [selectedCompany, setSelectedCompany] = useState();
  const [companiesList, setCompaniesList] = useState(null);

  const { list_all } = useSelector((state) => state.entities.companies);
  const { selected_user, isLoading } = useSelector(
    (state) => state.entities.users
  );

  useEffect(() => {
    if (selected_user) {
      const list = list_all.filter(
        (elem) =>
          !selected_user.companies.find(({ company }) => elem._id === company)
      );

      setCompaniesList(list);
    }
  }, [selected_user]);

  const classes = useStyles();

  const handleAdd = () => {
    add({
      _id: selectedCompany._id,
      role: selectedRole.name.toLowerCase(),
    });
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={4}>
          {companiesList && (
            <Chip
              id={index + "-company"}
              list={companiesList}
              selected={false}
              input_label={true}
              placeholder={"Company"}
              label="company_name"
              setSelected={setSelectedCompany}
            />
          )}
        </Grid>
        <Grid item md={7}>
          <Chip
            id={index + "-role"}
            list={user_roles}
            input_label={true}
            placeholder={"User Role"}
            label="name"
            setSelected={setSelectedRole}
          />
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            className={classes.newSetting}
            onClick={() => handleAdd()}
          >
            {isLoading ? "..." : <Save className={classes.icon} />}
          </Button>
        </Grid>
      </Grid>
      <Divider my={3} />
    </>
  );
};

export default AssignCompanyToUser;
