import React from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import {
  updateUser,
  resetAll,
  getUsers,
  setSelectedUser,
  resetLists,
  deleteUser,
} from "../../redux/reducers/usersReducer";

import {
  Box,
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import UserPopUp from "./UserPopUp";

import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import Loading from "../customComponent/Loading";
import { Reload } from "../customComponent/Reload";
import NotificationAlerts from "../../components/NotificationAlerts";
import { getNumberPerPage, updateNbrPerPage } from "../../helpers/functions";
import moment from "moment";
import { useHistory } from "react-router-dom";
import ArchiveDialog from "../customComponent/AlertDialog";
import { blue, red } from "@material-ui/core/colors";
import { Calendar, Mail } from "react-feather";
import { ROWS_PER_PAGE_OPTIONS } from "../../constants";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Customer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "name", alignment: "left", label: "Name" },
  { id: "created", alignment: "left", label: "Created" },
  { id: "email", alignment: "left", label: "Email" },
  { id: "total_companies", alignment: "center", label: "Companies" },
  { id: "actions", alignment: "right", label: "Actions" },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell mr={2}>Status</TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTable() {
  // check if the link has managers or admins

  const [openDialog, setOpenDialog] = React.useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const dispatch = useDispatch();
  const rowsPerPage = getNumberPerPage();
  const history = useHistory();
  const [userToDelete, setUserToDelete] = React.useState();

  // this needed for edit dialog
  // const [editDialogOpen, setEditDialogOpen] = React.useState(false);

  const { list, search, total_records, maxPage } = useSelector(
    (state) => state.entities.users
  );

  React.useEffect(() => {
    if (maxPage === 0) setPage(0);
  }, [maxPage]);

  const handleChangePage = (event, newPage) => {
    let data = {
      search,
      page: newPage + 1,
    };

    if (newPage > maxPage - 1) {
      dispatch(getUsers(data));
    }
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectees = list.map((n) => n.id);
      setSelected(newSelectees);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    let per_page = event.target.value;
    let data = {
      search,
      per_page,
    };

    updateNbrPerPage(per_page);
    setPage(0);
    dispatch(getUsers(data));
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = rowsPerPage - Math.min(list, 10 - page * rowsPerPage);

  const handleUserStatus = (company_id, status) => {
    dispatch(updateUser({ _id: company_id, status }));
  };
  const handleDeleteUser = (id) => {
    setUserToDelete(id);
    setOpenDialog(true);
  };

  const afterDelete = () => {
    setUserToDelete(null);
  };

  const goToOneUser = (user) => {
    dispatch(setSelectedUser({ user, companies: user.companies }));
    history.push("/users/" + user._id);
  };

  return (
    <>
      {userToDelete && (
        <ArchiveDialog
          title="Delete User"
          description="This will remove the user from all the companies he has access to. Are you sure you wanna delete this user?"
          open={openDialog}
          data={{ _id: userToDelete }}
          setOpen={setOpenDialog}
          dispatchOnAgree={deleteUser}
          onYes={afterDelete}
        />
      )}
      <Paper>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={list.length}
            />
            <TableBody>
              {stableSort(list, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row._id}-${index}`}
                      selected={isItemSelected}
                    >
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={row.status}
                              onChange={(event) =>
                                handleUserStatus(row._id, !row.status)
                              }
                            />
                          }
                        />
                      </TableCell>

                      <TableCell align="left">
                        <Customer onClick={() => goToOneUser(row)}>
                          {row.first_name} {row.last_name}
                        </Customer>
                      </TableCell>

                      <TableCell align="left">
                        <Calendar
                          size={13}
                          style={{
                            marginRight: "3px",
                            marginBottom: "-1px",
                            color: red[800],
                          }}
                        />
                        {moment(row.createdAt).format("DD/MM/YYYY")}
                      </TableCell>

                      <TableCell align="left">
                        <Mail
                          size={13}
                          style={{
                            marginRight: "3px",
                            marginBottom: "-2px",
                            color: blue[800],
                          }}
                        />
                        {row.email}
                      </TableCell>

                      <TableCell align="center">{row.totalCompanies}</TableCell>

                      <TableCell align="right">
                        <Tooltip title="Edit">
                          <IconButton
                            aria-label="edit"
                            style={{ color: blue[500] }}
                            onClick={() => goToOneUser(row)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            style={{ color: red[500] }}
                            aria-label="delete"
                            onClick={() => handleDeleteUser(row._id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={total_records}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}

function UsersList() {
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState(null);
  const [pageTitle, setPageTitle] = React.useState("Users");
  const { error, success, list, isLoading, isRequestSent, page } = useSelector(
    (state) => state.entities.users
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  React.useEffect(() => {
    const currentURL = window.location.href;

    dispatch(resetLists());

    if (currentURL.includes("users/managers")) {
      setPageTitle("Account Managers");
      setType("manager");
    } else if (currentURL.includes("users/admins")) {
      setPageTitle("Admins");
      setType("admin");
    } else {
      setPageTitle("Users");
      setType("user");
    }

    if (!isRequestSent) loadUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadUsers = () => {
    if (type) {
      let data = { ...page, type };
      dispatch(getUsers(data));
    }
  };

  return (
    <React.Fragment>
      <NotificationAlerts error={error} success={success} reset={resetAll} />
      <Helmet title={pageTitle} />

      <Grid justify="space-between" container>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {pageTitle}
          </Typography>
        </Grid>
        <Grid item>
          <Box display="flex" align="center">
            <Reload loading={isLoading} action={loadUsers} />

            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
            >
              <AddIcon />
              Add New
            </Button>
            {open && (
              <UserPopUp
                open={open}
                setOpen={setOpen}
                handleClose={handleClose}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {!list.length && isLoading ? <Loading /> : <EnhancedTable />}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UsersList;
