import React, { useState } from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import * as Yup from "yup";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  CardContent,
  TextField,
  Grid,
  FormControl,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import Chip from "../customComponent/Chips";
import { Formik } from "formik";
import { addGlobalWord } from "../../redux/reducers/globalWordReducer";
import { GLOBAL_WORD_LABEL_TYPE, LEADS_TYPES } from "../../constants";

const useStyles = makeStyles((theme) => ({}));

const GlobalWordPopUp = ({ open, setOpen, handleClose, globalWord }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [action, setAction] = useState(LEADS_TYPES[0]);
  const [label, setLabel] = useState(GLOBAL_WORD_LABEL_TYPE[0]);
  // eslint-disable-next-line no-unused-vars
  const [disable, setDisable] = useState(false);
  const initialValues = {
    keyword: "",
  };
  const validationSchema = Yup.object().shape({
    keyword: Yup.string().required("keyword is required"),
  });

  const handleSubmit = (values) => {
    const data = {
      label: label.value,
      keyword: values.keyword,
      action: action.value,
    };
    dispatch(addGlobalWord(data));
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogwrapper }}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography
          style={{ fontSize: "15px", fontWeight: "bold" }}
          gutterBottom
        >
          Add New Global Word
        </Typography>
        <div style={{ display: "flex", float: "right" }}>
          <Button
            style={{ color: "#868695", marginTop: "-40px" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent
        style={{ marginTop: "-18px" }}
        classes={{ root: classes.dialogContent }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            status,
          }) => (
            <>
              <CardContent>
                <Typography variant="body2" gutterBottom>
                  Create a new Global Word
                </Typography>

                <form onSubmit={handleSubmit}>
                  <Grid container>
                    <Grid item md={12} style={{ padding: "9px 0" }}>
                      <Typography variant="h5" classes={{ h5: classes.h5 }}>
                        Action:
                      </Typography>
                      <FormControl fullWidth>
                        <Chip
                          list={LEADS_TYPES}
                          selected={LEADS_TYPES[0]}
                          label={"name"}
                          setSelected={setAction}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={12} style={{ padding: "9px 0" }}>
                      <Typography variant="h5" classes={{ h5: classes.h5 }}>
                        Keyword:
                      </Typography>
                      <TextField
                        name="keyword"
                        placeholder="Keyword"
                        InputProps={{ classes: { input: classes.input } }}
                        inputlabelprops={{ shrink: false }}
                        value={values.domain}
                        error={Boolean(touched.keyword && errors.keyword)}
                        fullWidth
                        helperText={touched.keyword && errors.keyword}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={12} style={{ padding: "9px 0px" }}>
                      <Typography variant="h5" classes={{ h5: classes.h5 }}>
                        Label:
                      </Typography>
                      <FormControl fullWidth>
                        <Chip
                          list={GLOBAL_WORD_LABEL_TYPE}
                          selected={label}
                          label={"name"}
                          setSelected={setLabel}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Button
                    style={{ marginTop: "10px" }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={4}
                    disabled={disable}
                  >
                    Add New
                  </Button>
                </form>
              </CardContent>
            </>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default GlobalWordPopUp;
