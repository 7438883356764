export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

export const USER_TOKEN = "spider_forms_token";

export const SETTINGS = [
  "clicky_data",
  "nbr_devices_allowed",
  "mail_api_key",
  "settings_types",
];

export const getMessageWithVariable = (message, data) => {
  message = message.replaceAll("[URL]", data.profile.url || "");
  message = message.replaceAll("[NUMBER]", data.profile.phone || "");
  message = message.replaceAll("[PHONE]", data.profile.phone || "");
  message = message.replaceAll("[EMAIL]", data.profile.email || "");
  message = message.replaceAll("[NAME]", data.profile.sender || "");
  message = message.replaceAll(
    "[COMPANY_NAME]",
    data.profile.company_name || ""
  );
  message = message.replaceAll("[COMPANY_URL]", data.url || "");
  message = message.replaceAll("[CATEGORY]", data.category || "");
  message = message.replaceAll(
    "[COMPANY_DOMAIN]",
    new URL(data.url).hostname || ""
  );
  return message;
};

export const MESSAGES_VARIABLE = [
  "[URL]",
  "[NUMBER]",
  "[EMAIL]",
  "[NAME]",
  "[COMPANY_NAME]",
  "[COMPANY_URL]",
  "[COMPANY_DOMAIN]",
  "[CATEGORY]",
];

export const MESSAGES_TAGS = {
  "Our Website": "[URL]",
  "Phone Number": "[NUMBER]",
  "My Email": "[EMAIL]",
  "My Name": "[NAME]",
  "Company Name": "[COMPANY_NAME]",
  "Company Url": "[COMPANY_URL]",
  "Company Domain": "[COMPANY_DOMAIN]",
  Category: "[CATEGORY]",
};

export const LEADS_TYPES = [
  { name: "Blocked", value: "Blocked" },
  { name: "Not Sent", value: "NOT_SEND" },
  { name: "Duplicate", value: "DUPLICATE" },
  { name: "Test", value: "TEST" },
  { name: "Sent", value: "SENT" },
];
export const LEADS_ACTIONS = [
  { name: "Blocked", value: "Blocked" },
  { name: "Test", value: "TEST" },
];

export const GLOBAL_WORD_LABEL_TYPE = [
  { name: "Word", value: "word" },
  { name: "Name", value: "name" },
  { name: "Email", value: "email" },
  { name: "Phone", value: "phone" },
  { name: "Url", value: "url" },
];

export const ROWS_PER_PAGE_OPTIONS = [10, 20, 30, 45, 50, 75, 100];
