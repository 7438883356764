/* eslint-disable no-dupe-keys */
import React, { useState } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import {
  Grid,
  Typography,
  Button as MuiButton,
  TextField as MuiTextField,
  CardContent,
  Card as MuiCard,
  CircularProgress,
  Box,
  Divider as MuiDivider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../redux/reducers/usersReducer";
import Chip from "../customComponent/Chips";
import { Lock } from "react-feather";
import UpdatePassword from "./UpdatePassword";

const Divider = styled(MuiDivider)(spacing);

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#6320EE",
    marginRight: "10px",
    marginLeft: "5px",
  },
  addSetting: {
    color: "white",
    height: "40px",
    marginRight: "0.5rem",
    backgroundColor: "#1BB27C",
    "&:hover": {
      backgroundColor: "#258f69",
    },
  },
  // paper: {
  //     borderRadius: "12px",
  //     boxShadow: "0px 0px 12px -5px #000000",
  // },
  dialogwrapper: {
    position: "absolute",
    maxWidth: "40rem",
    // top: theme.spacing(5)
  },
  dialogTitle: {
    paddingRight: "0px",
    float: "right",
  },
  startICon: {
    margin: 0,
  },
  chips: {
    height: "20px",
    borderRadius: "0px",
  },
  label: {
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  rootButton: {
    minWidth: "39px",
    marginTop: "-2px",
    marginRight: "11px",
  },
  ButtonApprove: {
    backgroundColor: "#23CC94",
    color: "white",
    width: "6rem",
    margin: "12px 39px",
  },
  description: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    marginTop: "10px",
  },
  input: {
    backgroundColor: "#F9F9FC",
  },
  label: {
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "5px",
  },
  dialogContent: {
    padding: "0px 24px",
  },
  controlinput: {
    backgroundColor: "#F9F9FC",
  },
  select: {
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    padding: "4px 12px ",
    // backgroundColor: "white"
  },
  shrink: {
    display: "none",
  },
  inputSelect: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  paperSelect: {
    backgroundColor: "#f5f6fa",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
}));

const EditUser = () => {
  const types = [{ name: "Admin" }, { name: "Manager" }, { name: "User" }];

  const [selectedType, setSelectedType] = useState();
  const [updatePassword, setUpdatePassword] = useState(false);
  const [user, setUser] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();

  const { isLoading, selected_user } = useSelector(
    (state) => state.entities.users
  );

  React.useEffect(() => {
    if (selected_user) {
      setUser(selected_user.user);
      let type = selected_user.user.type;
      setSelectedType({ name: type.charAt(0).toUpperCase() + type.slice(1) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_user]);

  const initialValues = {
    first_name: user ? user.first_name : "",
    phone_number: user ? user.phone_number : "",
    last_name: user ? user.last_name : "",
    email: user ? user.email : "",
    schedule_call: user ? user.schedule_call : "",
    picture_path: user ? user.picture_path : "",
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    phone_number: Yup.string(),
    schedule_call: Yup.string(),
    picture_path: Yup.string(),
  });

  const handleSubmit = async (values) => {
    const data = {
      _id: user._id,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone_number: values.phone_number,
      schedule_call: values.schedule_call,
      picture_path: values.picture_path,
      type: selectedType.name.toLowerCase(),
    };

    //console.log(data);

    dispatch(updateUser(data));
  };

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Grid justify="space-between" container>
            <Grid item>
              <Typography variant="h3" gutterBottom display="inline">
                User Information
              </Typography>
            </Grid>
            <Grid item>
              <Box display="flex" align="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setUpdatePassword(true)}
                >
                  <Lock size={18} style={{ marginRight: "5px" }} />
                  Change Password
                </Button>
              </Box>
            </Grid>
          </Grid>
          <UpdatePassword open={updatePassword} setOpen={setUpdatePassword} />
          <Divider my={6} />
          {user === null ? (
            <Box display="flex" justifyContent="center" my={0}>
              <CircularProgress />
            </Box>
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                status,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={4}>
                    <Grid item md={6} style={{ padding: "9px" }}>
                      <Typography variant="h5" classes={{ h5: classes.label }}>
                        First Name*:
                      </Typography>
                      <TextField
                        placeholder="First Name"
                        name="first_name"
                        value={values.first_name}
                        error={Boolean(touched.first_name && errors.password)}
                        fullWidth
                        helperText={touched.first_name && errors.first_name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={6} style={{ padding: "9px" }}>
                      <Typography variant="h5" classes={{ h5: classes.label }}>
                        Last Name*:
                      </Typography>
                      <TextField
                        placeholder="Last Name"
                        name="last_name"
                        value={values.last_name}
                        error={Boolean(touched.last_name && errors.last_name)}
                        fullWidth
                        helperText={touched.last_name && errors.last_name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={6}>
                    <Grid item md={6}>
                      <Typography variant="h5" classes={{ h5: classes.label }}>
                        Email*:
                      </Typography>
                      <TextField
                        placeholder="Email"
                        name="email"
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Typography variant="h5" classes={{ h5: classes.label }}>
                        Phone Number:
                      </Typography>
                      <TextField
                        placeholder="Phone Number"
                        name="phone_number"
                        value={values.phone_number}
                        error={Boolean(
                          touched.phone_number && errors.phone_number
                        )}
                        fullWidth
                        helperText={touched.phone_number && errors.phone_number}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    {selectedType && selectedType.name === "Manager" && (
                      <>
                        <Grid item md={6}>
                          <Typography
                            variant="h5"
                            classes={{ h5: classes.label }}
                          >
                            Schedule Call URL:
                          </Typography>
                          <TextField
                            placeholder="Schedule Call URL"
                            name="schedule_call"
                            value={values.schedule_call}
                            error={Boolean(
                              touched.schedule_call && errors.schedule_call
                            )}
                            fullWidth
                            helperText={
                              touched.schedule_call && errors.schedule_call
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="text"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <Typography
                            variant="h5"
                            classes={{ h5: classes.label }}
                          >
                            Profile Image URL:
                          </Typography>
                          <TextField
                            placeholder="Profile Image URL"
                            name="picture_path"
                            value={values.picture_path}
                            error={Boolean(
                              touched.picture_path && errors.picture_path
                            )}
                            fullWidth
                            helperText={
                              touched.picture_path && errors.picture_path
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="text"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item md={12}>
                      <Typography variant="h5" classes={{ h5: classes.label }}>
                        User Type:
                      </Typography>
                      {types ? (
                        <Chip
                          list={types}
                          selected={user && selectedType}
                          input_label={true}
                          placeholder={"User Type"}
                          label="name"
                          setSelected={setSelectedType}
                        />
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>

                  <Button
                    className={classes.addSetting}
                    type="submit"
                    disabled={isLoading}
                    variant="contained"
                    color="primary"
                    mt={6}
                  >
                    {isLoading ? "Updating..." : "Save changes"}
                  </Button>
                </form>
              )}
            </Formik>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default EditUser;
