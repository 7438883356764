/* eslint-disable import/first */
import React from "react";

import { Layers, Link, Users, Umbrella, Settings } from "react-feather";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Guards
import AuthGuard from "../components/AuthGuard";

// Auth components
import SignIn from "../pages/auth/SignIn";
import PayCardExample from "../pages/auth/PayCardExample";
import SendResetPassword from "../pages/auth/SendResetPassword";
import ResetPassword from "../pages/auth/ResetPassword";

// user components
//import IntegrationsArea from "../pages/members/iframe/IntegrationsArea";

// Protected routes
import dashboard from "../pages/projects/dashboard";
import BrandsList from "../pages/brands/BrandsList";
import LoginGuard from "../components/LoginGuard";
import SettingsPage from "../pages/settings/Settings";
import CompaniesList from "../pages/companies/CompaniesList";

// new pages design
import { Dashboard } from "@material-ui/icons";
import EditBrand from "../pages/brands/EditBrand";
import EditCompany from "../pages/companies/EditCompany";
import UsersList from "../pages/users/UsersList";
import EditUser from "../pages/users/EditUser";
import LeadsList from "../pages/leads/LeadsList";
import LeadsSettings from "../pages/leads/LeadsSettings";

const dashboardRoute = {
  id: "Dashboard",
  path: "/dashboard",
  icon: <Dashboard />,
  component: dashboard,
  guard: AuthGuard,
};

const brandsRoute = {
  id: "Brands",
  path: "/brands",
  icon: <Layers />,
  component: BrandsList,
  guard: AuthGuard,
};
const updateBrandsRoute = {
  id: "Update Brands",
  path: "/brands/:id",
  icon: <Layers />,
  component: EditBrand,
  guard: AuthGuard,
};

const usersRoute = {
  id: "Users",
  path: "/users/",
  name: "Users",
  icon: <Users />,
  component: UsersList,
  guard: AuthGuard,
};

const managersRoute = {
  id: "Account Managers",
  path: "/users/managers",
  name: "Account Managers",
  icon: <Users />,
  component: UsersList,
  guard: AuthGuard,
};

// children: [
//     {
//       id: "users-customers",
//       name: "Contacts (customers)",
//       path: "/users",
//       component: UsersList,
//       guard: AuthGuard,
//     },
//     {
//       id: "Account Managers",
//       name: "Account Managers",
//       path: "/users/managers",
//       component: UsersList,
//       guard: AuthGuard,
//     },
//   ],

const adminsRoute = {
  id: "Admins",
  path: "/users/admins",
  icon: <Layers />,
  component: UsersList,
  guard: AuthGuard,
};

const updateUserRoute = {
  id: "Update User",
  path: "/users/:id",
  icon: <Layers />,
  component: EditUser,
  guard: AuthGuard,
};

const companiesRoute = {
  id: "Companies",
  path: "/companies",
  name: "Companies",
  icon: <Link />,
  component: CompaniesList,
  guard: AuthGuard,
};
const updateCompanyRoute = {
  id: "Update Company",
  path: "/companies/:id",
  icon: <Layers />,
  component: EditCompany,
  guard: AuthGuard,
};

const leadsManagerRoute = {
  id: "Leads Manager",
  path: "/leads/list",
  name: "Leads",
  icon: <Umbrella />,

  children: [
    {
      id: "Companies",
      path: "/leads/companies",
      name: "Companies",
      guard: AuthGuard,
      component: CompaniesList,
    },
    {
      id: "Leads",
      path: "/leads/list",
      name: "All Leads",
      guard: AuthGuard,
      component: LeadsList,
    },
    {
      id: "Blacklist Settings",
      path: "/leads/settings",
      name: "Settings",
      guard: AuthGuard,
      component: LeadsSettings,
    },
  ],
  guard: AuthGuard,
};

// const supportRoute = {
//   id: "Support",
//   path: "/message",
//   icon: <MessageSquare />,
//   component: MessagesList,
//   guard: AuthGuard,
// };

// const LogsRoute = {
//   id: "Logs",
//   path: "/logs",
//   icon: <Layout />,
//   component: Profiles,
//   guard: AuthGuard,
// };

// const integrationsRoute = {
//   id: "Integrations",
//   path: "/integrations/list",
//   icon: <Framer />,
//   component: IntegrationsList,
//   guard: AuthGuard,
// };

// const integrationInfoRoute = {
//   id: "Integrations info",
//   path: "/integrations/info/:id",
//   name: "Info Integration",
//   component: IntegrationsInfo,
//   guard: AuthGuard,
//   hideInMenu: true,
// };

const settingsRoute = {
  id: "Settings",
  path: "/settings",
  icon: <Settings />,
  component: SettingsPage,
  guard: AuthGuard,
};

const loginRoute = {
  path: "/",
  name: "Sign In",
  component: SignIn,
  guard: LoginGuard,
};

const payRoute = {
  id: "Pay Example",
  path: "/pay",
  name: "Pay",
  icon: <Settings />,
  component: PayCardExample,
  guard: AuthGuard,
};

const sendResetThePasswordRoute = {
  path: "/auth/forgot-password",
  name: "Forgot Password",
  component: SendResetPassword,
};

const resetThePasswordRoute = {
  path: "/auth/reset-password/:_id/:token",
  name: "Reset Password",
  component: ResetPassword,
  props: "this.props.match.params._id",
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardRoute,
  brandsRoute,
  companiesRoute,
  updateCompanyRoute,
  usersRoute,
  managersRoute,
  leadsManagerRoute,
  adminsRoute,
  // supportRoute,
  // LogsRoute,
  payRoute,
  // integrationsRoute,
  // integrationInfoRoute,
  settingsRoute,
  updateBrandsRoute,
  updateUserRoute,
];

// Routes that are not protected
export const authLayoutRoutes = [
  loginRoute,
  sendResetThePasswordRoute,
  resetThePasswordRoute,
];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardRoute,
  brandsRoute,
  companiesRoute,
  usersRoute,
  managersRoute,
  leadsManagerRoute,
  // integrationsRoute,
  // LogsRoute,
  settingsRoute,
  payRoute,
];
