import { combineReducers } from "redux";
import themeReducer from "../reducers/themeReducer";
import authReducer from "../reducers/authReducer";
import projectReducer from "../reducers/projectReducer";
import snackbarReducer from "../reducers/snackbarReducer";
import usersReducer from "../reducers/usersReducer";
import leadsReducer from "../reducers/leadsReducer";
import companiesReducer from "../reducers/companiesReducer";
import integrationsReducer from "../reducers/integrationsReducer";
import brandsReducer from "../reducers/brandsReducer";
import settingReducer from "../reducers/settingsReducer";
import dashboardReducer from "../reducers/dashboardReducer";
import globalWordReducer from "../reducers/globalWordReducer";

const listOfReducers = combineReducers({
  theme: themeReducer,
  auth: authReducer,
  brands: brandsReducer,
  companies: companiesReducer,
  projects: projectReducer,
  snack: snackbarReducer,
  users: usersReducer,
  integrations: integrationsReducer,
  leads: leadsReducer,
  settings: settingReducer,
  dashboard: dashboardReducer,
  globalWords: globalWordReducer,
});

export default combineReducers({
  entities: listOfReducers,
});
