import React from "react";
import { DateRangePicker } from "materialui-daterange-picker";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import { DateRangeOutlined, ArrowDropDown } from "@material-ui/icons";
import { StylesProvider } from "@material-ui/core/styles";
import "./DatePickerCss.css";

const DatePicker = ({
  toDispatch,
  data,
  minDate,
  maxDate,
  onSelect,
  setSelected,
}) => {
  const [open, setOpen] = React.useState(false);
  const [label, setLabel] = React.useState("All Times");

  const dispatch = useDispatch();

  const toggle = () => setOpen(!open);

  const handleChange = (range) => {
    setOpen(false);

    const start = new Date(range.startDate).toISOString().split("T")[0];
    const end = new Date(range.endDate).toISOString().split("T")[0];

    let labelText = `${start} to ${end}`;

    setLabel(range.label ? range.label : labelText);
    if (!data) data = {};
    data.date = {
      startDate: range.startDate,
      endDate: range.endDate,
    };

    if (toDispatch) dispatch(toDispatch(data));
    if (onSelect)
      onSelect({ startDate: range.startDate, endDate: range.endDate });
  };

  return (
    <StylesProvider>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        <DateRangeOutlined style={{ marginRight: "1rem" }} />
        {label}
        <ArrowDropDown style={{ marginLeft: "1rem" }} />
      </Button>
      <div
        style={{
          zIndex: "5",
          position: "absolute",
          top: "100%",
          right: "4%",
        }}
      >
        <DateRangePicker
          open={open}
          toggle={toggle}
          minDate={maxDate ? maxDate : "01-01-2020"}
          maxDate={
            maxDate
              ? maxDate
              : new Date(new Date().setDate(new Date().getDate() + 1))
                  .toISOString()
                  .split("T")[0]
          }
          onChange={(range) => handleChange(range)}
        />
      </div>
    </StylesProvider>
  );
};

export default DatePicker;
