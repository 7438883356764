import React from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";

import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { updateBrand } from "../../redux/reducers/brandsReducer";
import { makeStyles } from "@material-ui/core/styles";
import SettingsRow from "./SettingsRow";
import ArchiveDialog from "../customComponent/AlertDialog";

import NewSetting from "./NewSetting";
const Divider = styled(MuiDivider)(spacing);

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const useStyles = makeStyles({
  icon: {
    width: "100%",
    color: "white",
  },
  newSetting: {
    color: "white",
    height: "40px",
    marginRight: "0.5rem",
    backgroundColor: "#4320C4",
    "&:hover": {
      backgroundColor: "#4327ab",
    },
  },
  addSetting: {
    color: "white",
    height: "40px",
    marginRight: "0.5rem",
    backgroundColor: "#1BB27C",
    "&:hover": {
      backgroundColor: "#258f69",
    },
  },
  deleteSetting: {
    color: "white",
    height: "100%",
    marginRight: "0.5rem",
    backgroundColor: "#da2525",
    "&:hover": {
      backgroundColor: "#248262",
    },
  },
});

const SettingsTab = ({ type, title }) => {
  const [settings, setSettings] = React.useState();
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [settingToDelete, setSettingToDelete] = React.useState(null);
  const [openAddSetting, setOpenAddSetting] = React.useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();

  let { selected_brand } = useSelector((state) => state.entities.brands);

  React.useEffect(() => {
    if (selected_brand) {
      setSettings(selected_brand[type]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_brand]);

  const handleData = (key, value) => {
    setSettings({ ...settings, [key]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(settings);
    dispatch(updateBrand({ ...selected_brand, [type]: settings }));
  };

  const deleteSettings = (name) => {
    setDeleteOpen(true);
    setSettingToDelete(name);
  };

  const onDeleteSetting = () => {
    let newSettings = Object.assign({}, settings);
    delete newSettings[settingToDelete];
    //console.log(newSettings);
    dispatch(updateBrand({ ...selected_brand, [type]: newSettings }));
  };

  const setSetting = (data) => {
    let newSettings = Object.assign({}, settings);
    newSettings[data.name] = data.value;
    dispatch(updateBrand({ ...selected_brand, [type]: newSettings }));
  };

  return (
    <>
      <NewSetting
        open={openAddSetting}
        setOpen={setOpenAddSetting}
        setSetting={setSetting}
      />
      <ArchiveDialog
        title="Delete Setting"
        description="This will delete the name and the value from your database, make sure you are not using this data anywhere in the platform"
        open={deleteOpen}
        id={2}
        setOpen={setDeleteOpen}
        onYes={onDeleteSetting}
      />
      <Card mb={6}>
        <CardContent>
          <Typography variant="h4" gutterBottom display="inline">
            {title}
          </Typography>
          <Divider my={6} />
          <form onSubmit={handleSubmit}>
            {settings &&
              Object.keys(settings).map((key, index) => {
                //console.log(index);
                return (
                  <SettingsRow
                    index={index}
                    key={key}
                    name={key}
                    value={settings[key]}
                    remove={deleteSettings}
                    handleData={handleData}
                  />
                );
              })}

            <Button
              variant="contained"
              className={classes.newSetting}
              type="submit"
              color="primary"
              mt={3}
            >
              Save changes
            </Button>
            <Button
              variant="contained"
              className={classes.addSetting}
              color="primary"
              onClick={() => setOpenAddSetting(true)}
              mt={3}
            >
              Add New Setting
            </Button>
          </form>
        </CardContent>
      </Card>
    </>
  );
};

export default SettingsTab;
