import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../actions/apiActions";
const url = "dashboard";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    stats: {},
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
    selected_brand: null,
    selected_date: null,
  },
  reducers: {
    resetAll: (state) => {
      state.isLoading = false;
      state.error = null;
      state.success = null;
    },
    requestStarted: (state, action) => {
      state.isLoading = true;
      state.isRequestSent = true;
    },
    requestSuccess: (state, action) => {
      state.success = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    requestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetErrorAndSuccess: (state) => {
      state.error = null;
      state.isLoading = false;
      state.success = null;
    },
    getStats: (state, action) => {
      state.stats = action.payload.stats;
      state.selected_brand = action.payload.selected_brand;
      state.selected_date = action.payload.selected_date;
    },
  },
});
export default dashboardSlice.reducer;
export const {
  requestStarted,
  requestSuccess,
  requestFailed,
  resetErrorAndSuccess,
  resetAll,
  getStats,
} = dashboardSlice.actions;

export const getDashboardStats = (params = {}) => {
  if (params.brand === "all" || params.brand === null) delete params.brand;
  if (params.startDate === null) {
    delete params.date;
  } else {
    if (params.date && params.date.startDate)
      params.startDate = params.date.startDate;
    if (params.date && params.date.endDate)
      params.endDate = params.date.endDate;
    delete params.date;
  }
  let query = new URLSearchParams(params);
  return apiCallBegan({
    url: `${url}?${query}`,
    method: "GET",
    onStart: requestStarted.type,
    onSuccess: getStats.type,
    onError: requestFailed.type,
  });
};
