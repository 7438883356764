import React from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  CardContent,
  Divider,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import { useDispatch } from "react-redux";
// import { useHistory } from "react-router-dom";
import Loading from "../customComponent/Loading";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#6320EE",
    marginRight: "10px",
    marginLeft: "5px",
  },

  dialogWrapper: {
    position: "absolute",
  },
  dialogTitle: {
    paddingRight: "0px",
    float: "right",
  },
  startICon: {
    margin: 0,
  },
  chips: {
    height: "20px",
    borderRadius: "0px",
  },
  label: {
    paddingLeft: "1px",
    paddingRight: "1px",
  },
  rootButton: {
    minWidth: "39px",
    marginTop: "-2px",
    marginRight: "11px",
  },
  ButtonApprove: {
    width: "6rem",
    margin: "12px 39px",
  },
  ButtonDelete: {
    backgroundColor: "#23CC94",
    color: "white",
    width: "6rem",
    margin: "12px 39px",
  },
  description: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    marginTop: "10px",
  },
  input: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
  h5: {
    fontSize: "13px",
    fontWeight: "700",
    marginBottom: "5px",
  },
  dialogContent: {
    // padding: "0px 24px",
  },
  controlInput: {
    backgroundColor: "#F9F9FC",
  },
  select: {
    border: "1px solid #bfbfbf",
    borderRadius: "4px",
    padding: "4px 12px ",
  },
  shrink: {
    display: "none",
  },
  inputSelect: {
    padding: "11.5px 14px",
    backgroundColor: "#F9F9FC",
  },
}));

const ViewLeadPopUp = ({ open, setOpen, lead }) => {
  const classes = useStyles();
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    let list = [];
    Object.keys(lead.data).forEach((key) =>
      list.push({ key, value: lead.data[key] })
    );

    setData(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const dispatch = useDispatch();
  // const history = useHistory();

  if (!lead && data.length) return <Loading />;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle className={classes.dialogTitle}>
        <Grid justify="space-between" container style={{ marginBottom: "0px" }}>
          <Grid item md={7}>
            <Typography variant="h4">Lead: #{lead.reference_number}</Typography>
            <Typography
              variant="h6"
              style={{ color: "#868695", marginTop: "10px" }}
            >
              Date: {moment(lead.createdAt).fromNow()}
            </Typography>
          </Grid>

          <Grid item md={5}>
            {lead.source_url}
          </Grid>
        </Grid>

        <div style={{ display: "flex", float: "right" }}>
          <Button
            style={{ color: "#868695", marginTop: "-50px" }}
            classes={{ root: classes.rootButton }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent>
        <CardContent>
          <Grid container spacing={6}>
            <MyDivider headline="Form Submission" />
            {data.map((item) => (
              <DataRow name={item.key} value={item.value} />
            ))}

            <MyDivider headline="Extra Data" style={{ marginTop: "30px" }} />
            <GetSource label="Type Of Traffic" value="Facebook Organic" />
            <GetSource label="Source" value="Facebook" />
            <GetSource label="Campaign" value="Page" />
            <GetSource label="Medium" value="adset1" />
            <GetSource label="Content" value="Facebook" />

            <MyDivider headline="Visitor Data" style={{ marginTop: "30px" }} />

            <DataRow name="IP Address" value="192.256.352.01" />
            <DataRow name="Country" value="USA" />
            <DataRow name="State" value="Florida" />
            <DataRow name="City" value="Lakeland" />
            <DataRow name="Device" value="Desktop" />
            <DataRow name="Browser" value="Opera" />
          </Grid>
        </CardContent>
      </DialogContent>
      <DialogActions style={{ paddingBottom: "20px" }}>
        <Button variant="outlined">Forward</Button>
        <Button //onClick={handleClose}
          variant="outlined"
        >
          Delete
        </Button>
        <Button
          //onClick={handleClose}
          variant="contained"
        >
          Mark as Test
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function GetSource({ label, value, ...rest }) {
  return (
    <Grid md={2} {...rest}>
      <Typography
        variant="h5"
        style={{ fontWeight: "700", marginBottom: "12px" }}
      >
        {label}
      </Typography>
      <Typography variant="h5">{value}</Typography>
    </Grid>
  );
}

function MyDivider({ headline, ...rest }) {
  return (
    <Grid md={12} {...rest}>
      <Typography variant="h4" gutterBottom>
        {headline}:
      </Typography>
      <Divider my={4} style={{ marginBottom: "15px" }} />
    </Grid>
  );
}

function DataRow({ name, value, ...rest }) {
  return (
    <Grid
      container
      spacing={2}
      style={{ fontWeight: "300", marginBottom: "8px" }}
    >
      <Grid item md={4}>
        <Typography variant="h6" style={{ fontWeight: "500" }}>
          {name}:
        </Typography>
      </Grid>
      <Grid item md={8}>
        <Typography variant="h6" style={{ fontWeight: "600" }}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
}
export default ViewLeadPopUp;
