import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import {
  Box,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  FormControl,
  Chip,
} from "@material-ui/core";

import ChipComponent from "../customComponent/Chips";

import {
  resetAll,
  deleteCompany,
  setSelectedCompany,
} from "../../redux/reducers/companiesReducer";

import {
  Delete as DeleteIcon,
  ArrowForward,
  Person,
  PhoneAndroid,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import MySelect from "../customComponent/MySelect";
import { getCompanies } from "../../redux/reducers/companiesReducer";
import {
  getNumberPerPage,
  isValidEmail,
  updateNbrPerPage,
} from "../../helpers/functions";
import Loading from "../customComponent/Loading";
import { Reload } from "../customComponent/Reload";
import { blue, red } from "@material-ui/core/colors";
import moment from "moment";
import { Calendar, Eye, Link2, Mail } from "react-feather";
import ArchiveDialog from "../customComponent/AlertDialog";
import DatePicker from "../customComponent/DateRangePicker";
import { getLeads } from "../../redux/reducers/leadsReducer";
import NotificationAlerts from "../../components/NotificationAlerts";
import { LEADS_TYPES, ROWS_PER_PAGE_OPTIONS } from "../../constants";
import ViewLeadPopUp from "./ViewLeadPopUp";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Customer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
`;

// const Spacer = styled.div`
//   flex: 1 1 100%;
// `;

// const ToolbarTitle = styled.div`
//   min-width: 150px;
// `;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "ID", alignment: "left", label: "ID" },
  { id: "status", alignment: "center", label: "Status" },
  { id: "contact", alignment: "left", label: "Contact" },
  { id: "date", alignment: "left", label: "Date" },
  { id: "form", alignment: "left", label: "Form" },
  { id: "company", alignment: "left", label: "Company" },
  { id: "website", alignment: "left", label: "Website" },
  { id: "actions", alignment: "right", label: "Actions" },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell mr={2}>Status</TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// let EnhancedTableToolbar = (props) => {
//   const { numSelected } = props;

//   return (
//     <Toolbar>
//       <ToolbarTitle>
//         {numSelected > 0 ? (
//           <Typography color="inherit" variant="subtitle1">
//             {numSelected} selected
//           </Typography>
//         ) : (
//           <Typography variant="h6" id="tableTitle">
//             Companies List
//           </Typography>
//         )}
//       </ToolbarTitle>
//       <Spacer />
//       <Box>
//         {numSelected > 0 ? (
//           <Tooltip title="Delete">
//             <IconButton aria-label="Delete">
//               <ArchiveIcon />
//             </IconButton>
//           </Tooltip>
//         ) : (
//           <Tooltip title="Filter list">
//             <IconButton aria-label="Filter list">
//               <FilterListIcon />
//             </IconButton>
//           </Tooltip>
//         )}
//       </Box>
//     </Toolbar>
//   );
// };

function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const dispatch = useDispatch();
  const rowsPerPage = getNumberPerPage();
  const history = useHistory();
  const [companyToDelete, setCompanyToDelete] = React.useState(null);
  const [viewDialogOpen, setViewDialogOpen] = React.useState(false);
  const [leadToView, setLeadToView] = React.useState(null);

  // this needed for edit dialog
  // const [editDialogOpen, setEditDialogOpen] = React.useState(false);

  const { list, search, total_records, maxPage } = useSelector(
    (state) => state.entities.leads
  );

  React.useEffect(() => {
    if (maxPage === 0) setPage(0);
  }, [maxPage]);

  function getContact(data) {
    let contact = getLabelFromData("email", data);
    if (contact) return contact;

    contact = getEmailFromData(data);
    if (contact) return contact;

    contact = getLabelFromData("name", data);
    if (contact) return contact;

    contact = getLabelFromData("phone", data);
    if (contact) return contact;

    return "n/a";
  }

  function getLabelFromData(type, data) {
    for (let key in data) {
      if (key.toLowerCase().includes(type)) {
        return { type, value: data[key] };
      }
    }
  }

  function getEmailFromData(data) {
    for (let key in data) {
      if (isValidEmail(data[key])) {
        return { type: "email", value: data[key] };
      }
    }
  }

  const handleChangePage = (event, newPage) => {
    let data = {
      search,
      page: newPage + 1,
    };

    if (newPage > maxPage - 1) {
      dispatch(getCompanies(data));
    }
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      //const newSelectees = list.map((n) => n.id);
      // setSelected(newSelectees);
      return;
    }
    // setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    let per_page = event.target.value;
    let data = {
      search,
      per_page,
    };

    updateNbrPerPage(per_page);
    setPage(0);
    dispatch(getCompanies(data));
  };

  // const isSelected = (id) => selected.indexOf(id) !== -1;
  const afterCompanyDelete = () => {
    setCompanyToDelete(null);
  };

  const emptyRows = rowsPerPage - Math.min(list, 10 - page * rowsPerPage);

  const goToCompany = (id) => {
    dispatch(setSelectedCompany(null));
    history.push("/companies/" + id);
  };

  const closeViewLead = (id) => {
    setViewDialogOpen(false);
    setLeadToView(null);
  };

  return (
    <div>
      {leadToView && (
        <ViewLeadPopUp
          open={viewDialogOpen}
          setOpen={closeViewLead}
          lead={leadToView}
        />
      )}

      {companyToDelete && (
        <ArchiveDialog
          title="Block Lead"
          description="This will move the lead to the blacklisted list. Are you sure you want to proceed?!"
          open={openDialog}
          data={{ _id: companyToDelete }}
          setOpen={setOpenDialog}
          dispatchOnAgree={deleteCompany}
          onYes={afterCompanyDelete}
        />
      )}
      <Paper>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={2}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={list.length}
            />
            <TableBody>
              {stableSort(list, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // const isItemSelected = isSelected(row.id);
                  // const labelId = `enhanced-table-checkbox-${index}`;
                  // const { status } = row;
                  let color = "info";
                  if (row.status === "Blocked") color = "secondary";
                  if (row.status === "Valid") color = "primary";

                  let contactRes = getContact(row.data);

                  let contact = contactRes.value;

                  let { hostname } = new URL(row.source_url);
                  let domain = hostname.replace("www.", "");

                  let Icon = Person;
                  if (contactRes.type === "email") Icon = Mail;
                  else if (contactRes.type === "phone") Icon = PhoneAndroid;

                  return (
                    <TableRow hover key={index}>
                      <TableCell
                        align="left"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          goToCompany(row);
                        }}
                      >
                        <Customer>{row.reference_number}</Customer>
                      </TableCell>

                      <TableCell align="center">
                        <Chip label={row.status} color={color} size="small" />
                      </TableCell>

                      <TableCell
                        style={{ cursor: "pointer" }}
                        align="left"
                        onClick={() => {
                          goToCompany(row);
                        }}
                      >
                        <Customer
                          style={{ color: blue[800], fontSize: "0.85rem" }}
                        >
                          <Icon
                            size={13}
                            style={{
                              marginRight: "3px",
                              marginBottom: "-1px",
                              color: blue[900],
                            }}
                          />
                          {contact}
                        </Customer>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ color: blue[800], fontSize: "0.85rem" }}
                      >
                        <Calendar
                          size={13}
                          style={{
                            marginRight: "3px",
                            marginBottom: "-1px",
                            color: red[800],
                          }}
                        />
                        {moment(row.createdAt).fromNow()}
                      </TableCell>
                      <TableCell align={row.form?.label ? "left" : "center"}>
                        {row.form?.label || "-"}
                      </TableCell>

                      <TableCell
                        align="left"
                        onClick={() => goToCompany(row.company._id)}
                      >
                        <Customer
                          style={{ color: blue[800], fontSize: "0.85rem" }}
                        >
                          {row.company?.company_name}
                        </Customer>
                      </TableCell>

                      <TableCell align="left">
                        <Link2
                          size={13}
                          style={{
                            marginRight: "3px",
                            marginBottom: "-3px",
                            color: blue[800],
                          }}
                        />
                        <Link
                          to={{ pathname: "https://" + domain }}
                          color="inherit"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: blue[800],
                            fontWeight: "600",
                          }}
                        >
                          {domain}
                        </Link>
                      </TableCell>

                      <TableCell align="right">
                        <Tooltip title="View Lead">
                          <IconButton
                            style={{ color: blue[800] }}
                            aria-label="View Lead"
                            onClick={() => {
                              setLeadToView(row);
                              setViewDialogOpen(true);
                            }}
                          >
                            <Eye />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="BlackList">
                          <IconButton
                            style={{ color: blue[500] }}
                            aria-label="Delete"
                            onClick={() => setCompanyToDelete(row._id)}
                          >
                            <ArrowForward />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="BlackList">
                          <IconButton
                            style={{ color: red[500] }}
                            aria-label="Delete"
                            onClick={() => setCompanyToDelete(row._id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={total_records}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function LeadsList() {
  const { success, error, isLoading, selected_company, list } = useSelector(
    (state) => state.entities.leads
  );

  const [validate, setValidate] = React.useState("all");
  const [selectedCompany, setSelectedCompany] = React.useState("all");
  const [selectedDate, setSelectedDate] = React.useState(null);

  const { list_all: companies_List } = useSelector(
    (state) => state.entities.companies
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    loadLeads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, validate, selectedCompany]);

  const loadLeads = () => {
    let data = {
      date: selectedDate,
      company: selectedCompany,
      status: validate,
    };
    dispatch(getLeads(data));
  };

  return (
    <React.Fragment>
      <NotificationAlerts error={error} success={success} reset={resetAll} />

      <Helmet title="Leads" />

      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Leads
          </Typography>
        </Grid>
        <Grid item>
          <Box style={{ display: "flex" }}>
            <Reload loading={isLoading} action={loadLeads} />
            {/* <FormControl style={{ minWidth: "10rem", marginRight: "1rem" }}>
              <SelectBySearch />
            </FormControl> */}
            <FormControl style={{ minWidth: "12rem", marginRight: "1rem" }}>
              <ChipComponent
                style={{
                  height: "2.5rem",
                }}
                selected={selected_company}
                list={companies_List.filter((item) =>
                  item.number.includes("SB")
                )}
                placeholder={"All Companies"}
                label="company_name"
                input_label="All Companies"
                setSelected={(company) => {
                  setSelectedCompany(company._id);
                }}
              />
            </FormControl>
            <FormControl style={{ minWidth: "10rem", marginRight: "1rem" }}>
              <MySelect
                selected={validate}
                list={LEADS_TYPES}
                defaultLabel="All Types"
                defaultValue="all"
                label="name"
                value="value"
                setSelected={setValidate}
              />
            </FormControl>
            <FormControl>
              <DatePicker onSelect={setSelectedDate} />
            </FormControl>
            {/* {open && (
              <CompanyPopUp
                open={open}
                setOpen={setOpen}
                handleClose={handleClose}
              />
            )} */}
          </Box>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {!list.length && isLoading ? <Loading /> : <EnhancedTable />}
          {/* <NoResults
            loading={isLoading}
            list={list.length}
            table={EnhancedTable}
          /> */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default LeadsList;
