import { createSlice } from "@reduxjs/toolkit";
import { getNumberPerPage } from "../../helpers/functions";
import { apiCallBegan } from "../actions/apiActions";
const url = "words";

const slice = createSlice({
  name: "globalWord",
  initialState: {
    list: [],
    all: [],
    search: null,
    selected_label: null,
    selected_action: null,
    page: 0,
    per_page: 0,
    total_pages: 0,
    maxPage: 0,
    total_records: 0,
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
  },
  reducers: {
    resetAll: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.success = null;
    },
    requestStarted: (state, action) => {
      state.isLoading = true;
      state.error = null;
      state.isRequestSent = true;
    },
    requestedFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    globalWordsLoaded: (state, action) => {
      state.list = state.list.concat(action.payload.data);
      state.page = action.payload.page;
      state.per_page = action.payload.per_page;
      state.total_records = action.payload.total_records;
      state.total_pages = action.payload.total_pages;
      state.selected_label = action.payload.selected_label;
      state.search = action.payload.search;
      state.selected_action = action.payload.selected_action;
      state.maxPage = state.page > state.maxPage ? state.page : state.maxPage;
      state.isLoading = false;
    },
    globalWordsReLoaded: (state, action) => {
      state.list = action.payload.data;
      state.page = action.payload.page;
      state.per_page = action.payload.per_page;
      state.total_records = action.payload.total_records;
      state.selected_label = action.payload.selected_label;
      state.search = action.payload.search;
      state.selected_action = action.payload.selected_action;
      state.total_pages = action.payload.total_pages;
      state.maxPage = 0;
      state.isLoading = false;
    },
    allGlobalWordsLoaded: (state, action) => {
      state.all = action.payload;
      state.isLoading = false;
    },
    globalWordAdded: (state, action) => {
      state.success = "New globalWord Added";
      state.list.unshift(...action.payload);
      state.total_records = state.total_records + 1;
      state.isLoading = false;
    },
    globalWordUpdated: (state, action) => {
      const index = state.list.findIndex(
        (globalWord) => globalWord._id === action.payload._id
      );
      state.list[index] = action.payload;
      state.success = "Global Word Updated";
      state.isLoading = false;
    },
    globalWordDeleted: (state, action) => {
      const index = state.list.findIndex(
        (globalWord) => globalWord._id === action.payload._id
      );
      state.list.splice(index, 1);
      state.total_records = state.total_records - 1;
      state.isLoading = false;
      state.success = "Global Word Deleted";
    },
  },
});

export const {
  globalWordAdded,
  globalWordsLoaded,
  globalWordsReLoaded,
  requestedFailed,
  requestStarted,
  globalWordUpdated,
  globalWordDeleted,
  allGlobalWordsLoaded,
  resetAll,
} = slice.actions;
export default slice.reducer;

export const getGlobalWords = (params = {}) => {
  params.per_page = getNumberPerPage();
  if (params.action === "all" || params.action === null) delete params.action;
  if (params.label === "all" || params.label === null) delete params.label;
  if (params.search === "" || params.search === null) delete params.search;
  let query = new URLSearchParams(params);
  // // Reload the data if no page is provided

  let resultsReceived = params.page
    ? globalWordsLoaded.type
    : globalWordsReLoaded.type;

  return apiCallBegan({
    url: `${url}?${query}`,
    onStart: requestStarted.type,
    onSuccess: resultsReceived,
    onError: requestedFailed.type,
  });
};

export const getAllGlobalWords = () =>
  apiCallBegan({
    url: url + "/all",
    onStart: requestStarted.type,
    onSuccess: allGlobalWordsLoaded.type,
    onError: requestedFailed.type,
  });

export const addGlobalWord = (data) =>
  apiCallBegan({
    url,
    method: "post",
    data,
    onStart: requestStarted.type,
    onSuccess: globalWordAdded.type,
    onError: requestedFailed.type,
  });

export const updateGlobalWord = (id, data) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: "put",
    data,
    onStart: requestStarted.type,
    onSuccess: globalWordUpdated.type,
    onError: requestedFailed.type,
  });

export const deleteGlobalWord = (id) =>
  apiCallBegan({
    url,
    method: "delete",
    data: { _id: id },
    onStart: requestStarted.type,
    onSuccess: globalWordDeleted.type,
    onError: requestedFailed.type,
  });
