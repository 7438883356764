import React from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TagsInput from "../customComponent/TagsInput";

import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import Chip from "../customComponent/Chips";
import {
  getOneCompany,
  updateCompany,
} from "../../redux/reducers/companiesReducer";
import Loading from "../customComponent/Loading";

const Divider = styled(MuiDivider)(spacing);

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const GeneralSettings = () => {
  let { id } = useParams();
  const dispatch = useDispatch();

  const [data, setData] = React.useState({
    company_name: "",
    domain: "",
  });

  // const [integrations, setIntegrations] = React.useState(null);

  const { list_all: brands } = useSelector((state) => state.entities.brands);
  const { selected_company, isLoading } = useSelector(
    (state) => state.entities.companies
  );

  let { list_all: integrations } = useSelector(
    (state) => state.entities.integrations
  );

  React.useEffect(() => {
    if (selected_company) {
      setData(selected_company);
      if (integrations && selected_company.integrations) {
        // const integration = selected_company.integrations.map((elem) =>
        //   integrations?.find(({ _id }) => elem.integration === _id)
        // );
      }
    } else {
      dispatch(getOneCompany(id));
    }

    // if (integrations && selected_company) {
    //   const integration = integrations.filter((item) =>
    //     selected_company.integrations.find(
    //       ({ integration }) => item._id === integration
    //     )
    //   );
    //   //setCompanyIntegrations(comInteg);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_company, integrations]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log(data);
    dispatch(updateCompany(data));
  };

  const handleSelectBrand = (brand) => {
    setData({ ...data, brand });
  };

  function handleSelectedValues(notification_emails) {
    setData({ ...data, notification_emails });
  }

  if (!selected_company) return <Loading />;
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h4" gutterBottom display="inline">
          General Settings
        </Typography>
        <Divider my={6} />
        <form onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item md={10}>
              <TextField
                name="company_name"
                label="Company Name"
                onChange={handleChange}
                value={data.company_name}
                fullWidth
                variant="outlined"
                required
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                name="name"
                label="Company ID"
                value={data.number}
                fullWidth
                variant="outlined"
                required
                disabled={true}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                name="domain"
                label="Domain"
                value={data.domain}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                required
              />
            </Grid>
            <Grid item md={6}>
              {selected_company?.brand && brands && (
                <Chip
                  list={brands}
                  selected={selected_company.brand}
                  placeholder={"Select Brand"}
                  label="name"
                  setSelected={handleSelectBrand}
                />
              )}
            </Grid>
            <Grid item md={12}>
              {selected_company?.notification_emails && (
                <TagsInput
                  selectedValues={handleSelectedValues}
                  fullWidth
                  variant="outlined"
                  id="emails"
                  name="notification_emails"
                  values={selected_company.notification_emails}
                  placeholder=" Add email"
                  label="Notification E-mails"
                  checkEmail={true}
                />
              )}
            </Grid>
            {/* <Grid item md={12}>
              {companyIntegrations && integrations && (
                <Chip
                  list={integrations}
                  selected={companyIntegrations}
                  placeholder={"Company Integrations"}
                  label="name"
                  setSelected={handleSelectIntegrations}
                  multiple={true}
                />
              )}
            </Grid> */}
          </Grid>
          <Button
            type="submit"
            disabled={isLoading}
            variant="contained"
            color="primary"
            mt={3}
          >
            {isLoading ? "Saving..." : "Save changes"}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default GeneralSettings;
