/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Paper,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { makeStyles } from "@material-ui/core/styles";
import ArchiveDialog from "../customComponent/AlertDialog";

import UserCompanyRow from "./CompanyUserRow";
// import AssignCompanyToUser from "./AssignCompanyToUser";
import {
  deleteUserCompany,
  updateUserCompanies,
} from "../../redux/reducers/usersReducer";
const Divider = styled(MuiDivider)(spacing);

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const useStyles = makeStyles({
  icon: {
    width: "100%",
    color: "white",
  },
  newSetting: {
    color: "white",
    height: "40px",
    marginRight: "0.5rem",
    backgroundColor: "#4320C4",
    "&:hover": {
      backgroundColor: "#4327ab",
    },
  },
  addSetting: {
    color: "white",
    height: "40px",
    marginRight: "0.5rem",
    backgroundColor: "#1BB27C",
    "&:hover": {
      backgroundColor: "#258f69",
    },
  },
  deleteSetting: {
    color: "white",
    height: "100%",
    marginRight: "0.5rem",
    backgroundColor: "#da2525",
    "&:hover": {
      backgroundColor: "#248262",
    },
  },
});

const UpdateCompanyUsers = ({ users }) => {
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [openAddUser, setOpenAddUser] = React.useState(false);
  const [userToDelete, setUserToDelete] = React.useState(null);
  const [updatedUser, setUpdatedUsers] = React.useState([]);

  const dispatch = useDispatch();
  const classes = useStyles();

  let { selected_company, companiesUpdated, isLoading } = useSelector(
    (state) => state.entities.companies
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    let newList = users.map((user) => {
      return {
        company: selected_company._id,
        user: user._id,
        role: user.role,
      };
    });
    console.log(newList);
    // dispatch(updateUserCompanies(newList));
  };

  const deleteUser = (index) => {
    setUserToDelete(index);
    setDeleteOpen(true);
  };
  const onDeleteUser = () => {
    dispatch(
      deleteUserCompany({
        company: selected_company._id,
        user: selected_company.users[userToDelete]._id,
      })
    );
    setUserToDelete(null);
  };

  const setCompany = (company) => {
    let newList = [];
    newList.push({
      company: company._id,
      user: selected_company.user._id,
      role: company.role,
    });
    dispatch(updateUserCompanies(newList));
    setOpenAddUser(false);
  };

  function handleChangeRole(index, updated) {
    let updatedUsers = [];

    let res = selected_company.users.map((user, i) => {
      if (i === index) return updatedUsers.push(updated);
      else return updatedUsers.push(user);
    });
    setUpdatedUsers(updatedUsers);
  }

  return (
    <>
      {!selected_company ? (
        <Box display="flex" justifyContent="center" my={0}>
          <CircularProgress />
        </Box>
      ) : (
        <Paper>
          <ArchiveDialog
            title="Delete Company"
            description="This will remove the user from accessing this company. Are you sure you wanna proceed?"
            open={deleteOpen}
            setOpen={setDeleteOpen}
            onYes={onDeleteUser}
          />

          <Card mb={6}>
            <CardContent>
              <Typography variant="h3" gutterBottom display="inline">
                Company Users
              </Typography>
              <Divider my={6} />
              <form onSubmit={handleSubmit}>
                {users &&
                  users.map((user, index) => {
                    return (
                      <UserCompanyRow
                        index={index}
                        user={user}
                        remove={deleteUser}
                        change={handleChangeRole}
                      />
                    );
                  })}

                {/* {openAddUser && <AssignCompanyToUser add={setCompany} />} */}

                {!openAddUser && (
                  <Button
                    variant="contained"
                    className={classes.newSetting}
                    type="submit"
                    color="primary"
                    mt={3}
                  >
                    {isLoading ? "Updating..." : "Save changes"}
                  </Button>
                )}
                <Button
                  variant="contained"
                  className={classes.addSetting}
                  color="primary"
                  onClick={() => setOpenAddUser(!openAddUser)}
                  mt={3}
                >
                  {openAddUser ? "Cancel" : "Assign New User"}
                </Button>
              </form>
            </CardContent>
          </Card>
        </Paper>
      )}
    </>
  );
};

export default UpdateCompanyUsers;
