/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { dispatchAdminModules, getToken } from "../helpers/functions";
import { getUserByToken } from "../redux/reducers/authReducer";
import Loader from "./Loader";

// For routes that can only be accessed by authenticated users
const AuthGuard = ({ children }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.entities.auth.data);
  const { isRequestSent } = useSelector((state) => state.entities.auth);

  const state = useSelector((state) => state.entities);

  let token = getToken();

  React.useEffect(() => {
    if (user && token) dispatchAdminModules(dispatch, state);
  }, [user]);

  if (!token) {
    return <Redirect to="/" />;
  }

  // we have token but No user
  if (!user) {
    if (!isRequestSent) dispatch(getUserByToken());
    return <Loader />;
  }

  //we have user but not ad;in
  if (user.type !== "admin" && user.type !== "manager") {
    return <Redirect to="/404" />;
  }

  // all good load children
  return children;
};

export default AuthGuard;
