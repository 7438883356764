import { createSlice } from "@reduxjs/toolkit";
import { getNumberPerPage } from "../../helpers/functions";
import { apiCallBegan } from "../actions/apiActions";
const url = "integrations/leads";

const slice = createSlice({
  name: "leads",
  initialState: {
    list_all: [],
    list: [],
    managers: [],
    integrations: [],
    stats: {},
    selected_brand: null,
    selected_integration: null,
    selected_manager: null,
    selected_date: null,
    selected_company: null,
    search: "",
    page: 0,
    per_page: 0,
    total_pages: 0,
    maxPage: 0,
    total_records: 0,
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
  },
  reducers: {
    resetAll: (leads, action) => {
      leads.isLoading = false;
      leads.error = null;
      leads.success = null;
      leads.deActivated = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    requestStarted: (leads, action) => {
      leads.isLoading = true;
      leads.error = null;
      leads.isRequestSent = true;
    },
    requestedFailed: (leads, action) => {
      leads.error = action.payload;
      leads.isLoading = false;
    },

    companyAdded: (leads, action) => {
      leads.success = "New Lead Added";
      leads.list.unshift(action.payload);
      leads.list_all.unshift({
        _id: action.payload._id,
        company_name: action.payload.company_name,
      });
      leads.isLoading = false;
      leads.selected_company = action.payload;
    },
    setSelectedLead: (leads, action) => {
      leads.selected_company = action.payload;
    },
    companyUpdated: (leads, action) => {
      const index = leads.list.findIndex(
        (company) => company._id === action.payload._id
      );
      leads.list[index] = action.payload;
      leads.success = "Lead Updated";
      leads.isLoading = false;
    },
    companyDeleted: (leads, action) => {
      const index = leads.list.findIndex(
        (company) => company._id === action.payload._id
      );
      const i = leads.list_all.findIndex(
        (company) => company._id === action.payload._id
      );
      leads.list_all.splice(i, 1);
      leads.list.splice(index, 1);
      leads.success = "Lead Deleted";
      leads.isLoading = false;
    },

    leadsDeActivated: (leads, action) => {
      const { modifiedCount, word } = action.payload;
      leads.success = modifiedCount + " leads De-activated successfully";
      leads.deActivated = word;
      leads.isLoading = false;
    },

    leadsLoaded: (state, action) => {
      state.list = state.list.concat(action.payload.data);
      state.isLoading = false;
      state.page = action.payload.page;
      state.per_page = action.payload.per_page;
      state.total_pages = action.payload.total_pages;
      state.total_records = action.payload.total_records;
      state.selected_brand = action.payload.selected_brand;
      state.selected_integration = action.payload.selected_integration;
      state.selected_manager = action.payload.selected_manager;
      state.search = action.payload.search;
      state.maxPage = state.page > state.maxPage ? state.page : state.maxPage;
    },
    leadsReLoaded: (state, action) => {
      state.list = action.payload.data;
      state.isLoading = false;
      state.page = action.payload.page;
      state.per_page = action.payload.per_page;
      state.total_pages = action.payload.total_pages;
      state.total_records = action.payload.total_records;
      state.selected_brand = action.payload.selected_brand;
      state.selected_integration = action.payload.selected_integration;
      state.selected_manager = action.payload.selected_manager;
      state.search = action.payload.search;
      state.maxPage = 0;
    },
    allLeadsLoaded: (state, action) => {
      state.list_all = action.payload;
      state.isLoading = false;
    },
    OneLeadsLoaded: (state, action) => {
      state.company = action.payload;
      state.selected_company = action.payload;
      state.isLoading = false;
    },
    LeadIntegrationsLoaded: (state, action) => {
      state.integrations = action.payload;
      state.isLoading = false;
      state.success = "";
    },
  },
});

export const {
  setError,
  companyAdded,
  leadsLoaded,
  leadsReLoaded,
  OneLeadsLoaded,
  requestedFailed,
  requestStarted,
  companyUpdated,
  companyDeleted,
  leadsDeActivated,
  allLeadsLoaded,
  LeadIntegrationsLoaded,
  resetAll,
  setSelectedLead,
} = slice.actions;
export default slice.reducer;

export const getLeads = (params = {}) => {
  params.per_page = getNumberPerPage();
  if (params.search === "" || params.search === null) delete params.search;
  if (params.company === "all") delete params.company;
  if (params.status === "all") delete params.status;

  if (params.date === null) delete params.date;

  if (params.date) {
    params.startDate = params.date.startDate;
    params.endDate = params.date.endDate;
    delete params.date;
  }

  let query = new URLSearchParams(params);
  // // Reload the data if no page is provided
  let resultsReceived = params.page ? leadsLoaded.type : leadsReLoaded.type;

  return apiCallBegan({
    url: `${url}?${query}`,
    method: "GET",
    onStart: requestStarted.type,
    onSuccess: resultsReceived,
    onError: requestedFailed.type,
  });
};

export const getOneLead = (id) =>
  apiCallBegan({
    url: `${url}/${id}`,
    method: "GET",
    onStart: requestStarted.type,
    onSuccess: OneLeadsLoaded.type,
    onError: requestedFailed.type,
  });

export const getLeadIntegrations = (id) =>
  apiCallBegan({
    url: `${url}/${id}/integrations`,
    method: "GET",
    onStart: requestStarted.type,
    onSuccess: LeadIntegrationsLoaded.type,
    onError: requestedFailed.type,
  });

export const addLead = (data) =>
  apiCallBegan({
    url,
    method: "POST",
    data,
    onStart: requestStarted.type,
    onSuccess: companyAdded.type,
    onError: requestedFailed.type,
  });

export const updateLead = (data) =>
  apiCallBegan({
    url,
    method: "PUT",
    data,
    onStart: requestStarted.type,
    onSuccess: companyUpdated.type,
    onError: requestedFailed.type,
  });

export const deActivateLeads = (word) =>
  apiCallBegan({
    url: `${url}/de-activate/${word}`,
    method: "PUT",
    onStart: requestStarted.type,
    onSuccess: leadsDeActivated.type,
    onError: requestedFailed.type,
  });
export const deleteLead = (data) =>
  apiCallBegan({
    url,
    data,
    method: "DELETE",
    onStart: requestStarted.type,
    onSuccess: companyDeleted.type,
    onError: requestedFailed.type,
  });
