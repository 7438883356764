import React from "react";
import * as moment from "moment";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import {
  updateBrand,
  resetAll,
  getBrands,
  setSelectedBrand,
} from "../../redux/reducers/brandsReducer";
import { makeStyles } from "@material-ui/core/styles";

import { Helmet } from "react-helmet-async";
import NumberFormat from "react-number-format";
import NotificationAlerts from "../../components/NotificationAlerts";

import {
  Box,
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  FormControlLabel,
  Typography,
  Switch,
} from "@material-ui/core";
import {
  getComparator,
  getNumberPerPage,
  stableSort,
  updateNbrPerPage,
} from "../../helpers/functions";

import { HelpOutline as InfoIcon, Edit as EditIcon } from "@material-ui/icons";

import { spacing } from "@material-ui/system";

import BrandPopup from "./AddNewBrandPopUp";
import Loading from "../customComponent/Loading";
import { Reload } from "../customComponent/Reload";
import { Plus } from "react-feather";
import { useHistory } from "react-router-dom";
import { ROWS_PER_PAGE_OPTIONS } from "../../constants";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Customer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const useStyles = makeStyles({
  campaign: {
    marginRight: "12px",
    height: "40px",
    backgroundColor: "#6A74C9",
    color: "white",
    "&:hover": {
      backgroundColor: "#424c96",
    },
  },
  icon: {
    width: "20px",
    color: "white",
  },
  newProject: {
    color: "white",
    height: "40px",
    backgroundColor: "#23CC94",
    "&:hover": {
      backgroundColor: "#248262",
    },
    width: "9rem",
  },
});

const headCells = [
  {
    id: "Stats",
    numeric: false,
    disablePadding: false,
    label: "Stats",
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "created",
    numeric: true,
    disablePadding: false,
    label: "Created",
  },
  {
    id: "Domain",
    numeric: true,
    disablePadding: false,
    label: "Domain",
  },
  {
    id: "Companies",
    numeric: false,
    disablePadding: false,
    label: "Companies",
  },
  {
    id: "users",
    numeric: false,
    disablePadding: false,
    label: "Users",
  },

  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    alignment: "right",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {headCell.tooltip ? (
                <Tooltip title={headCell.tooltip}>
                  <InfoIcon fontSize="small" />
                </Tooltip>
              ) : (
                ""
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTable = () => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [page, setPage] = React.useState(0);
  const dispatch = useDispatch();
  const rowsPerPage = getNumberPerPage();
  const history = useHistory();

  // this needed for edit dialog
  // const [editDialogOpen, setEditDialogOpen] = React.useState(false);

  const { search, list, total_records, maxPage } = useSelector(
    (state) => state.entities.brands
  );

  React.useEffect(() => {
    if (maxPage === 0) setPage(0);
  }, [maxPage]);

  const handleChangePage = (event, newPage) => {
    let data = {
      search,
      page: newPage + 1,
    };

    if (newPage > maxPage - 1) {
      dispatch(getBrands(data));
    }
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event) => {
    let per_page = event.target.value;
    let data = {
      search,
      per_page,
    };

    updateNbrPerPage(per_page);
    setPage(0);
    dispatch(getBrands(data));
  };

  const emptyRows =
    rowsPerPage - Math.min(list, list.length - page * rowsPerPage);

  const handleBrandStatus = (_id, status) => {
    console.log({ _id, status });
    dispatch(updateBrand({ _id, status: status }));
  };
  const handleEditBrand = (brand) => {
    dispatch(setSelectedBrand(brand));
    history.push("/brands/" + brand._id);
  };

  return (
    <div>
      {/* {openBrandDialog && (
        <Popup
          open={openBrandDialog}
          setOpen={handleBrandDialog}
          brand={selected_brand}
        />
      )} */}

      <Paper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={list.length}
            />

            <TableBody>
              {stableSort(list, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover key={index}>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={row.status}
                              onChange={(event) =>
                                handleBrandStatus(row._id, !row.status)
                              }
                            />
                          }
                        />
                      </TableCell>

                      <TableCell component="th" id={labelId} scope="row">
                        <Customer onClick={() => handleEditBrand(row)}>
                          {row.name}
                        </Customer>
                      </TableCell>

                      <TableCell component="th" id={labelId} scope="row">
                        {moment(row.createdAt).format("DD/MM/YYYY")}
                      </TableCell>

                      <TableCell component="th" id={labelId} scope="row">
                        <Customer>{row.domain}</Customer>
                      </TableCell>

                      <TableCell align="left">
                        <NumberFormat
                          value={row.totalCompanies}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </TableCell>

                      <TableCell align="left">
                        <NumberFormat
                          value={row.totalUsers}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="Edit">
                          <IconButton
                            aria-label="details"
                            onClick={() => handleEditBrand(row)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={10} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={total_records}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

const BrandsList = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const { error, success, isLoading, list } = useSelector(
    (state) => state.entities.brands
  );
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadBrands = () => {
    dispatch(getBrands());
  };

  return (
    <React.Fragment>
      <NotificationAlerts error={error} success={success} reset={resetAll} />

      <Helmet title="Brands" />

      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Brands
          </Typography>
        </Grid>
        <Grid item>
          <Box display="flex" align="center">
            <Reload loading={isLoading} action={loadBrands} />

            <Button
              variant="contained"
              startIcon={<Plus className={classes.icon} />}
              className={classes.campaign}
              onClick={handleClickOpen}
            >
              New Brand
            </Button>
            {open && (
              <BrandPopup
                open={open}
                setOpen={setOpen}
                brand={null}
                handleClose={handleClose}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            {!list.length && isLoading ? <Loading /> : <EnhancedTable />}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default BrandsList;
