import React from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Typography,
  Tab,
  Tabs,
  Divider as MuiDivider,
  Paper as MuiPaper,
} from "@material-ui/core/";
import { Helmet } from "react-helmet-async";
import { spacing } from "@material-ui/system";
import { useDispatch, useSelector } from "react-redux";
import { getUser, resetAll } from "../../redux/reducers/usersReducer";
import { useParams } from "react-router-dom";
import NotificationAlerts from "../../components/NotificationAlerts";
import UserData from "./UserData";
import UpdateUserCompanies from "./UpdateUserCompanies";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const EditUser = () => {
  let { id } = useParams();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [user, setUser] = React.useState(null);

  const { error, success, selected_user } = useSelector(
    (state) => state.entities.users
  );

  React.useEffect(() => {
    if (!selected_user) {
      dispatch(getUser(id));
    } else {
      setUser(selected_user.user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_user]);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <NotificationAlerts error={error} success={success} reset={resetAll} />
      <Helmet title="Edit Users" />
      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Update {user && `: (${user.first_name} ${user.last_name})`}
          </Typography>
        </Grid>
      </Grid>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          <Paper>
            <Box className={classes.root}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
                style={{ width: "250px" }}
              >
                <Tab label="Profile" {...a11yProps(0)} />
                <Tab label="Companies" {...a11yProps(1)} />
              </Tabs>
              <TabPanel style={{ width: "100%" }} value={value} index={0}>
                {user && <UserData />}
              </TabPanel>
              <TabPanel style={{ width: "100%" }} value={value} index={1}>
                {selected_user && <UpdateUserCompanies />}
              </TabPanel>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default EditUser;
