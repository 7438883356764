import { createSlice } from "@reduxjs/toolkit";
import { getNumberPerPage } from "../../helpers/functions";
import { apiCallBegan } from "../actions/apiActions";
const url = "users";

const slice = createSlice({
  name: "users",
  initialState: {
    list: [],
    managers: [],
    isLoading: false,
    error: null,
    success: null,
    isRequestSent: null,
    selected_brand: null,
    selected_company: null,
    selected_date: null,
    selected_user: null,
    search: "",
    page: 0,
    per_page: 0,
    total_pages: 0,
    maxPage: 0,
    total_records: 0,
    companiesUpdated: null,
  },
  reducers: {
    resetAll: (users, action) => {
      users.isLoading = false;
      users.error = null;
      users.success = null;
    },
    requestStarted: (users, action) => {
      users.isLoading = true;
      users.error = null;
      users.isRequestSent = true;
      users.companiesUpdated = null;
    },
    requestedFailed: (users, { payload }) => {
      users.error = payload;
      users.isLoading = false;
    },
    usersLoaded: (state, action) => {
      state.list = state.list.concat(action.payload.data);
      state.isLoading = false;
      state.page = action.payload.page;
      state.per_page = action.payload.per_page;
      state.total_pages = action.payload.total_pages;
      state.total_records = action.payload.total_records;
      state.selected_brand = action.payload.selected_brand;
      state.search = action.payload.search;
      state.maxPage = state.page > state.maxPage ? state.page : state.maxPage;
    },
    selectedUserLoaded: (state, action) => {
      state.selected_user = action.payload;
      state.isLoading = false;
    },
    usersReLoaded: (state, action) => {
      state.list = action.payload.data;
      state.isLoading = false;
      state.page = action.payload.page;
      state.per_page = action.payload.per_page;
      state.total_pages = action.payload.total_pages;
      state.total_records = action.payload.total_records;
      state.selected_brand = action.payload.selected_brand;
      state.search = action.payload.search;
      state.maxPage = 0;
    },
    loadUsersList: (users, action) => {
      users.list = action.payload;
      users.isLoading = false;
    },

    userPasswordUpdated: (users, action) => {
      users.isLoading = false;
      users.success = "User password updated.";
    },

    userCompaniesUpdated: (state, action) => {
      state.isLoading = false;
      state.companiesUpdated = true;
      state.selected_user.companies = action.payload.companies;
      state.success = "Companies Updated.";
    },
    companyDeleted: (state, action) => {
      state.isLoading = false;
      state.companiesUpdated = true;
      state.selected_user.companies = action.payload.companies;
      state.success = "Company Deleted.";
    },
    setSelectedUser: (users, action) => {
      users.selected_user = action.payload;
    },
    resetLists(users, action) {
      users.list = [];
    },
    loadManagersList: (users, action) => {
      users.managers = action.payload;
      users.isLoading = false;
    },
    userAdded: (users, action) => {
      users.success = "New User Added.";
      users.list.unshift(action.payload.user);
      users.selected_user = action.payload;
      users.isLoading = false;
    },
    userDeleted: (users, action) => {
      users.success = "User Deleted.";
      ///users.list.unshift(action.payload);
      const index = users.list.findIndex(
        (user) => user._id === action.payload.user._id
      );
      users.list.splice(index, 1);
      users.isLoading = false;
    },
    userUpdated: (users, action) => {
      const index = users.list.findIndex(
        (user) => user._id === action.payload.user._id
      );
      users.list[index] = { ...users.list[index], ...action.payload.user };
      users.success = "User Updated.";
      users.selected_user = action.payload;
      users.isLoading = false;
    },
  },
});

export const {
  userAdded,
  usersLoaded,
  usersReLoaded,
  loadUsersList,
  loadManagersList,
  requestedFailed,
  requestStarted,
  userUpdated,
  resetAll,
  setSelectedUser,
  selectedUserLoaded,
  resetLists,
  userCompaniesUpdated,
  companiesUpdated,
  companyDeleted,
  userDeleted,
  userPasswordUpdated,
} = slice.actions;
export default slice.reducer;

export const getUsers = (params = {}) => {
  params.per_page = getNumberPerPage();
  if (params.search === "" || params.search === null) delete params.search;
  if (params.brand === "all" || params.brand === null) delete params.brand;
  let query = new URLSearchParams(params);
  // // Reload the data if no page is provided
  let resultsReceived = params.page ? usersLoaded.type : usersReLoaded.type;

  return apiCallBegan({
    url: `${url}?${query}`,
    method: "GET",
    onStart: requestStarted.type,
    onSuccess: resultsReceived,
    onError: requestedFailed.type,
  });
};

export const updateUserCompanies = (data) =>
  apiCallBegan({
    url: url + "/companies",
    method: "POST",
    data,
    onStart: requestStarted.type,
    onSuccess: userCompaniesUpdated.type,
    onError: requestedFailed.type,
  });
export const deleteUserCompany = (data) =>
  apiCallBegan({
    url: "companies/user",
    method: "DELETE",
    data,
    onStart: requestStarted.type,
    onSuccess: companyDeleted.type,
    onError: requestedFailed.type,
  });

export const getManagers = () => {
  const params = { type: "manager" };
  const query = new URLSearchParams(params);
  return apiCallBegan({
    url: `${url}/list?${query}`,
    onStart: requestStarted.type,
    onSuccess: loadManagersList.type,
    onError: requestedFailed.type,
  });
};

export const getUser = (id) =>
  apiCallBegan({
    url: url + "/" + id,
    method: "GET",
    onStart: requestStarted.type,
    onSuccess: selectedUserLoaded.type,
    onError: requestedFailed.type,
  });

export const addUser = (data) =>
  apiCallBegan({
    url,
    method: "POST",
    data,
    onStart: requestStarted.type,
    onSuccess: userAdded.type,
    onError: requestedFailed.type,
  });

export const updateUser = (data) =>
  apiCallBegan({
    url,
    method: "PUT",
    data,
    onStart: requestStarted.type,
    onSuccess: userUpdated.type,
    onError: requestedFailed.type,
  });

export const deleteUser = (data) =>
  apiCallBegan({
    url,
    method: "DELETE",
    data,
    onStart: requestStarted.type,
    onSuccess: userDeleted.type,
    onError: requestedFailed.type,
  });
export const updateUserPassword = (data) =>
  apiCallBegan({
    url: url + "/password/user",
    method: "PUT",
    data,
    onStart: requestStarted.type,
    onSuccess: userPasswordUpdated.type,
    onError: requestedFailed.type,
  });
