import React from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Typography,
  Tab,
  Tabs,
  Divider as MuiDivider,
  Paper as MuiPaper,
} from "@material-ui/core/";
import { Helmet } from "react-helmet-async";
import { spacing } from "@material-ui/system";
import GeneralSettings from "./GeneralSettings";
import { useDispatch, useSelector } from "react-redux";
import { getOneCompany, resetAll } from "../../redux/reducers/companiesReducer";
import { useParams } from "react-router-dom";
import NotificationAlerts from "../../components/NotificationAlerts";
import CompanyIntegrations from "./CompanyIntegrations";
import UpdateCompanyUsers from "./UpdateCompanyUsers";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const EditCompany = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const { selected_company, error, success } = useSelector(
    (state) => state.entities.companies
  );
  let { id } = useParams();

  React.useEffect(() => {
    dispatch(getOneCompany(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <NotificationAlerts error={error} success={success} reset={resetAll} />

      <Helmet title={"Update Company"} />
      <Grid justify="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Update Company
            {selected_company && ": " + selected_company.company_name}
          </Typography>
        </Grid>

        <Divider my={6} />

        <Grid container spacing={6}>
          <Grid item xs={12} md={12}>
            <Paper>
              <Box className={classes.root}>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  className={classes.tabs}
                >
                  <Tab label="General Settings" {...a11yProps(0)} />
                  <Tab label="Integrations" {...a11yProps(1)} />
                  <Tab label="Users" {...a11yProps(3)} />
                </Tabs>
                <TabPanel style={{ width: "100%" }} value={value} index={0}>
                  <GeneralSettings />
                </TabPanel>
                <TabPanel style={{ width: "100%" }} value={value} index={1}>
                  <CompanyIntegrations />
                </TabPanel>
                <TabPanel style={{ width: "100%" }} value={value} index={2}>
                  <UpdateCompanyUsers users={selected_company?.users || []} />
                </TabPanel>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EditCompany;
